import React from 'react';
import { Row, Col, Image } from 'react-bootstrap';
import logo from '../images/OpticostLogo2White.svg';
function Footer() {
	return (
		<div className='footer-div'>
			<div>
				<Row lg={4} sm={1} style={{ paddingTop: '50px', margin: '10px' }}>
					<div>
						<Image
							fluid
							src={logo}
							alt='opticost-logo'
							className='footer-logo'
						/>
					</div>
					<Col className='footer-link'>
						<h5>Product</h5>
						<div
							style={{
								fontSize: '15px',
								fontWeight: '400',
								marginTop: '20px',
								opacity: '0.8',
							}}>
							<p>Why Opticost</p>
							<p>How it works</p>
							<p>Pricing</p>
						</div>
					</Col>
					<Col className='footer-link'>
						<h5>Company</h5>
						<div
							style={{
								fontSize: '15px',
								fontWeight: '400',
								marginTop: '20px',
								opacity: '0.8',
							}}>
							<p>About</p>
							<p>Career</p>
							<p>Contact</p>
							<p>Book a demo</p>
						</div>
					</Col>
					<Col className='footer-link'>
						<h5>Resources</h5>
						<div
							style={{
								fontSize: '15px',
								fontWeight: '400',
								marginTop: '20px',
								opacity: '0.8',
							}}>
							<p>Case Studies</p>
							<p>Blog</p>
							<p>Help Center</p>
							<p>FAQ</p>
						</div>
					</Col>
				</Row>
				<Row className='footer-divider'>
					<div
						style={{
							display: 'inline-block',
							color: 'white',
							marginTop: '10px',
						}}>
						<span style={{ margin: '10px' }}>Terms</span>
						<span style={{ margin: '10px' }}>Privacy</span>
						<span style={{ margin: '10px' }}>Cookies</span>
					</div>
				</Row>
			</div>
		</div>
	);
}
export default Footer;
