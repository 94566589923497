import { APIURL } from "../../config"

const useRefreshToken = () => {
    const refresh = async () => {
        const result =
        await fetch(`${APIURL}/users/refresh`, {
            method:'GET',
            credentials: 'include',
            headers: {
                Accept: 'application/json',
                "Content-Type": "application/json",
            },
        })
        .then((response) => response.json())
        return result
    } 
    return refresh
}
export default useRefreshToken