import React, { useState, useEffect } from 'react';
//import { Link } from 'react-router-dom';
import './Dashboard.css';
import { APIURL } from '../../config';
//import defaultImage from '../../images/defaulpdf.png';
import UserDashboardNav from './UserDashboardNav';
//import { defaultProfile } from '../../config';
import { Row, Col } from 'react-bootstrap';

const Dashboard = (props) => {
	const [projects, setProjects] = useState([]);
	const [contractors, setContractors] = useState([]);
	//Pagination
	// const [currentPage, setCurrentPage] = useState(1);
	// const [itemsPerPage, setItemsPerPage] = useState(2);
	// const [pageNumberLimit, setPageNumberLimit] = useState(2);
	// const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(2);
	// const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
	function fetchProjectAPI() {
		//function to get current projects
		if (props.userId === null) {
			return <p> There was a problem loading this page, please try again</p>;
		} else {
			const url = `${APIURL}/users/${props.userId}/projects`;
			fetch(url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props.token}`,
				},
			})
				.then((response) => response.json())
				.then((data) => setProjects(data));
		}
	}
	function fetchContractorAPI() {
		//function to get current contractors
		if (props.userId === null) {
			return <p> There was a problem loading this page, please try again</p>;
		} else {
			const url = `${APIURL}/users/${props.userId}/contractors`;
			fetch(url, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props.token}`,
				},
			})
				.then((response) => response.json())
				.then((data) => setContractors(data));
		}
	}
	useEffect(() => {
		fetchProjectAPI();
		fetchContractorAPI();
		//fetchUserAPI()
		//eslint-disable-next-line
	}, []);
	//Pagination
	// const indexOfLastItem = currentPage * itemsPerPage;
	// const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	// const currentItems = projects.slice(indexOfFirstItem, indexOfLastItem);
	// const renderData = (projects) => {
	// 		return ( projects.map((project) => {
	// 			return(
	// 				<div className='general-card-view' key={project._id}>
	// 					<Link
	// 						className='link-color'
	// 						to={'/user-dashboard/projects/detail/' + project._id}>
	// 						<img
	// 							className='general-thumbnail-image'
	// 							src={defaultImage}
	// 							alt='project_thumbnail'
	// 						/>
	// 						<p> Project: {project.title} </p>
	// 						<p> Job Number: {project.job_id} </p>
	// 						<p> Status: {project.status}</p>
	// 						<p> Created Date: {project.created_date}</p>
	// 						<p> Last Updated: {project.last_modified}</p>
	// 					</Link>
	// 				</div>)
	// 		}))
	// }
	// const pages = [];
	// for (let i = 0; i <= Math.ceil(projects.length / itemsPerPage); i++) {
	// 	pages.push(i);
	// }
	// const renderPageNumbers = pages.map((number) => {
	// 	if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
	// 		return (
	// 			<li key={number} id={number} onClick={handleNextPage}>
	// 				{number}
	// 			</li>
	// 		);
	// 	} else {
	// 		return null;
	// 	}
	// });

	// function handleNextPage(event) {
	// 	setCurrentPage(Number(event.target.id));
	// 	console.log(projects)
	// }
	// const handleNextButton = () => {
	// 	setCurrentPage(currentPage + 1);

	// 	if (currentPage + 1 > maxPageNumberLimit) {
	// 		setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
	// 		setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
	// 	}
	// };

	// const handlePreviousButton = () => {
	// 	setCurrentPage(currentPage -1 );
	// 	if((currentPage - 1)% pageNumberLimit === 0) {
	// 		setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit)
	// 		setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit)
	// 	}
	// }
	// let pageIncrementBtn = null;
	// if (pages.length > maxPageNumberLimit) {
	// 	pageIncrementBtn = <li onClick={handleNextButton}> &hellip; </li>;
	// }

	// let pageDecrementBtn = null;
	// if (minPageNumberLimit >= 1) {
	// 	pageDecrementBtn = <li onClick={handlePreviousButton}> &hellip; </li>;
	// }
	//console.log(props)
	return (
		<>
			<Row>
				<UserDashboardNav
					userId={props.userId}
					userFullName={props.userFullName}
					account={props.account}
					handleSignOut={props.handleSignOut}></UserDashboardNav>
				<Col sm={10}>
					<h4>Coming Soon</h4>
					{/* <div className='general-div-wrapper'>
						{projects.map((project) => {
							return (
								<div className='general-card-view' key={project._id}>
									<Link
										className='link-color'
										to={'/user-dashboard/projects/detail/' + project._id}>
										<img
											className='general-thumbnail-image'
											src={defaultImage}
											alt='project_thumbnail'
										/>
										<p>Project: {project.title}</p>
										<p>Job Number: {project.job_id}</p>
										<p>Status: {project.status}</p>
										<p>Created Date: {project.created_date}</p>
										<p>Last Updated: {project.last_modified}</p>
									</Link>
								</div>
							);
						})}
						{renderData(currentItems)}
					</div> */}
				</Col>
			</Row>
			{/* <ul>
				<li>
					<button
						onClick={handlePreviousButton}
						disabled={currentPage === pages[0] ? true : false}>
						Prev
					</button>
				</li>
				{pageDecrementBtn}
				{renderPageNumbers}
				{pageIncrementBtn}
				<li>
					<button
						onClick={handleNextButton}
						disabled={currentPage === pages[pages.length - 1] ? true : false}>
						Next
					</button>
				</li>
			</ul> */}

			{/* <div>
					<div className='general-div-wrapper'>
						<div className='general-card-view'>
							<Link
								className='link-color'
								to={'/user-dashboard/projects/detail/077cf269c14d9e068ebb6e5'}>
								<img
									className='general-thumbnail-image'
									src={defaultImage}
									alt='project_thumbnail'
								/>
								<p> Project: New Wall Estimate </p>
								<p> Job Number: 1234567 </p>
								<p> Status: Submitted </p>
								<p> Created Date: 04 15 2021</p>
								<p> Last Updated: 04 25 2021</p>
							</Link>
						</div>
						<div className='general-card-view'>
							<p> Project: New Roof Estimate </p>
							<p> Job Number: 1234567 </p>
							<p> Status: In-progress </p>
							<p> Created Date: 04 15 2021</p>
							<p> Last Updated: 04 25 2021</p>
						</div>
						<div className='general-card-view'>
							<p> Project: New Floor Estimate </p>
							<p> Job Number: 1234567 </p>
							<p> Status: Quoting </p>
							<p> Created Date: 04 15 2021</p>
							<p> Last Updated: 04 25 2021</p>
						</div>
					</div>
				</div> */}
			{/* <h4 className='general-header'>Main Contractors</h4>
			<div className='general-row-wrapper-deviation'>
				<div className='general-row'>
					<p> CONTACT PERSON</p>
					<p> COMPANY NAME</p>
					<p> EMAIL</p>
					<p> PHONE #</p>
					<p> SPECIALTY</p>
				</div>
			</div>
			{contractors.map((contractor) => {
				let contractorPhoneNumber;
				if (contractor.phone === undefined) {
					contractorPhoneNumber = '(XXX) XXX-XXXX';
				} else {
					contractorPhoneNumber = contractor.phone
						.toString()
						.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
				}
				return (
					<Link
						to={'/user-dashboard/contractors/detail/' + contractor._id}
						className='link-color'
						key={contractor._id}>
						<div className='general-row-wrapper' key={contractor._id}>
							<div className='general-row' key={contractor._id}>
								<div className='general-profile-view'>
									<img
										className='general-profile'
										src={contractor.profile || defaultProfile}
										alt='contractor-profile'
									/>
									<p>
										{contractor.first_name} {contractor.last_name}
									</p>
								</div>
								<p> {contractor.company}</p>
								<p> {contractor.email} </p>
								<p> {contractorPhoneNumber || contractor.phone}</p>
								<p> {contractor.expertise}</p>
								<p> View Detail</p>
							</div>
						</div>
					</Link>
				);
			})} */}
			{/* <h4 className='general-header'>Current Contractors</h4>
				<div className='general-row-wrapper'>
					<div className='general-row'>
						<div className='general-profile-view'>
							<img className='general-profile' src={user1} />
							<p>John Walker</p>
						</div>
						<p>JohnW@expertroofing.com </p>
						<p>408 498 2243</p>
						<p>Expert Roofing</p>
						<p>Roof Estimate</p>
						<Link
							to={'/user-dashboard/contractors/detail/6077cef8ed2e44e047dc8e08'}
							className='link-color'>
							<p> View Detail</p>
						</Link>
					</div>
				</div>
				<div className='general-row-wrapper'>
					<div className='general-row'>
						<div className='general-profile-view'>
							<img className='general-profile' src={user2} />
							<p>Winston Glen</p>
						</div>
						<p>WinstonGlen@a+flooring.com </p>
						<p>408 661 8233</p>
						<p>A+ Flooring </p>
						<p>Flooring and Estimator</p>
						<Link
							to={'/user-dashboard/contractors/detail/6077cef8ed2e44e047dc8e08'}
							className='link-color'>
							<p> View Detail</p>
						</Link>
					</div>
				</div> */}
		</>
	);
};
export default Dashboard;
