import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { APIURL } from '../config';

const ResetPasswordLink = (props) => {
	const id = props.match.params.id;
	const token = props.match.params.token;
	const [idFromBackEnd, setIdFromBackend] = useState('');
	const [tokenFromBackend, setTokenFromBackend] = useState('');
	const [newPassword, setNewPassword] = useState('');
	const [isRedirect, setIsRedirect] = useState(false);
	const [redirectLink, setRedirectLink] = useState('');
	const [modalBox, setModalBox] = useState('none');
	const [errModalBox, setErrModalBox] = useState('none');

	const fetchResetLink = async () => {
		await fetch(`${APIURL}/users/passwordreseturl/${id}/${token}`, {
			method: 'GET',
		})
			.then((response) => response.json())
			.then((data) => {
				setIdFromBackend(data.id);
				setTokenFromBackend(data.token);
			})
			.catch((error) => console.log(error));
	};

	const handlePasswordChange = (event) => {
		setNewPassword(event.target.value);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		fetch(`${APIURL}/users/resetpassword`, {
			method: 'PUT',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				password: newPassword,
				id: idFromBackEnd,
				token: tokenFromBackend,
			}),
		})
			.then((response) => response.json())
			.then((message) => {
				if (message === 'Password has been updated') {
					setModalBox('block');
				} else if (message === 'Error updating password') {
					setErrModalBox('block');
				}
			});
	};

	const handleModalBox = () => {
		setModalBox('none');
		setErrModalBox('none');
		setRedirectLink('/sign-in');
		setIsRedirect(true);
	};

	useEffect(() => {
		if (idFromBackEnd === '' || tokenFromBackend === '') {
			fetchResetLink();
		}
		//eslint-disable-next-line
	}, [idFromBackEnd]);

	if (isRedirect) {
		return <Redirect to={redirectLink} />;
	}
	if (idFromBackEnd === '' || tokenFromBackend === '') {
		return (
			<>
				<div>Loading form, please wait</div>
			</>
		);
	} else if (idFromBackEnd === undefined || tokenFromBackend === undefined) {
		return (
			<>
				<div className='password-reset'>
					<h4>
						Unexpected error occured or this link is no longer valid. Please
						request new link or contact us at:
						<a href='mailto:main@opticost.ai'> main@opticos.ai</a>
					</h4>
					<p> Close this box to be redirected to sign-in page</p>
					<button className='general-button' onClick={handleModalBox}>
						Close
					</button>
				</div>
			</>
		);
	} else if (idFromBackEnd !== '' && tokenFromBackend !== '') {
		return (
			<>
				<div className='password-reset'>
					<p>To reset your password, enter new password below</p>
					<form>
						<input type='hidden' name='id' defaultValue={idFromBackEnd} />
						<input type='hidden' name='token' defaultValue={tokenFromBackend} />
						<input
							type='password'
							name='password'
							value={newPassword}
							placeholder='Enter your new password...'
							onChange={handlePasswordChange}
							className='password-reset-input'
							style={{ padding: '0px 10px' }}
						/>
						<div style={{ marginTop: '20px' }}>
							<button
								className='button'
								style={{ width: '300px', height: '40px' }}
								onClick={handleSubmit}>
								Reset Password
							</button>
						</div>
					</form>
				</div>
				<div className='general-modal' style={{ display: `${modalBox}` }}>
					<p>Your password had been successfully update!</p>
					<p> Close this box to be redirected to sign-in page</p>
					<button className='general-button' onClick={handleModalBox}>
						Close
					</button>
				</div>
				<div className='general-modal' style={{ display: `${errModalBox}` }}>
					<h3>
						Unexpected error occured. Please contact us at:
						<a href='mailto:main@opticost.ai'> main@opticos.ai</a>
					</h3>
					<p> Close this box to be redirected to sign-in page</p>
					<button className='general-button' onClick={handleModalBox}>
						Close
					</button>
				</div>
			</>
		);
	}
};

export default ResetPasswordLink;
