import React, { useState, useEffect } from 'react';
import useRefreshToken from '../CustomHooks/RefreshToken';
import UserDashboardNav from '../Dashboards/UserDashboardNav';
import { Link } from 'react-router-dom';
import { APIURL } from '../../config';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Row, Col, Pagination, Toast } from 'react-bootstrap';

const EstimatedProjects = ({
	token,
	setToken,
	userId,
	userFullName,
	account,
	handleSignOut,
}) => {
	const [projectData, setProjectData] = useState([]);
	const persistLogin = localStorage.getItem('persist');
	const email = localStorage.getItem('common');
	const refresh = useRefreshToken();
	const [currentPage, setCurrentPage] = useState(1);
	const [itemsPerPage, setItemsPerPage] = useState(15);
	const [pageNumberLimit, setPageNumberLimit] = useState(5);
	const [maxPageNumberLimit, setMaxPageNumberLimit] = useState(5);
	const [minPageNumberLimit, setMinPageNumberLimit] = useState(0);
	const [showDeleteToast, setShowDeleteToast] = useState(false);

	const fetchUserProjects = async () => {
		if (!token) {
			return;
		} else if (token) {
			await fetch(`${APIURL}/estimated-projects/users/${email}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${token}`,
				},
			})
				.then((response) => response.json())
				.then((projects) => {
					setProjectData(projects);
				});
		}
	};

	const handleDeleteToast = () => {
		if (showDeleteToast) {
			setShowDeleteToast(false);
		} else setShowDeleteToast(true);
	};

	const removeProject = async (event) => {
		event.preventDefault();
		const projectId = event.target.id;
		const formData = JSON.stringify({
			user: userId,
		});
		await fetch(`${APIURL}/estimated-projects/${projectId}`, {
			method: 'DELETE',
			headers: {
				Authorization: `Bearer ${token}`,
				'Content-Type': 'application/json; charset=utf-8',
			},
			body: formData,
		})
			.then(() => {
				fetchUserProjects();
				handleDeleteToast();
			})
			.catch((err) => console.log(err));
	};
	//Pagination
	const indexOfLastItem = currentPage * itemsPerPage;
	const indexOfFirstItem = indexOfLastItem - itemsPerPage;
	const currentItems = projectData.slice(indexOfFirstItem, indexOfLastItem);
	const renderData = (projects) => {
		return projects.map((project) => {
			return (
				<div key={project._id}>
					<Row className='project-grid-row'>
						<Col sm={10}>
							<Link
								className='row link-color project-grid-col'
								to={'/estimated-projects/detail/' + project._id}>
								<Col sm={1}>
									<span className='general-profile-view '>
										<FontAwesomeIcon
											className='font-awesome'
											icon='fa-solid fa-file-pdf'
										/>
									</span>
								</Col>
								<Col sm={3}>
									<span>{project.customer}</span>
								</Col>
								<Col sm={2}>
									<span>{project.created_date}</span>
								</Col>
								<Col sm={3}>
									<span>{project.job_number}</span>
								</Col>
								<Col sm={2}>
									<span>{project.job_description}</span>
								</Col>
							</Link>
						</Col>
						<Col sm={2}>
							<button
								className='delete-button'
								onClick={removeProject}
								id={project._id}>
								Delete Project
							</button>
						</Col>
					</Row>
				</div>
			);
		});
	};
	const pages = [];
	for (let i = 0; i <= Math.ceil(projectData.length / itemsPerPage); i++) {
		pages.push(i);
	}
	const renderPageNumbers = pages.map((number) => {
		if (number < maxPageNumberLimit + 1 && number > minPageNumberLimit) {
			return (
				<Pagination.Item
					active={number === currentPage}
					key={number}
					id={number}
					onClick={handleNextPage}>
					{number}
				</Pagination.Item>
			);
		} else {
			return null;
		}
	});

	function handleNextPage(event) {
		setCurrentPage(Number(event.target.id));
	}

	const handleNextButton = () => {
		setCurrentPage(currentPage + 1);

		if (currentPage + 1 > maxPageNumberLimit) {
			setMaxPageNumberLimit(maxPageNumberLimit + pageNumberLimit);
			setMinPageNumberLimit(minPageNumberLimit + pageNumberLimit);
		}
	};

	const handlePreviousButton = () => {
		setCurrentPage(currentPage - 1);
		if ((currentPage - 1) % pageNumberLimit === 0) {
			setMaxPageNumberLimit(maxPageNumberLimit - pageNumberLimit);
			setMinPageNumberLimit(minPageNumberLimit - pageNumberLimit);
		}
	};
	// let pageIncrementBtn = null;
	// if (pages.length > maxPageNumberLimit) {
	// 	pageIncrementBtn = <Pagination.Ellipsis onClick={handleNextButton} />;
	// }

	// let pageDecrementBtn = null;
	// if (minPageNumberLimit >= 1) {
	// 	pageDecrementBtn = <Pagination.Ellipsis onClick={handlePreviousButton} />;
	// }
	const verifyRefreshToken = async () => {
		if (persistLogin === 'true') {
			try {
				const newToken = await refresh();
				setToken(newToken);
			} catch (error) {
				console.error(error);
			} finally {
				return;
			}
		} else {
			return;
		}
	};
	useEffect(
		() => {
			verifyRefreshToken();
			fetchUserProjects();
			//eslint-disable-next-line
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[token],
		projectData
	);
	//THIS IS RENDER RETURN
	if (!token || token === '' || token === undefined || token === null) {
		return (
			<h2 className='not-signin-message'>Please sign in to access this page</h2>
		);
	} else if (projectData.length < 0) {
		return <div>Loading Data</div>;
	} else if (projectData.length === 0) {
		return (
			<>
				<Row>
					<UserDashboardNav
						userId={userId}
						userFullName={userFullName}
						account={account}
						handleSignOut={handleSignOut}></UserDashboardNav>
					<Col sm={10}>
						<h3 className='pdf-report-header'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-folder-open'
							/>
							Current Projects
						</h3>
						<div className='pdf-report-wrapper'>
							<p>No Projects Available</p>
						</div>
					</Col>
				</Row>
			</>
		);
	} else
		return (
			<>
				<Row>
					<UserDashboardNav
						userId={userId}
						userFullName={userFullName}
						account={account}
						handleSignOut={handleSignOut}></UserDashboardNav>
					<Col sm={10} className='dashboard-area'>
						<Row className='toast-row'>
							<Col>
								<Toast
									className='toast-remove'
									onClose={handleDeleteToast}
									show={showDeleteToast}
									delay={1500}
									autohide>
									<Toast.Header className='toast-header'>
										<strong className='me-auto'>Project Delete</strong>
									</Toast.Header>
									<Toast.Body>Project has been deleted</Toast.Body>
								</Toast>
							</Col>
						</Row>
						<h3 className='pdf-report-header'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-folder-open'
							/>
							Current Projects ({projectData.length})
						</h3>
						<div>
							<Row>
								<Col sm={10}>
									<div className='row project-grid-header'>
										<Col className='project-grid-small-col' sm={1}>
											PDF
										</Col>
										<Col className='project-grid-med-col' sm={3}>
											Customer
										</Col>
										<Col className='project-grid-small-col' sm={2}>
											Date Created
										</Col>
										<Col sm={3}>Job #</Col>
										<Col sm={2}>Job Description</Col>
									</div>
								</Col>
								<Col className='project-grid-header' sm={2}>
									Action
								</Col>
							</Row>
							{renderData(currentItems)}
							<Row>
								<Col sm={3} style={{ margin: '0 auto' }}>
									<Pagination>
										<Pagination.Prev
											onClick={handlePreviousButton}
											disabled={currentPage === 1 ? true : false}>
											Prev
										</Pagination.Prev>
										{/* {pageDecrementBtn} */}
										{renderPageNumbers}
										{/* {pageIncrementBtn} */}
										<Pagination.Next
											onClick={handleNextButton}
											disabled={
												currentPage === pages[pages.length - 1] ? true : false
											}>
											Next
										</Pagination.Next>
									</Pagination>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</>
		);
};

export default EstimatedProjects;
