import React, { useReducer } from 'react';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {
	Page,
	Text,
	View,
	Document,
	Image,
	PDFDownloadLink,
} from '@react-pdf/renderer';
import { Elements } from '@stripe/react-stripe-js';
import { APIURL } from '../config';
import logo from '../opticostlogo3.jpg';
import useRefreshToken from './CustomHooks/RefreshToken';
import StripeCheckout from './StripeCheckout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pdfRenderStyles, reportFooter, secondPDFPage } from './PDFtemplate';
import { scaleFactor } from './Shared/ScaleFactor';
import { slopeFactor } from './Shared/SlopeFactor';
import { Col, Form, Row } from 'react-bootstrap';

function Main(props) {
	const [projectInfoInput, setProjectInfoInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			customerName: '',
			projectDate: '',
			projectAddress: '',
			projectPhone: '',
			jobDescription: '',
			jobNumber: '',
		}
	);
	const [analyzedSquareFootage, setAnalyzedSquareFootage] = useState(0);
	const [analyzedEave, setAnalyzedEave] = useState(0);
	const [analyzedHip, setAnalyzedHip] = useState(0);
	const [analyzedValley, setAnalyzedValley] = useState(0);
	const [analyzedRidge, setAnalyzedRidge] = useState(0);

	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			squareFootage: 0,
			ridge: 0,
			hip: 0,
			eave: 0,
			rake: 0,
			valleyMeasurement: 0,
			waste: 0,
			roofPitch: 0,
			chimneysDescription: '',
			chimneysCost: 0,
			chimneysHour: 0,
			permit: '',
			externalHourlyCost: 0,
			materialMarkup: 0,
			overhead: 0,
			profit: 0,
			skylightsDescription: '',
			skylightsCost: 0,
			skylightsHour: 0,
			otherToolsDescription: '',
			otherToolsCost: 0,
			otherToolsQuantity: 0,
			miscFlashingQuantity: 0,
			miscFlashingHour: 0,
			saddleQuantity: 0,
			saddleHour: 0,
			pipeJacksQuantity: 0,
			pipeJacksHour: 0,
			totalSquareFootageInputError: '',
			ridgeInputError: '',
			hipInputError: '',
			eaveInputError: '',
			rakeInputError: '',
			wasteInputError: '',
			overheadInputError: '',
			profitInputError: '',
			laborInputError: '',
			miscFlashingInputError: '',
			chimneyFlashingInputError: '',
			skylightFlashingInputError: '',
			otherToolsInputError: '',
			valleyInputError: '',
			saddleInputError: '',
			pipeJacksInputError: '',
		}
	);

	//PDF Render Style
	const styles = pdfRenderStyles;

	// Stripe Stuff
	const [clientSecret, setClientSecret] = useState('');
	const [paymentId, setPaymentId] = useState('');
	const appearance = {
		theme: 'stripe',
	};
	const options = {
		clientSecret,
		appearance,
	};
	const [paymentBox, setPaymentBox] = useState('block');

	// Persist login
	const persistLogin = localStorage.getItem('persist');
	const refresh = useRefreshToken();

	// PDF Stuff
	const [pdfReport, setPDFReport] = useState('');
	const [materialPricingPDF, setMaterialPricingPDF] = useState('');
	const [pdfDetailReport, setPDFDetailReport] = useState('');
	const [showAddToast, setShowAddToast] = useState(false);
	const [showDeleteToast, setShowDeleteToast] = useState(false);
	const secretUser = process.env.REACT_APP_SECRET;

	//PDF/IMAGE Upload Stuff
	const [image, setImage] = useState({
		data: '',
		name: 'No File Chosen',
	});

	// Uploaded image height and scale factor
	const [userEnteredHeight, setUserEnteredHeight] = useState(24);
	const [userEnteredScale, setUserEnteredScale] = useState(1.0);
	const [userEnteredSlope, setUserEnteredSlope] = useState(5.0);

	let selectedElement;
	const [disableBtn, setDisableBtn] = useState(false);
	const [spinner, setSpinner] = useState('not-loading');
	const [analyzing, setAnalyzing] = useState('Analyze');
	const [errorMsg, setErrorMsg] = useState('');
	const [failedAnalyze, setFailedAnalyze] = useState(false);
	//Material list
	const roofMaterialMasterList = props.masterMaterialsList;

	// Materials Default/Initial Value
	const [currentRoof, setCurrentRoof] = useState({
		value: 'Landmark',
		CostPerSquare: 138,
		PoundPerSqFt: 2.25,
		LaborHourPerSquare: 1.05,
		NumberOfBundlePerSquare: 3.0,
		RemoveHourPerSquare: 1.0,
		FastenerPerBundle: 88,
	});
	const [desiredRoof, setDesiredRoof] = useState({
		value: 'Landmark',
		CostPerSquare: 138.0,
		PoundPerSqFt: 2.25,
		LaborHourPerSquare: 1.05,
		NumberOfBundlePerSquare: 3.0,
		RemoveHourPerSquare: 1.0,
		FastenerPerBundle: 88,
	});
	const [desiredUnderlayment, setDesiredUnderlayment] = useState({
		value: '30# Felt 36in',
		CostPerRoll: 34.31,
		LaborHourPerSqFt: 0.0025,
		SqFtCoverPerRoll: 200,
	});
	const [roofingNails, setRoofingNails] = useState({
		value: '3/4 in',
		QtyPerBox: 7200,
		CostPerBox: 56.55,
	});
	const [plasticCaps, setPlasticCaps] = useState({
		value: '7/8 in',
		QtyPerBox: 2000,
		CostPerBox: 20.5,
	});
	const [ridgeNails, setRidgeNails] = useState({
		value: '3/4 in',
		QtyPerBox: 7200,
		CostPerBox: 56.55,
	});
	const [ridgeCoilNails, setRidgeCoilNails] = useState({
		value: '3/4 in',
		QtyPerBox: 7200,
		CostPerBox: 56.55,
	});
	const [caulkMaterial, setCaulkMaterial] = useState({
		value: 'Duralink',
		LFCoveragePerUnit: 500,
		CostPerUnit: 10.04,
	});
	const [paintMaterial, setPaintMaterial] = useState({
		value: 'Aervoe',
		LFCoveragePerUnit: 400,
		CostPerUnit: 6.74,
	});
	const [starterMaterial, setStarterMaterial] = useState({
		value: 'Presidential',
		LFCoveragePerUnit: 36.0,
		CostPerUnit: 79.6,
		LaborHourPerLinearFeet: 0.015,
	});
	const [lumberMaterial, setLumberMaterial] = useState({
		value: "4' Batts",
		CostPerUnit: 19.94,
		LaborHourPerUnit: 0.1,
	});
	const [roofingVentsMaterial, setRoofingVentsMaterial] = useState({
		value: "O'Hagin Shake",
		LFCoveragePerUnit: 100,
		CostPerUnit: 45.36,
		LaborHourPerUnit: 0.25,
	});
	const [intakeVentsMaterial, setIntakeVentsMaterial] = useState({
		value: 'None',
		CostPerUnit: 0,
		LaborHourPerUnit: 0,
		LFCoveragePerUnit: 0,
	});
	const [hipAndRidgeMaterial, setHipAndRidgeMaterial] = useState({
		value: '10in Mountain Ridge',
		LFCoveragePerUnit: 20.0,
		CostPerUnit: 82.29,
		LaborHourPerUnit: 0.25,
	});
	const [valleysMaterial, setValleysMaterial] = useState({
		value: 'Tile 24in',
		Description: 'Tile 24 in',
		CostPerUnit: 56.14,
		LaborHourPerUnit: 0.125,
	});
	const [nosingMaterial, setNosingMaterial] = useState({
		value: "Drip 1x2 in @10'",
		LFCoveragePerUnit: 10,
		CostPerUnit: 5.8,
		LaborHourPerLinearFeet: 0.0008333,
	});
	const [miscFlashingsMaterial, setMiscFlashingsMaterial] = useState({
		value: '12in x 20ft',
		Description: 'Lead Roll 12in x 20ft',
		CostPerUnit: 125.72,
		LaborHourPerUnit: 2.0,
	});
	const [pipeJacksAndAssemblies, setPipeJacksAndAssemblies] = useState({
		value: '1 in Regular Base',
		Description: '1 in Regular Base',
		CostPerUnit: 6.25,
	});
	const [saddlesMaterial, setSaddlesMaterial] = useState({
		value: '0in to 23in',
		Material: 'Shake Base',
		Description: 'No Cricket',
		CostPerUnit: 46.38,
	});
	const [newDeckMaterial, setNewDeckMaterial] = useState({
		value: "OSB (4' x 8')",
		LFCoveragePerUnit: 32,
		CostPerUnit: 21.55,
		LaborHourPerUnit: 0.333,
	});
	const [miscFlashingList, setMiscFlashingList] = useState([]);
	const [saddleMeasurementList, setSaddleMeasurementList] = useState([]);
	const [chimneyFlashingList, setChimneyFlashingList] = useState([]);
	const [skylightFlashingList, setSkylightFlashingList] = useState([]);
	const [valleyMeasurementList, setValleyMeasurementList] = useState([]);
	const [customToolsList, setCustomToolsList] = useState([]);
	const [pipeJacksList, setPipeJacksList] = useState([]);
	const [installComplexity, setInstallComplexity] = useState(1.0);
	//const [numberOfLayer, setNumberOfLayer] = useState('');
	//const [permit, setPermit] = useState('');
	const [tearOff, setTearOff] = useState('');
	const [newDeck, setNewDeck] = useState('');
	const [gutters, setGutters] = useState('');
	const [report, setReport] = useState('');
	//const [inputError, setInputError] = useState({ mistFlashingInputError: '' });
	const [totalChargeAmount, setTotalChargeAmount] = useState(0);
	const [displayLumberMaterial, setDisplayLumberMaterial] = useState('none');
	const [displayStarterMaterial, setDisplayStarterMaterial] = useState('');
	const [displayDeckMaterial, setDisplayDeckMaterial] = useState('none');

	//Modal Box & Agreement Message
	const [modalBox, setModalBox] = useState('none');
	const [agreementMessage, setAgreementMessage] = useState('block');

	// Scale factor dropdown (for analyzing)
	const scaleFactorDropDown = scaleFactor.map((data) => {
		return (
			<option key={data.name} name={data.name} value={data.scale}>
				{data.name}
			</option>
		);
	});
	// Slop factor dropdown (for analyzing)
	const slopeFactorDropDown = slopeFactor.map((data) => {
		return (
			<option key={data.name} name={data.name} value={data.scale}>
				{data.name}
			</option>
		);
	});
	// Materials & Dropdowns
	const asphaltShinglesDropdown =
		roofMaterialMasterList[0].Asphalt_Shingles.map((data) => {
			return (
				<option
					key={data.Style}
					name={data.Manufacture}
					value={data.Style}
					data-costpersquare={data.CostPerSquare}
					data-poundpersqft={data.PoundPerSqFt}
					data-laborhourpersquare={data.LaborHourPerSquare}
					data-removehourpersquare={data.RemoveHourPerSquare}
					data-fastenerperbundle={data.FastenerPerBundle}
					data-numberofbundlepersquare={data.NumberOfBundlePerSquare}>
					{data.Manufacture}-{data.Style}
				</option>
			);
		});

	const onePieceConcreteTilesDropdown =
		roofMaterialMasterList[0].One_Piece_Concrete_Tiles.map((data) => {
			return (
				<option
					key={data.Style + data.Color}
					name={data.Manufacture}
					value={data.Style}
					data-costpersquare={data.CostPerSquare}
					data-poundpersqft={data.PoundPerSqFt}
					daa-removehourpersquare={data.RemoveHourPerSquare}
					data-laborhourpersquare={data.LaborHourPerSquare}>
					{data.Manufacture}-{data.Style}
				</option>
			);
		});

	const usClayTilesDropdown = roofMaterialMasterList[0].US_Clay_Tiles.map(
		(data) => {
			return (
				<option
					key={data.Style + data.Color}
					name={data.Manufacture}
					value={data.Style + ' ' + data.Color}
					data-costpersquare={data.CostPerSquare}
					data-laborhourpersquare={data.LaborHourPerSquare}
					data-removehourpersquare={data.RemoveHourPerSquare}>
					{data.Manufacture}-{data.Style}
				</option>
			);
		}
	);

	const underlaymentsDropdown = roofMaterialMasterList[0].Underlayment.map(
		(data) => {
			return (
				<option
					key={data.Style}
					value={data.Style}
					data-costperroll={data.CostPerRoll}
					data-laborhourpersqft={data.LaborHourPerSqFt}
					data-sqftcoverperroll={data.SqFtCoverPerRoll}>
					{data.Style}
				</option>
			);
		}
	);

	// const fastenersDropdown = roofMaterialMasterList[0].Fasteners.map((data) => {
	// 	return (
	// 		<option
	// 			key={data.Size + data.Type}
	// 			name={data.Type}
	// 			value={data.Size}
	// 			data-costperbox={data.CostPerBox}
	// 			data-qtyperbox={data.QtyPerBox}>
	// 			{data.Type} | {data.Size}
	// 		</option>
	// 	);
	// });

	const coilNailsDropdown = roofMaterialMasterList[0].CoilNails.map((data) => {
		return (
			<option
				key={data.Size + data.Type}
				name={data.Type}
				value={data.Size}
				data-costperbox={data.CostPerBox}
				data-qtyperbox={data.QtyPerBox}>
				{data.Type} | {data.Size}
			</option>
		);
	});

	const plasticCapsDropdown = roofMaterialMasterList[0].PlasticCaps.map(
		(data) => {
			return (
				<option
					key={data.Size + data.Type}
					name={data.Type}
					value={data.Size}
					data-costperbox={data.CostPerBox}
					data-qtyperbox={data.QtyPerBox}>
					{data.Type} | {data.Size}
				</option>
			);
		}
	);

	const stripNailsDropdown = roofMaterialMasterList[0].StripNails.map(
		(data) => {
			return (
				<option
					key={data.Size + data.Type}
					name={data.Type}
					value={data.Size}
					data-costperbox={data.CostPerBox}
					data-qtyperbox={data.QtyPerBox}>
					{data.Type} | {data.Size}
				</option>
			);
		}
	);

	const caulkDropdown = roofMaterialMasterList[0].Caulk.map((data) => {
		return (
			<option
				key={data.Style}
				value={data.Style}
				data-lfcoverageperunit={data.LFCoveragePerUnit}
				data-costperunit={data.CostPerUnit}>
				{data.Style} | {data.Color}
			</option>
		);
	});

	const paintDropdown = roofMaterialMasterList[0].Paint.map((data) => {
		return (
			<option
				key={data.Type}
				value={data.Type}
				data-lfcoverageperunit={data.LFCoveragePerUnit}
				data-costperunit={data.CostPerUnit}>
				{data.Type} | {data.Color}
			</option>
		);
	});

	const startersDropdown = roofMaterialMasterList[0].Starter.map((data) => {
		return (
			<option
				key={data.Type}
				value={data.Type}
				data-lfcoverageperunit={data.LFCoveragePerUnit}
				data-costperunit={data.CostPerUnit}
				data-laborhourperlinearfeet={data.LaborHourPerLinearFeet}>
				{data.Type}
			</option>
		);
	});

	const roofingVentsDropdown = roofMaterialMasterList[0].Vents.map((data) => {
		return (
			<option
				key={data.Type}
				value={data.Type}
				data-lfcoverageperunit={data.LFCoveragePerUnit}
				data-costperunit={data.CostPerUnit}
				data-laborhourperunit={data.LaborHourPerUnit}>
				{data.Type}
			</option>
		);
	});

	const intakeVentsDropdown = roofMaterialMasterList[0].IntakeVents.map(
		(data) => {
			return (
				<option
					key={data.Type}
					value={data.Type}
					data-lfcoverageperunit={data.LFCoveragePerUnit}
					data-costperunit={data.CostPerUnit}
					data-laborhourperunit={data.LaborHourPerUnit}>
					{data.Type}
				</option>
			);
		}
	);

	const hipAndRidgeDropdown = roofMaterialMasterList[0].HipAndRidge.map(
		(data) => {
			return (
				<option
					key={data.Type}
					value={data.Type}
					data-lfcoverageperunit={data.LFCoveragePerUnit}
					data-costperunit={data.CostPerUnit}
					data-laborhourperunit={data.LaborHourPerUnit}>
					{data.Type}
				</option>
			);
		}
	);

	const deckingDropdown = roofMaterialMasterList[0].Decking.map((data) => {
		return (
			<option
				key={data.Style + data.Thickness}
				value={data.Style}
				data-lfcoverageperunit={data.LFCoveragePerUnit}
				data-costperunit={data.CostPerUnit}
				data-laborhourperunit={data.LaborHourPerUnit}>
				{data.Style} | {data.Thickness} | {data.WoodType}
			</option>
		);
	});

	const lumberDropdown = roofMaterialMasterList[0].Lumber.map((data) => {
		return (
			<option
				key={data.Style + data.Thickness}
				value={data.Style}
				data-costperunit={data.CostPerUnit}
				data-laborhourperunit={data.LaborHourPerUnit}>
				{data.Style} | {data.Thickness} | {data.WoodType}
			</option>
		);
	});

	const valleysDropdown = roofMaterialMasterList[0].Valleys.map((data) => {
		return (
			<option
				key={data.Type + data.Size}
				value={data.Size + ' ' + data.Type}
				data-description={data.Type + ' ' + data.Size}
				data-costperunit={data.CostPerUnit}
				data-laborhourperunit={data.LaborHourPerUnit}>
				{data.Type} | {data.Size} | {data.Color}
			</option>
		);
	});

	const nosingDropdown = roofMaterialMasterList[0].Nosing.map((data) => {
		return (
			<option
				key={data.Type + data.Size}
				value={data.Size}
				data-lfcoverageperunit={data.LFCoveragePerUnit}
				data-laborhourperlinearfeet={data.LaborHourPerLinearFeet}
				data-costperunit={data.CostPerUnit}>
				{data.Type} | {data.Size}
			</option>
		);
	});

	const miscFlashingDropdown = roofMaterialMasterList[0].MiscFlashings.map(
		(data) => {
			return (
				<option
					key={data.Size + data.Detail}
					value={data.Size}
					data-description={data.Type + ' ' + data.Size}
					data-costperunit={data.CostPerUnit}
					data-laborhourperunit={data.LaborHourPerUnit}>
					{data.Type} | {data.Size} | {data.Detail}
				</option>
			);
		}
	);

	const pipeJacksAndAssembliesDropdown =
		roofMaterialMasterList[0].PipeJacksAndAssemblies.map((data) => {
			return (
				<option
					key={data.Size + data.Style}
					value={data.Size}
					data-description={data.Size + ' ' + data.Style}
					data-costperunit={data.CostPerUnit}>
					{data.Style} | {data.Size}
				</option>
			);
		});

	const saddlesDropdown = roofMaterialMasterList[0].Saddles.map((data) => {
		return (
			<option
				key={data.Size + data.Style}
				value={data.Size}
				data-material={data.Type}
				data-description={data.Style}
				data-costperunit={data.CostPerUnit}>
				{data.Type} | {data.Size} | {data.Style}
			</option>
		);
	});

	const uploadImage = async () => {
		let formData = new FormData();
		formData.append('file', image.data);
		formData.append('user_entered_height', userEnteredHeight);
		formData.append('user_entered_scale', userEnteredScale);
		formData.append('user_entered_slope_factor', userEnteredSlope);
		await fetch(
			//'http://127.0.0.1:8000/analyze',
			'https://www.opticost.io/analyze',
			//'https://54.177.155.94/analyze', // aws public ip
			{
				method: 'POST',
				headers: {
					'Access-Control-Allow-Origin': '*',
					'Access-Control-Allow-Methods': 'POST',
				}, // these comment out for local, uncomment for aws
				body: formData,
				processData: false,
				contentType: false,
			},
			setSpinner('bootstrap-loading'),
			setAnalyzing('Analyzing...'),
			setDisableBtn(true),
			setErrorMsg('')
		)
			.then((res) => res.json())
			.then((res) => {
				if (res) {
					setSpinner('not-loading');
					setAnalyzing('Analyze');
					setErrorMsg('Finish analyzing, proceed below');
					setFailedAnalyze(false);
					setDisableBtn(false);
					setAnalyzedSquareFootage(parseFloat(res.measurement_of_roof_area));
					setAnalyzedEave(parseFloat(res.Eave));
					setAnalyzedHip(parseFloat(res.Hip));
					setAnalyzedRidge(parseFloat(res.Ridge));
					setAnalyzedValley(parseFloat(res.Valley));
				}
			})
			.catch((err) => {
				console.log(err);
				setAnalyzing('Analyze');
				setSpinner('not-loading');
				setDisableBtn(false);
				setFailedAnalyze(true);
				setErrorMsg(
					'Error processing file, try again or upload a different file.'
				);
			});
	};

	const handleFileUploadChange = (event) => {
		if (event.target.files.length) {
			setImage({
				data: event.target.files[0],
				name: event.target.files[0].name,
			});
		}
	};

	const handleUserEnteredHeightChange = (event) => {
		setUserEnteredHeight(event.target.value);
	};

	const handleScaleFactorChange = (event) => {
		setUserEnteredScale(parseFloat(event.target.value));
	};

	const handleSlopeFactorChange = (event) => {
		setUserEnteredSlope(parseFloat(event.target.value));
	};

	const handleModalBox = () => {
		setModalBox('none');
	};

	const handleAddToast = () => {
		if (showAddToast) {
			setShowAddToast(false);
		} else setShowAddToast(true);
	};

	const handleDeleteToast = () => {
		if (showDeleteToast) {
			setShowDeleteToast(false);
		} else setShowDeleteToast(true);
	};

	const handleCurrentRoofChange = (event) => {
		setCurrentRoof({
			value: event.target.value,
			CostPerSquare: event.target.selectedOptions[0].dataset.costpersquare,
			LaborHourPerSquare:
				event.target.selectedOptions[0].dataset.laborhourpersquare,
			NumberOfBundlePerSquare:
				event.target.selectedOptions[0].dataset.numberofbundlepersquare,
			PoundPerSqFt: event.target.selectedOptions[0].dataset.poundpersqft,
			RemoveHourPerSquare:
				event.target.selectedOptions[0].dataset.removehourpersquare,
			FastenerPerBundle:
				event.target.selectedOptions[0].dataset.fastenerperbundle,
		});
		selectedElement = document.getElementById('current-roof');
		selectedElement.classList.add('form-select-focus');
	};
	const handleDesiredRoofChange = (event) => {
		if (
			(event.target.value === 'Landmark' ||
				event.target.value === 'Landmark Solaris' ||
				event.target.value === 'Landmark Pro' ||
				event.target.value === 'Landmark Pro Solaris' ||
				event.target.value === 'Landmark Premium' ||
				event.target.value === 'Landmark TL' ||
				event.target.value === 'Landmark TL Solaris' ||
				event.target.value === 'Presidential' ||
				event.target.value === 'Presidential Solaris' ||
				event.target.value === 'Presidential TL' ||
				event.target.value === 'Presidential TL Solaris') &&
			displayStarterMaterial === 'none'
		) {
			setDisplayStarterMaterial('');
			setDisplayLumberMaterial('none');
		}
		if (
			(event.target.value === 'Barcelona 900' ||
				event.target.value === 'Saxony 900 Slate' ||
				event.target.value === 'Saxony 900 Shake' ||
				event.target.value === 'Saxony 900 Split' ||
				event.target.value === 'Saxony 900 Hartford' ||
				event.target.value === 'Villa 900' ||
				event.target.value === 'Madera 900' ||
				event.target.value === 'Duralite Espana' ||
				event.target.value === 'Duralite Villa' ||
				event.target.value === 'Duralite Saxony 600' ||
				event.target.value === 'Duralite Saxony 700' ||
				event.target.value === 'Cedarlite 600' ||
				event.target.value === 'Madera 700' ||
				//clay tile
				event.target.value === "1 Pc 'S' Tile Flat Color" ||
				event.target.value === "1 Pc 'S' Tile Standard Blends" ||
				event.target.value === '2 Pc Mission Flat Color' ||
				event.target.value === '2 Pc Mission Standard Blends' ||
				event.target.value === '2 Pc Monarch All Blends' ||
				event.target.value === 'Romano Pans Any' ||
				event.target.value === 'Claylite Any' ||
				event.target.value === 'Claymax Any') &&
			displayLumberMaterial === 'none'
		) {
			setDisplayLumberMaterial('');
			setDisplayStarterMaterial('none');
		}
		setDesiredRoof({
			value: event.target.value,
			CostPerSquare: event.target.selectedOptions[0].dataset.costpersquare,
			LaborHourPerSquare:
				event.target.selectedOptions[0].dataset.laborhourpersquare,
			NumberOfBundlePerSquare:
				event.target.selectedOptions[0].dataset.numberofbundlepersquare,
			RemoveHourPerSquare:
				event.target.selectedOptions[0].dataset.removehourpersquare,
			FastenerPerBundle:
				event.target.selectedOptions[0].dataset.fastenerperbundle,
		});
		selectedElement = document.getElementById('desire-roof');
		selectedElement.classList.add('form-select-focus');
	};
	const handleDesiredUnderlaymentChange = (event) => {
		setDesiredUnderlayment({
			value: event.target.value,
			CostPerRoll: event.target.selectedOptions[0].dataset.costperroll,
			LaborHourPerSqFt:
				event.target.selectedOptions[0].dataset.laborhourpersqft,
			SqFtCoverPerRoll:
				event.target.selectedOptions[0].dataset.sqftcoverperroll,
		});
		selectedElement = document.getElementById('desire-underlayment');
		selectedElement.classList.add('form-select-focus');
	};
	const handleRoofingNailsChange = (event) => {
		setRoofingNails({
			value: event.target.value,
			QtyPerBox: event.target.selectedOptions[0].dataset.qtyperbox,
			CostPerBox: event.target.selectedOptions[0].dataset.costperbox,
		});
		selectedElement = document.getElementById('roof-nail');
		selectedElement.classList.add('form-select-focus');
	};
	const handleRidgeCoilNailsChange = (event) => {
		setRidgeCoilNails({
			value: event.target.value,
			QtyPerBox: event.target.selectedOptions[0].dataset.qtyperbox,
			CostPerBox: event.target.selectedOptions[0].dataset.costperbox,
		});
		selectedElement = document.getElementById('ridge-coil-nail');
		selectedElement.classList.add('form-select-focus');
	};
	const handlePlasticCapsChange = (event) => {
		setPlasticCaps({
			value: event.target.value,
			QtyPerBox: event.target.selectedOptions[0].dataset.qtyperbox,
			CostPerBox: event.target.selectedOptions[0].dataset.costperbox,
		});
		selectedElement = document.getElementById('plastic-cap');
		selectedElement.classList.add('form-select-focus');
	};
	const handleRidgeNailsChange = (event) => {
		setRidgeNails({
			value: event.target.value,
			QtyPerBox: event.target.selectedOptions[0].dataset.qtyperbox,
			CostPerBox: event.target.selectedOptions[0].dataset.costperbox,
		});
		selectedElement = document.getElementById('ridge-nail');
		selectedElement.classList.add('form-select-focus');
	};
	const handleStarterMaterialChange = (event) => {
		setStarterMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerLinearFeet:
				event.target.selectedOptions[0].dataset.laborhourperlinearfeet,
		});
		selectedElement = document.getElementById('starter-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleLumberMaterialChange = (event) => {
		setLumberMaterial({
			value: event.target.value,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('lumber');
		selectedElement.classList.add('form-select-focus');
	};

	const handleNewDeckChange = (event) => {
		if (event.target.value === 'NO' && displayDeckMaterial === '') {
			setDisplayDeckMaterial('none');
		} else if (event.target.value === 'YES' && displayDeckMaterial === 'none') {
			setDisplayDeckMaterial('');
		}
		setNewDeck(event.target.value);
	};
	const handleNewDeckMaterialChange = (event) => {
		setNewDeckMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('new-deck-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleRoofingVentsMaterialChange = (event) => {
		setRoofingVentsMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('roofing-vent');
		selectedElement.classList.add('form-select-focus');
	};
	const handleIntakeVentsMaterialChange = (event) => {
		setIntakeVentsMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('intake-vent');
		selectedElement.classList.add('form-select-focus');
	};
	const handleValleysMaterialChange = (event) => {
		setValleysMaterial({
			value: event.target.value,
			Description: event.target.selectedOptions[0].dataset.description,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('valley-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handlePaintMaterialChange = (event) => {
		setPaintMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
		});
		selectedElement = document.getElementById('paint-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleHipAndRidgeMaterialChange = (event) => {
		setHipAndRidgeMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('hip-ridge-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleCaulkMaterialChange = (event) => {
		setCaulkMaterial({
			value: event.target.value,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
		});
		selectedElement = document.getElementById('caulk-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleNosingMaterialChange = (event) => {
		setNosingMaterial({
			value: event.target.value,
			LFCoveragePerUnit:
				event.target.selectedOptions[0].dataset.lfcoverageperunit,
			LaborHourPerLinearFeet:
				event.target.selectedOptions[0].dataset.laborhourperlinearfeet,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
		});
		selectedElement = document.getElementById('nosing-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleMiscFlashingsMaterialChange = (event) => {
		setMiscFlashingsMaterial({
			value: event.target.value,
			Description: event.target.selectedOptions[0].dataset.description,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
			LaborHourPerUnit:
				event.target.selectedOptions[0].dataset.laborhourperunit,
		});
		selectedElement = document.getElementById('misc-flashing-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handlePipeJacksAndAssembliesMaterialChange = (event) => {
		setPipeJacksAndAssemblies({
			value: event.target.value,
			Description: event.target.selectedOptions[0].dataset.description,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
		});
		selectedElement = document.getElementById('pipe-jack');
		selectedElement.classList.add('form-select-focus');
	};
	const handleSaddlesMaterialChange = (event) => {
		setSaddlesMaterial({
			value: event.target.value,
			Material: event.target.selectedOptions[0].dataset.material,
			Description: event.target.selectedOptions[0].dataset.description,
			CostPerUnit: event.target.selectedOptions[0].dataset.costperunit,
		});
		selectedElement = document.getElementById('saddle-material');
		selectedElement.classList.add('form-select-focus');
	};
	const handleGuttersChange = (event) => {
		setGutters(event.target.value);
	};
	// const handleNumberOfLayerChange = (event) => {
	// 	setNumberOfLayer(event.target.value);
	// };
	// const handlePermitChange = (event) => {
	// 	setPermit(event.target.value);
	// };
	const handleInstallComplexityChange = (event) => {
		setInstallComplexity(parseFloat(event.target.value));
		selectedElement = document.getElementById('complexity');
		selectedElement.classList.add('bold-blue');
	};
	const handleTearOffChange = (event) => {
		setTearOff(event.target.value);
	};
	const handleChange = (event) => {
		const name = event.target.name;
		let newValue;
		const validInput = /^[0-9]\d*(\.\d+)?$/;
		if (
			event.target.name === 'chimneysDescription' ||
			event.target.name === 'skylightsDescription' ||
			event.target.name === 'otherToolsDescription'
		) {
			newValue = event.target.value;
		} else if (
			event.target.name !== 'chimneysDescription' ||
			event.target.name !== 'skylightsDescription' ||
			event.target.name !== 'otherToolsDescription'
		) {
			if (!event.target.value.match(validInput)) {
				switch (event.target.name) {
					case 'squareFootage':
						userInput.totalSquareFootageInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'ridge':
						userInput.ridgeInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'hip':
						userInput.hipInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'eave':
						userInput.eaveInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'rake':
						userInput.rakeInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'waste':
						userInput.wasteInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'overhead':
						userInput.overheadInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'profit':
						userInput.profitInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'externalHourlyCost':
						userInput.laborInputError = 'Input must be a number';
						newValue = 0;
						break;
					case 'miscFlashingQuantity':
					case 'miscFlashingHour':
						userInput.miscFlashingInputError = 'Quantity/Hour must be a number';
						newValue = 0;
						break;
					case 'chimneysCost':
					case 'chimneysHour':
						userInput.chimneyFlashingInputError = 'Cost/Hour must be a number';
						newValue = 0;
						break;
					case 'skylightsCost':
					case 'skylightsHour':
						userInput.skylightFlashingInputError = 'Cost/Hour must be a number';
						newValue = 0;
						break;
					case 'otherToolsCost':
					case 'otherToolsQuantity':
						userInput.otherToolsInputError = 'Cost/Quantity must be a number';
						newValue = 0;
						break;
					case 'valleyMeasurement':
						userInput.valleyInputError = 'Measurement must be a number';
						newValue = 0;
						break;
					case 'saddleQuantity':
					case 'saddleHour':
						userInput.saddleInputError = 'Quantity/Hour must be a number';
						newValue = 0;
						break;
					case 'pipeJacksQuantity':
					case 'pipeJacksHour':
						userInput.pipeJacksInputError = 'Quantity/Hour must be a number';
						newValue = 0;
						break;
					default:
						newValue = 0;
						break;
				}
			} else if (event.target.value.match(validInput)) {
				userInput.totalSquareFootageInputError = '';
				userInput.ridgeInputError = '';
				userInput.hipInputError = '';
				userInput.eaveInputError = '';
				userInput.rakeInputError = '';
				userInput.wasteInputError = '';
				userInput.overheadInputError = '';
				userInput.profitInputError = '';
				userInput.laborInputError = '';
				userInput.miscFlashingInputError = '';
				userInput.chimneyFlashingInputError = '';
				userInput.skylightFlashingInputError = '';
				userInput.otherToolsInputError = '';
				userInput.valleyInputError = '';
				userInput.saddleInputError = '';
				userInput.pipeJacksInputError = '';
				newValue = parseFloat(event.target.value);
			} else {
				newValue = 0;
			}
		}
		setUserInput({ [name]: newValue });
	};
	const handleProjectInfoChange = (event) => {
		const name = event.target.name;
		const newValue = event.target.value;
		setProjectInfoInput({ [name]: newValue });
	};
	const addMiscFlashing = (event) => {
		event.preventDefault();
		let newMiscFlashingList = [...miscFlashingList];
		const newMiscFlashingItem = {
			description: miscFlashingsMaterial.Description,
			quantity: userInput.miscFlashingQuantity,
			hour: userInput.miscFlashingHour,
			cost: miscFlashingsMaterial.CostPerUnit,
			key:
				miscFlashingsMaterial.Description +
				'-' +
				userInput.miscFlashingQuantity +
				'-' +
				userInput.miscFlashingHour +
				'-' +
				(miscFlashingList.length + 1),
		};
		newMiscFlashingList.push(newMiscFlashingItem);
		setMiscFlashingList(newMiscFlashingList);
		const elementId = ['misc-flashing-qty', 'misc-flashing-hr'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};
	const addChimneyFlashing = (event) => {
		event.preventDefault();
		let newChimneyFlashingList = [...chimneyFlashingList];
		const newChimneyItem = {
			description: userInput.chimneysDescription,
			cost: userInput.chimneysCost,
			hour: userInput.chimneysHour,
			key:
				userInput.chimneysDescription +
				'-' +
				userInput.chimneysCost +
				'-' +
				userInput.chimneysHour +
				'-' +
				(chimneyFlashingList.length + 1),
		};
		newChimneyFlashingList.push(newChimneyItem);
		setChimneyFlashingList(newChimneyFlashingList);
		const elementId = ['chimney-desc', 'chimney-cost', 'chimney-hr'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};
	const addSkylightFlashing = (event) => {
		event.preventDefault();
		let newSkylightFlashingList = [...skylightFlashingList];
		const newSkylightItem = {
			description: userInput.skylightsDescription,
			cost: userInput.skylightsCost,
			hour: userInput.skylightsHour,
			key:
				userInput.skylightsDescription +
				'-' +
				userInput.skylightsCost +
				'-' +
				userInput.skylightsHour +
				'-' +
				(skylightFlashingList.length + 1),
		};
		newSkylightFlashingList.push(newSkylightItem);
		setSkylightFlashingList(newSkylightFlashingList);
		const elementId = ['skylight-desc', 'skylight-cost', 'skylight-hr'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};
	const addValleyMeasurement = (event) => {
		event.preventDefault();
		let newValleyMeasurementList = [...valleyMeasurementList];
		const newValleyItem = {
			description: valleysMaterial.Description,
			quantity: valleyMeasurementList.length + 1,
			measurement: userInput.valleyMeasurement,
			key:
				valleysMaterial.Description +
				'-' +
				userInput.valleyMeasurement +
				'-' +
				(valleyMeasurementList.length + 1),
		};
		newValleyMeasurementList.push(newValleyItem);
		setValleyMeasurementList(newValleyMeasurementList);
		const elementId = ['misc-flashing-qty', 'misc-flashing-hr'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};
	const addSaddleMeasurement = (event) => {
		event.preventDefault();
		let newSaddleList = [...saddleMeasurementList];
		const newSaddleItem = {
			description: saddlesMaterial.value + ' ' + saddlesMaterial.Description,
			material: saddlesMaterial.Material,
			cost: saddlesMaterial.CostPerUnit,
			quantity: userInput.saddleQuantity,
			hour: userInput.saddleHour,
			key:
				userInput.saddleQuantity +
				'-' +
				userInput.saddleHour +
				'-' +
				(saddleMeasurementList.length + 1),
		};
		newSaddleList.push(newSaddleItem);
		setSaddleMeasurementList(newSaddleList);
		const elementId = ['saddle-qty', 'saddle-hr'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};
	const addCustomTools = (event) => {
		event.preventDefault();
		let newCustomToolsList = [...customToolsList];
		const newToolItem = {
			description: userInput.otherToolsDescription,
			quantity: userInput.otherToolsQuantity,
			cost: userInput.otherToolsCost,
			key:
				userInput.otherToolsDescription +
				'-' +
				userInput.otherToolsQuantity +
				'-' +
				(customToolsList.length + 1),
		};
		newCustomToolsList.push(newToolItem);
		setCustomToolsList(newCustomToolsList);
		const elementId = ['tool-desc', 'tool-cost', 'tool-qty'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};
	const addPipeJacksAndAssemblies = (event) => {
		event.preventDefault();
		let newPipeJacksList = [...pipeJacksList];
		const newPipeJacksItem = {
			description: pipeJacksAndAssemblies.Description,
			quantity: userInput.pipeJacksQuantity,
			cost: pipeJacksAndAssemblies.CostPerUnit,
			hour: userInput.pipeJacksHour,
			key:
				pipeJacksAndAssemblies.Description +
				'-' +
				userInput.quantity +
				'-' +
				(pipeJacksList.length + 1),
		};
		newPipeJacksList.push(newPipeJacksItem);
		setPipeJacksList(newPipeJacksList);
		const elementId = ['pipe-jack-qty', 'pipe-jack-hr'];
		elementId.forEach((id) => {
			selectedElement = document.getElementById(id);
			if (selectedElement) {
				selectedElement.classList.add('form-control-focus');
			}
		});
		handleAddToast();
	};

	const removeCustomItems = (event) => {
		event.preventDefault();
		const uniqueId = event.target.id;
		const toolIndex = customToolsList.map((item) => item.key).indexOf(uniqueId);
		const chimneyIndex = chimneyFlashingList
			.map((item) => item.key)
			.indexOf(uniqueId);
		const skylightIndex = skylightFlashingList
			.map((item) => item.key)
			.indexOf(uniqueId);
		const valleyIndex = valleyMeasurementList
			.map((item) => item.key)
			.indexOf(uniqueId);
		const miscFlashingIndex = miscFlashingList
			.map((item) => item.key)
			.indexOf(uniqueId);
		const saddleIndex = saddleMeasurementList
			.map((item) => item.key)
			.indexOf(uniqueId);
		const pipeJackIndex = pipeJacksList
			.map((item) => item.key)
			.indexOf(uniqueId);
		let newToolList = [...customToolsList];
		let newChimneyFlashingList = [...chimneyFlashingList];
		let newSkylightFlashingList = [...skylightFlashingList];
		let newValleyMeasurementList = [...valleyMeasurementList];
		let newMiscFlashingList = [...miscFlashingList];
		let newSaddleMeasurementList = [...saddleMeasurementList];
		let newPipeJacksList = [...pipeJacksList];
		if (toolIndex > -1) {
			newToolList.splice(toolIndex, 1);
			setCustomToolsList(newToolList);
			handleDeleteToast();
		}
		if (chimneyIndex > -1) {
			newChimneyFlashingList.splice(chimneyIndex, 1);
			setChimneyFlashingList(newChimneyFlashingList);
			handleDeleteToast();
		}
		if (skylightIndex > -1) {
			newSkylightFlashingList.splice(skylightIndex, 1);
			setSkylightFlashingList(newSkylightFlashingList);
			handleDeleteToast();
		}
		if (valleyIndex > -1) {
			newValleyMeasurementList.splice(valleyIndex, 1);
			setValleyMeasurementList(newValleyMeasurementList);
			handleDeleteToast();
		}
		if (miscFlashingIndex > -1) {
			newMiscFlashingList.splice(miscFlashingIndex, 1);
			setMiscFlashingList(newMiscFlashingList);
			handleDeleteToast();
		}
		if (saddleIndex > -1) {
			newSaddleMeasurementList.splice(saddleIndex, 1);
			setSaddleMeasurementList(newSaddleMeasurementList);
			handleDeleteToast();
		}
		if (pipeJackIndex > -1) {
			newPipeJacksList.splice(pipeJackIndex, 1);
			setPipeJacksList(newPipeJacksList);
			handleDeleteToast();
		}
	};

	let tax = 1.09125;
	let waste = userInput.waste / 100 + 1;
	let markup = 1;
	let overhead = userInput.overhead / 100 + 1;
	let profit = userInput.profit / 100 + 1;
	let actualOverhead = userInput.overhead / 100;
	let actualProfit = userInput.profit / 100;
	let externalInstallRoofCost;
	let roofMaterialCost;
	let roofMaterialQuantity;
	let roofMaterialUnit;
	let totalRoofInstallLaborHour;
	let externalStarterShingleCost;
	let starterShingleMaterialCost;
	let starterShingleMaterialQuantity;
	let starterShingleMaterialUnit;
	let totalStarterShingleLaborHour;
	let externalLumberCost;
	let lumberMaterialCost;
	let lumberMaterialQuantity;
	let totalLumberLaborHour;
	let externalUnderlaymentCost;
	let underlaymentMaterialCost;
	let underlaymentMaterialQuantity;
	let underlaymentMaterialUnit;
	let totalUnderlaymentLaborHour;
	let externalRidgeCost;
	let totalRidgeLaborHour;
	let ridgeMaterialCost;
	let ridgeMaterialQuantity;
	let ridgeMaterialUnit;
	let externalHipCost;
	let totalHipLaborHour;
	let hipMaterialCost;
	let hipMaterialQuantity;
	let hipAndRidgeMaterialQuantity;
	let hipAndRidgeMaterialUnit;
	let hipMaterialUnit;
	let externalValleyCost;
	let totalValleyLaborHour;
	let valleyMaterialCost;
	let valleyMaterialQuantity;
	let valleyMaterialUnit;
	let externalRakeCost;
	let totalRakeLaborHour;
	let rakeMaterialCost;
	let rakeMaterialQuantity;
	let rakeMaterialUnit;
	let externalEaveCost;
	let totalEaveLaborHour;
	let eaveMaterialCost;
	let eaveMaterialQuantity;
	let eaveMaterialUnit;
	let externalGuttersCost;
	let totalGuttersLaborHour;
	let guttersMaterialCost;
	let guttersMaterialQuantity;
	let externalDownspoutCost;
	let downSpoutMaterialCost;
	let downSpoutMaterialQuantity;
	let totalDownSpoutLaborHour;
	let externalRoofingVentCost;
	let totalRoofingVentLaborHour;
	let roofingVentsMaterialCost;
	let roofingVentsMaterialQuantity;
	let roofingVentsMaterialUnit;
	let externalIntakeVentsCost;
	let intakeVentsMaterialCost;
	let intakeVentsQuantity;
	let totalIntakeVentsLaborHour;
	let externalDeckCost;
	let totalDeckLaborHour;
	let deckMaterialCost;
	let deckMaterialQuantity;
	let deckMaterialUnit;
	let miscFlashingMaterialCost;
	let totalMiscFlashingLaborHour;
	let externalMiscFlashingCost;
	let miscFlashingMaterialQuantity;
	let externalChimneyFlashingCost;
	let totalChimneyLaborHour;
	let chimneyMaterialCost;
	let chimneyMaterialQuantity;
	let externalSkylightFlashingCost;
	let totalSkylightLaborHour;
	let skylightMaterialCost;
	let skylightMaterialQuantity;
	let externalTearOffCost;
	let totalTearOffLaborHour;
	let disposalCost;
	let roofingNailCost;
	let roofingNailQuantity;
	let roofingNailUnit;
	let ridgeCoilNailCost;
	let ridgeCoilNailQuantity;
	let ridgeCoilNailUnit;
	let ridgeNailCost;
	let ridgeNailQuantity;
	let ridgeNailUnit;
	let plasticCapCost;
	let plasticCapQuantity;
	let plasticCapUnit;
	let customToolsCost;
	let customToolsQuantity;
	let saddleMaterialCost;
	let totalSaddleLaborHour;
	let externalSaddleCost;
	let caulkMaterialCost;
	let caulkQuantity;
	let paintMaterialCost;
	let paintQuantity;
	let externalPipeJacksCost;
	let pipeJacksMaterialCost;
	let totalNailsCost;
	let totalPipeJacksLaborHour;
	let externalTotalLaborCost;
	let externalGrandTotal;
	let externalGrandTotalWithOverheadAndProfit;
	let totalMaterialCost;
	let totalLaborHour;

	const calculateInstallRoofCost = () => {
		if (
			desiredRoof.value === 'Landmark' ||
			desiredRoof.value === 'Landmark Solaris' ||
			desiredRoof.value === 'Landmark Pro' ||
			desiredRoof.value === 'Landmark Pro Solaris' ||
			desiredRoof.value === 'Landmark Premium' ||
			desiredRoof.value === 'Landmark TL' ||
			desiredRoof.value === 'Landmark TL Solaris' ||
			desiredRoof.value === 'Presidential' ||
			desiredRoof.value === 'Presidential Solaris' ||
			desiredRoof.value === 'Presidential TL' ||
			desiredRoof.value === 'Presidential TL Solaris'
		) {
			roofMaterialUnit =
				Math.ceil(
					(userInput.squareFootage * waste) /
						(100 / desiredRoof.NumberOfBundlePerSquare)
				) + ' Bundle(s)';
			roofingNailQuantity =
				Math.ceil(
					(userInput.squareFootage * waste) /
						(100 / desiredRoof.NumberOfBundlePerSquare)
				) * desiredRoof.FastenerPerBundle;
			roofingNailUnit =
				Math.ceil(roofingNailQuantity / roofingNails.QtyPerBox) + ' Box(es)';
			roofingNailCost =
				Math.ceil(roofingNailQuantity / roofingNails.QtyPerBox) *
				roofingNails.CostPerBox;
			ridgeCoilNailQuantity =
				Math.ceil(
					(userInput.squareFootage * waste) /
						(100 / desiredRoof.NumberOfBundlePerSquare)
				) * desiredRoof.FastenerPerBundle;
			ridgeCoilNailUnit =
				Math.ceil(ridgeCoilNailQuantity / ridgeCoilNails.QtyPerBox) +
				' Box(es)';
			ridgeCoilNailCost =
				Math.ceil(ridgeCoilNailQuantity / ridgeCoilNails.QtyPerBox) *
				ridgeCoilNails.CostPerBox;
		} else {
			roofMaterialUnit = Math.ceil(userInput.squareFootage * waste) + ' Pieces';
			roofingNailQuantity = 0;
			roofingNailCost = 0;
			roofingNailUnit = '0 Box(es)';
			ridgeCoilNailQuantity = 0;
			ridgeCoilNailCost = 0;
			ridgeCoilNailUnit = '0 Box(es)';
		}
		roofMaterialQuantity =
			(userInput.squareFootage * waste).toFixed(2) + ' Sqft';
		externalInstallRoofCost =
			desiredRoof.LaborHourPerSquare *
			(userInput.squareFootage / 100) *
			userInput.externalHourlyCost *
			installComplexity;
		totalRoofInstallLaborHour =
			desiredRoof.LaborHourPerSquare *
			(userInput.squareFootage / 100) *
			installComplexity;
		roofMaterialCost =
			desiredRoof.CostPerSquare *
			Math.ceil((userInput.squareFootage * waste) / 100);

		return (
			externalInstallRoofCost,
			roofMaterialCost,
			roofMaterialQuantity,
			totalRoofInstallLaborHour,
			roofMaterialUnit,
			roofingNailQuantity,
			roofingNailUnit,
			roofingNailCost,
			ridgeCoilNailQuantity,
			ridgeCoilNailUnit,
			ridgeCoilNailCost
		);
	};

	const calculateTearOffCost = () => {
		if (tearOff === 'NO') {
			externalTearOffCost = 0;
			disposalCost = 0;
			totalTearOffLaborHour = 0;
		} else if (tearOff === 'YES') {
			// Disposal Cost = (Total SqFt * weight per SqFt / 2000) = # of tons * cost per ton
			disposalCost =
				((userInput.squareFootage * currentRoof.PoundPerSqFt) / 2000) * 125; //currently $125 per ton
			// TearOff labor cost = (Total SqFt / 100) = # of Square * Labor Hour Per Square * Hour Rate * Complexity
			externalTearOffCost =
				currentRoof.RemoveHourPerSquare *
				(userInput.squareFootage / 100) *
				userInput.externalHourlyCost *
				installComplexity;
			totalTearOffLaborHour =
				(userInput.squareFootage / 100) *
				currentRoof.RemoveHourPerSquare *
				installComplexity;
		}

		return { externalTearOffCost, disposalCost, totalTearOffLaborHour };
	};

	const calculateUnderlaymentCost = () => {
		totalUnderlaymentLaborHour =
			userInput.squareFootage *
			desiredUnderlayment.LaborHourPerSqFt *
			installComplexity;
		externalUnderlaymentCost =
			desiredUnderlayment.LaborHourPerSqFt *
			userInput.squareFootage *
			userInput.externalHourlyCost *
			installComplexity;
		underlaymentMaterialQuantity =
			Math.ceil(userInput.squareFootage * waste) + ' Sqft';
		underlaymentMaterialUnit =
			Math.ceil(
				(userInput.squareFootage * waste) / desiredUnderlayment.SqFtCoverPerRoll
			) + ' Roll(s)';
		underlaymentMaterialCost = Math.ceil(
			(userInput.squareFootage / desiredUnderlayment.SqFtCoverPerRoll) *
				waste *
				desiredUnderlayment.CostPerRoll
		); //update from $30
		plasticCapQuantity = Math.ceil(userInput.squareFootage * waste);
		plasticCapUnit =
			Math.ceil(plasticCapQuantity / plasticCaps.QtyPerBox) + ' Box(es)';
		plasticCapCost =
			Math.ceil(plasticCapQuantity / plasticCaps.QtyPerBox) *
			plasticCaps.CostPerBox;

		return (
			externalUnderlaymentCost,
			underlaymentMaterialCost,
			underlaymentMaterialQuantity,
			underlaymentMaterialUnit,
			totalUnderlaymentLaborHour,
			plasticCapQuantity,
			plasticCapUnit,
			plasticCapCost
		);
	};

	const calculateStarterShingleAndLumberCost = () => {
		if (userInput.eave === 0 && userInput.rake === 0) {
			externalStarterShingleCost = 0;
			totalStarterShingleLaborHour = 0;
			starterShingleMaterialCost = 0;
			starterShingleMaterialQuantity = '0 LF';
			starterShingleMaterialUnit = '0 Unit';
		}
		if (userInput.ridge === 0 && userInput.rake === 0) {
			externalLumberCost = 0;
			totalLumberLaborHour = 0;
			lumberMaterialCost = 0;
			lumberMaterialQuantity = 0 + ' Piece(s)';
		}
		if (
			desiredRoof.value === 'Landmark' ||
			desiredRoof.value === 'Landmark Solaris' ||
			desiredRoof.value === 'Landmark Pro' ||
			desiredRoof.value === 'Landmark Pro Solaris' ||
			desiredRoof.value === 'Landmark Premium' ||
			desiredRoof.value === 'Landmark TL' ||
			desiredRoof.value === 'Landmark TL Solaris' ||
			desiredRoof.value === 'Presidential' ||
			desiredRoof.value === 'Presidential Solaris' ||
			desiredRoof.value === 'Presidential TL' ||
			desiredRoof.value === 'Presidential TL Solaris'
		) {
			externalLumberCost = 0;
			totalLumberLaborHour = 0;
			lumberMaterialCost = 0;
			lumberMaterialQuantity = 0 + ' Piece(s)';
			if (
				desiredRoof.value === 'Landmark' ||
				desiredRoof.value === 'Landmark Solaris' ||
				desiredRoof.value === 'Landmark Pro' ||
				desiredRoof.value === 'Landmark Pro Solaris' ||
				desiredRoof.value === 'Landmark Premium' ||
				desiredRoof.value === 'Landmark TL' ||
				desiredRoof.value === 'Landmark TL Solaris'
			) {
				externalStarterShingleCost =
					(userInput.eave + userInput.rake) *
					starterMaterial.LaborHourPerLinearFeet *
					userInput.externalHourlyCost *
					installComplexity;
				totalStarterShingleLaborHour =
					(userInput.eave + userInput.rake) *
					starterMaterial.LaborHourPerLinearFeet *
					installComplexity;
				starterShingleMaterialCost =
					(Math.ceil((userInput.eave + userInput.rake) * waste) /
						starterMaterial.LFCoveragePerUnit) *
					starterMaterial.CostPerUnit; //update from $0.333
				starterShingleMaterialQuantity =
					Math.ceil((userInput.eave + userInput.rake) * waste) + ' LF';
				starterShingleMaterialUnit =
					Math.ceil(
						((userInput.eave + userInput.rake) * waste) /
							starterMaterial.LFCoveragePerUnit
					) + ' Unit(s)';
			} else if (
				desiredRoof.value === 'Presidential' ||
				desiredRoof.value === 'Presidential Solaris' ||
				desiredRoof.value === 'Presidential TL' ||
				desiredRoof.value === 'Presidential TL Solaris'
			) {
				externalStarterShingleCost =
					userInput.eave *
					waste *
					starterMaterial.LaborHourPerLinearFeet *
					userInput.externalHourlyCost *
					installComplexity;
				totalStarterShingleLaborHour =
					userInput.eave *
					waste *
					starterMaterial.LaborHourPerLinearFeet *
					installComplexity;
				starterShingleMaterialCost =
					(Math.ceil(userInput.eave * waste) /
						starterMaterial.LFCoveragePerUnit) *
					starterMaterial.CostPerUnit; //update from $0.333
				starterShingleMaterialQuantity =
					Math.ceil(userInput.eave * waste) + ' LF';
				starterShingleMaterialUnit =
					Math.ceil(
						(userInput.eave * waste) / starterMaterial.LFCoveragePerUnit
					) + ' Unit(s)';
			}
		} else if (
			//concrete tile (all straight & curve)
			desiredRoof.value === 'Barcelona 900' ||
			desiredRoof.value === 'Saxony 900 Slate' ||
			desiredRoof.value === 'Saxony 900 Shake' ||
			desiredRoof.value === 'Saxony 900 Split' ||
			desiredRoof.value === 'Saxony 900 Hartford' ||
			desiredRoof.value === 'Villa 900' ||
			desiredRoof.value === 'Madera 900' ||
			desiredRoof.value === 'Duralite Espana' ||
			desiredRoof.value === 'Duralite Villa' ||
			desiredRoof.value === 'Duralite Saxony 600' ||
			desiredRoof.value === 'Duralite Saxony 700' ||
			desiredRoof.value === 'Cedarlite 600' ||
			desiredRoof.value === 'Madera 700' ||
			//clay tile
			desiredRoof.value === "1 Pc 'S' Tile Flat Color" ||
			desiredRoof.value === "1 Pc 'S' Tile Standard Blends" ||
			desiredRoof.value === '2 Pc Mission Flat Color' ||
			desiredRoof.value === '2 Pc Mission Standard Blends' ||
			desiredRoof.value === '2 Pc Monarch All Blends' ||
			desiredRoof.value === 'Romano Pans Any' ||
			desiredRoof.value === 'Claylite Any' ||
			desiredRoof.value === 'Claymax Any'
		) {
			externalStarterShingleCost = 0;
			totalStarterShingleLaborHour = 0;
			starterShingleMaterialCost = 0;
			starterShingleMaterialQuantity = 0 + ' LF';
			starterShingleMaterialUnit = 0 + ' Roll';
			// for all 2x4 2x6 lumbers add 10% to total ridge/rake
			externalLumberCost =
				Math.ceil((userInput.ridge / 12 + userInput.rake / 12) * 1.1) *
				lumberMaterial.LaborHourPerUnit *
				userInput.externalHourlyCost *
				installComplexity;
			totalLumberLaborHour =
				Math.ceil((userInput.ridge / 12 + userInput.rake / 12) * 1.1) *
				lumberMaterial.LaborHourPerUnit *
				installComplexity;

			lumberMaterialQuantity =
				Math.ceil((userInput.ridge / 12 + userInput.rake / 12) * 1.1 * waste) +
				' Piece(s)';
			lumberMaterialCost =
				Math.ceil((userInput.ridge / 12 + userInput.rake / 12) * 1.1 * waste) *
				lumberMaterial.CostPerUnit;
		}

		return (
			externalStarterShingleCost,
			totalStarterShingleLaborHour,
			starterShingleMaterialCost,
			starterShingleMaterialQuantity,
			starterShingleMaterialUnit,
			externalLumberCost,
			totalLumberLaborHour,
			lumberMaterialCost,
			lumberMaterialQuantity,
			lumberMaterial.value
		);
	};

	const calculateRidgeHipRakeEaveCost = () => {
		if (userInput.ridge === 0) {
			externalRidgeCost = 0;
			ridgeMaterialCost = 0;
			totalRidgeLaborHour = 0;
			ridgeMaterialQuantity = '0 LF';
			ridgeMaterialUnit = '0 Box/Piece';
		}
		if (userInput.hip === 0) {
			externalHipCost = 0;
			hipMaterialCost = 0;
			totalHipLaborHour = 0;
			hipMaterialQuantity = '0 LF';
			hipMaterialUnit = '0 Box/Piece';
		}
		if (userInput.eave === 0) {
			externalEaveCost = 0;
			eaveMaterialCost = 0;
			totalEaveLaborHour = 0;
			eaveMaterialQuantity = '0 LF';
			eaveMaterialUnit = '0 Piece';
		}
		if (userInput.rake === 0) {
			externalRakeCost = 0;
			rakeMaterialCost = 0;
			totalRakeLaborHour = 0;
			rakeMaterialQuantity = '0 LF';
			rakeMaterialUnit = '0 Piece';
		}
		if (userInput.ridge === 0 && userInput.hip === 0) {
			ridgeNailCost = 0;
			ridgeNailQuantity = 0;
			ridgeNailUnit = '0 Box';
		}
		ridgeMaterialQuantity = Math.ceil(userInput.ridge * waste) + ' LF';
		hipMaterialQuantity = Math.ceil(userInput.hip * waste) + ' LF';
		hipAndRidgeMaterialQuantity =
			Math.ceil(userInput.ridge * waste) +
			Math.ceil(userInput.hip * waste) +
			' LF';
		eaveMaterialQuantity = Math.ceil(userInput.eave * waste) + ' LF';
		rakeMaterialQuantity = Math.ceil(userInput.rake * waste) + ' LF';

		//if desired roof is shingles then
		if (
			desiredRoof.value === 'Landmark' ||
			desiredRoof.value === 'Landmark Solaris' ||
			desiredRoof.value === 'Landmark Pro' ||
			desiredRoof.value === 'Landmark Pro Solaris' ||
			desiredRoof.value === 'Landmark Premium' ||
			desiredRoof.value === 'Landmark TL' ||
			desiredRoof.value === 'Landmark TL Solaris' ||
			desiredRoof.value === 'Presidential' ||
			desiredRoof.value === 'Presidential Solaris' ||
			desiredRoof.value === 'Presidential TL' ||
			desiredRoof.value === 'Presidential TL Solaris'
		) {
			//Ridge
			//ridge is 20 feet per box, about 0.25 hour per box to install
			externalRidgeCost =
				Math.ceil(userInput.ridge / hipAndRidgeMaterial.LFCoveragePerUnit) *
				(userInput.externalHourlyCost * hipAndRidgeMaterial.LaborHourPerUnit) *
				installComplexity;
			ridgeMaterialCost =
				Math.ceil(
					(userInput.ridge * waste) / hipAndRidgeMaterial.LFCoveragePerUnit
				) * hipAndRidgeMaterial.CostPerUnit; //update from $60
			totalRidgeLaborHour =
				(userInput.ridge / hipAndRidgeMaterial.LFCoveragePerUnit) *
				hipAndRidgeMaterial.LaborHourPerUnit *
				installComplexity;
			// ridge nail need 2 nails per 0.66 feet
			ridgeNailQuantity = Math.ceil(
				(((userInput.ridge + userInput.hip) * waste) / 0.66) * 2
			);
			ridgeNailUnit =
				Math.ceil(ridgeNailQuantity / ridgeNails.QtyPerBox) + ' Box(es)';
			ridgeNailCost =
				Math.ceil(ridgeNailQuantity / ridgeNails.QtyPerBox) *
				ridgeNails.CostPerBox;

			//Hip
			//hip is 24 feet per box, 0.25 hour per box to install
			externalHipCost =
				Math.ceil(userInput.hip / hipAndRidgeMaterial.LFCoveragePerUnit) *
				(userInput.externalHourlyCost * hipAndRidgeMaterial.LaborHourPerUnit) *
				installComplexity;
			//hip material cost equal to cost per box time total of box in LF
			hipMaterialCost =
				Math.ceil(
					(userInput.hip * waste) / hipAndRidgeMaterial.LFCoveragePerUnit
				) * hipAndRidgeMaterial.CostPerUnit; //update from $60
			//hip labor in hour = hour per feet times total hip LF
			totalHipLaborHour =
				(userInput.hip / hipAndRidgeMaterial.LFCoveragePerUnit) *
				hipAndRidgeMaterial.LaborHourPerUnit *
				installComplexity;
			ridgeMaterialUnit =
				Math.ceil(
					(userInput.ridge * waste) / hipAndRidgeMaterial.LFCoveragePerUnit
				) + ' Box(es)';

			hipMaterialUnit =
				Math.ceil(
					(userInput.hip * waste) / hipAndRidgeMaterial.LFCoveragePerUnit
				) + ' Box(es)';
			hipAndRidgeMaterialUnit =
				Math.ceil(
					((userInput.hip + userInput.ridge) * waste) /
						hipAndRidgeMaterial.LFCoveragePerUnit
				) + ' Box(es)';
			//Eave
			//eave is per feet times labor cost
			externalEaveCost =
				userInput.eave *
				nosingMaterial.LaborHourPerLinearFeet *
				userInput.externalHourlyCost *
				installComplexity;
			totalEaveLaborHour =
				userInput.eave *
				nosingMaterial.LaborHourPerLinearFeet *
				installComplexity;
			eaveMaterialCost =
				Math.ceil((userInput.eave * waste) / nosingMaterial.LFCoveragePerUnit) *
				nosingMaterial.CostPerUnit; //update from $1.1
			eaveMaterialUnit =
				Math.ceil((userInput.eave * waste) / nosingMaterial.LFCoveragePerUnit) +
				' Piece(s)';

			//Rake
			//rake is per feet times labor cost
			externalRakeCost =
				userInput.rake *
				nosingMaterial.LaborHourPerLinearFeet *
				userInput.externalHourlyCost *
				installComplexity;
			totalRakeLaborHour =
				userInput.rake *
				nosingMaterial.LaborHourPerLinearFeet *
				installComplexity;
			rakeMaterialCost =
				Math.ceil((userInput.rake * waste) / nosingMaterial.LFCoveragePerUnit) *
				nosingMaterial.CostPerUnit; //update from $1.1
			rakeMaterialUnit =
				Math.ceil((userInput.rake * waste) / nosingMaterial.LFCoveragePerUnit) +
				' Piece(s)';
		}
		//if desired roof is concrete tile then
		else if (
			desiredRoof.value === 'Barcelona 900' ||
			desiredRoof.value === 'Saxony 900 Slate' ||
			desiredRoof.value === 'Saxony 900 Shake' ||
			desiredRoof.value === 'Saxony 900 Split O.E Thatch' ||
			desiredRoof.value === 'Saxony 900 Hartford Slate' ||
			desiredRoof.value === 'Villa 900' ||
			desiredRoof.value === 'Madera 900' ||
			desiredRoof.value === 'Duralite Espana' ||
			desiredRoof.value === 'Duralite Villa' ||
			desiredRoof.value === 'Duralite Saxony 600' ||
			desiredRoof.value === 'Duralite Saxony 700' ||
			desiredRoof.value === 'Cedarlite 600' ||
			desiredRoof.value === 'Madera 700' ||
			// clay tile
			desiredRoof.value === "1 Pc 'S' Tile Flat Color" ||
			desiredRoof.value === "1 Pc 'S' Tile Standard Blends" ||
			desiredRoof.value === '2 Pc Mission Flat Color' ||
			desiredRoof.value === '2 Pc Mission Standard Blends' ||
			desiredRoof.value === '2 Pc Monarch All Blends' ||
			desiredRoof.value === 'Romano Pans Any' ||
			desiredRoof.value === 'Claylite Any Any' ||
			desiredRoof.value === 'Claymax Any'
		) {
			//ridge nail not apply for tiles
			ridgeNailCost = 0;
			ridgeNailQuantity = 0;
			ridgeNailUnit = '0 Box';
			//ridge cost equal to LF times hour to install times labor rate
			externalRidgeCost =
				Math.ceil(userInput.ridge) *
				(userInput.externalHourlyCost * 0.05) *
				installComplexity;
			//ridge labor in hour per piece	, 1 piece = 1 LF
			totalRidgeLaborHour = 0.05 * userInput.ridge * installComplexity;
			ridgeMaterialUnit = Math.ceil(userInput.ridge * waste) + ' Piece(s)';
			//hip cost equal to LF times hour to install time labor rate
			externalHipCost =
				Math.ceil(userInput.hip) *
				(userInput.externalHourlyCost * 0.05) *
				installComplexity;
			//hip labor in hour = hour per feet times total hip LF
			totalHipLaborHour = 0.05 * userInput.hip * installComplexity;
			hipAndRidgeMaterialUnit =
				Math.ceil(userInput.hip * waste) +
				Math.ceil(userInput.ridge * waste) +
				' Piece(s)';
			hipMaterialUnit = Math.ceil(userInput.hip * waste) + ' Piece(s)';
			//Rake
			rakeMaterialUnit = Math.ceil(userInput.rake * waste) + ' Piece(s)';
			if (
				desiredRoof.value === "1 Pc 'S' Tile Flat Color" ||
				desiredRoof.value === "1 Pc 'S' Tile Standard Blends" ||
				desiredRoof.value === '2 Pc Mission Flat Color' ||
				desiredRoof.value === '2 Pc Mission Standard Blends' ||
				desiredRoof.value === '2 Pc Monarch All Blends' ||
				desiredRoof.value === 'Romano Pans Any' ||
				desiredRoof.value === 'Claylite Any' ||
				desiredRoof.value === 'Claymax Any'
			) {
				//hip material cost equal to LF/piece times cost per piece
				hipMaterialCost = Math.ceil(userInput.hip * waste) * 3.93; //update from $3.5
				//ridge material cost equal to LF/piece times cost per LF/piece
				ridgeMaterialCost = Math.ceil(userInput.ridge * waste) * 3.93; //update from $3.5
				//rake is per feet times labor cost
				externalRakeCost =
					userInput.rake *
					0.06 *
					userInput.externalHourlyCost *
					installComplexity;
				totalRakeLaborHour = userInput.rake * 0.06 * installComplexity;
				rakeMaterialCost = Math.ceil((userInput.rake * waste) / 10) * 4.82; //update from $4.0
				//eave is per feet times labor cost
				externalEaveCost =
					userInput.eave *
					0.01 *
					userInput.externalHourlyCost *
					installComplexity;
				totalEaveLaborHour = userInput.eave * 0.01 * installComplexity;
				eaveMaterialCost = Math.ceil((userInput.eave * waste) / 10) * 4.39; //update from $4
				eaveMaterialUnit = Math.ceil(userInput.eave * waste) + ' Feet';
			} else {
				//hip material cost equal to LF/piece times cost per piece
				hipMaterialCost = Math.ceil(userInput.hip * waste) * 4.51; //update from $3.5
				//ridge material cost equal to LF/piece times cost per LF/piece
				ridgeMaterialCost = Math.ceil(userInput.ridge * waste) * 4.51; //update from $3.5
				//Rake
				//rake is per feet times labor cost
				externalRakeCost =
					userInput.rake *
					0.06 *
					userInput.externalHourlyCost *
					installComplexity;
				totalRakeLaborHour = userInput.rake * 0.06 * installComplexity;
				rakeMaterialCost = Math.ceil((userInput.rake * waste) / 10) * 4.51; //update from $3.5
				//Eave
				//eave is per feet times labor cost
				externalEaveCost =
					(userInput.eave / 10) *
					0.1 *
					userInput.externalHourlyCost *
					installComplexity;
				totalEaveLaborHour = (userInput.eave / 10) * 0.1 * installComplexity;
				eaveMaterialCost = Math.ceil((userInput.eave * waste) / 10) * 32.28; //update from $8
				eaveMaterialUnit =
					Math.ceil((userInput.eave * waste) / 10) + ' Section(s)';
			}
		}

		return (
			externalRidgeCost,
			ridgeMaterialCost,
			totalRidgeLaborHour,
			ridgeMaterialQuantity,
			ridgeMaterialUnit,
			externalHipCost,
			totalHipLaborHour,
			hipMaterialCost,
			hipMaterialQuantity,
			hipMaterialUnit,
			hipAndRidgeMaterialQuantity,
			hipAndRidgeMaterialUnit,
			valleyMaterialQuantity,
			valleyMaterialUnit,
			externalRakeCost,
			totalRakeLaborHour,
			rakeMaterialCost,
			rakeMaterialQuantity,
			rakeMaterialUnit,
			externalEaveCost,
			totalEaveLaborHour,
			eaveMaterialCost,
			eaveMaterialQuantity,
			eaveMaterialUnit,
			ridgeNailQuantity,
			ridgeNailUnit,
			ridgeNailCost
		);
	};

	const calculateValleyCost = () => {
		let totalValleyMeasurement = 0;
		let totalValleyLF = 0;
		if (!valleyMeasurementList || valleyMeasurementList.length <= 0) {
			externalValleyCost = 0;
			totalValleyLaborHour = 0;
			valleyMaterialCost = 0;
			valleyMaterialQuantity = '0 LF';
			valleyMaterialUnit = '0 Section';
		} else if (valleyMeasurementList.length > 0) {
			valleyMeasurementList.map((valley) => {
				return (totalValleyMeasurement += Math.ceil(
					(valley.measurement / 10) * waste
				));
			});
			//Valley
			//valley is labor times per section (10'/section)
			externalValleyCost =
				totalValleyMeasurement *
				(userInput.externalHourlyCost * valleysMaterial.LaborHourPerUnit) *
				installComplexity;
			totalValleyLaborHour =
				totalValleyMeasurement *
				valleysMaterial.LaborHourPerUnit *
				installComplexity;
			//valley material cost = price times number of piece(section)
			valleyMaterialCost = totalValleyMeasurement * valleysMaterial.CostPerUnit; //update from $30
			valleyMeasurementList.map((valley) => {
				return (totalValleyLF += Math.ceil(valley.measurement * waste));
			});
			valleyMaterialQuantity = totalValleyLF + ' LF';
			valleyMaterialUnit = totalValleyMeasurement + ' Section(s)';
		}

		return {
			externalValleyCost,
			totalValleyLaborHour,
			valleyMaterialCost,
			valleyMaterialQuantity,
			valleyMaterialUnit,
		};
	};

	const calculateCustomToolsCost = () => {
		let totalCustomToolCost = 0;
		if (customToolsList.length > 0) {
			customToolsList.map((item) => {
				return (totalCustomToolCost += item.cost * item.quantity);
			});
			customToolsCost = totalCustomToolCost;
			customToolsQuantity = customToolsList.length + 'Item(s)';
		} else {
			customToolsCost = 0;
			customToolsQuantity = '0 Item';
		}
		return { customToolsCost, customToolsQuantity };
	};

	const calculateGuttersAndDownspoutCost = () => {
		if (gutters === 'NO') {
			externalGuttersCost = 0;
			guttersMaterialCost = 0;
			totalGuttersLaborHour = 0;
			externalDownspoutCost = 0;
			downSpoutMaterialCost = 0;
			totalDownSpoutLaborHour = 0;
			guttersMaterialQuantity = 0 + ' LF';
			downSpoutMaterialQuantity = 0 + ' Unit';
		} else if (gutters === 'YES') {
			externalGuttersCost =
				userInput.eave * 0.01 * userInput.externalHourlyCost;
			totalGuttersLaborHour = userInput.eave * 0.01;
			guttersMaterialCost = Math.ceil(userInput.eave * waste) * 10;
			guttersMaterialQuantity = Math.ceil(userInput.eave) + ' LF';
			externalDownspoutCost =
				Math.ceil(userInput.eave / 20) * 0.25 * userInput.externalHourlyCost;
			totalDownSpoutLaborHour = Math.ceil(userInput.eave / 20) * 0.25;
			downSpoutMaterialCost = Math.ceil((userInput.eave / 20) * waste) * 120;
			downSpoutMaterialQuantity =
				Math.ceil((userInput.eave / 20) * waste) + ' Unit(s)';
		}

		return (
			externalGuttersCost,
			totalGuttersLaborHour,
			guttersMaterialCost,
			guttersMaterialQuantity,
			externalDownspoutCost,
			totalDownSpoutLaborHour,
			downSpoutMaterialCost,
			downSpoutMaterialQuantity
		);
	};

	const calculateMiscFlashingCost = () => {
		let totalMiscFlashingCost = 0;
		let totalMiscFlashingLaborCost = 0;
		let miscFlashingLaborHour = 0;
		if (miscFlashingList.length > 0) {
			miscFlashingList.map((item) => {
				totalMiscFlashingCost += item.cost * item.quantity;
				totalMiscFlashingLaborCost += item.hour * userInput.externalHourlyCost;
				miscFlashingLaborHour += item.hour;
				return {
					totalMiscFlashingCost,
					totalMiscFlashingLaborCost,
					miscFlashingLaborHour,
				};
			});
			miscFlashingMaterialCost = totalMiscFlashingCost;
			totalMiscFlashingLaborHour = miscFlashingLaborHour * installComplexity;
			externalMiscFlashingCost = totalMiscFlashingLaborCost * installComplexity;
			miscFlashingMaterialQuantity = miscFlashingList.length + 'Unit(s)';
		} else {
			miscFlashingMaterialCost = 0;
			totalMiscFlashingLaborHour = 0;
			externalMiscFlashingCost = 0;
			miscFlashingMaterialQuantity = '0 Unit';
		}
		return {
			miscFlashingMaterialCost,
			totalMiscFlashingLaborHour,
			externalMiscFlashingCost,
			miscFlashingMaterialQuantity,
		};
	};

	const calculateSkylightAndChimneyFlashingAllowance = () => {
		let totalChimneyFlashingCost = 0;
		let totalChimneyFlashingLaborHour = 0;
		let totalChimneyFlashingLaborCost = 0;
		let totalSkylightFlashingCost = 0;
		let totalSkylightFlashingLaborHour = 0;
		let totalSkylightFlashingLaborCost = 0;
		if (chimneyFlashingList.length > 0) {
			chimneyFlashingList.map((item) => {
				totalChimneyFlashingCost += item.cost;
				totalChimneyFlashingLaborHour += item.hour;
				totalChimneyFlashingLaborCost +=
					item.hour * userInput.externalHourlyCost;
				return {
					totalChimneyFlashingCost,
					totalChimneyFlashingLaborHour,
					totalChimneyFlashingLaborCost,
				};
			});
			chimneyMaterialCost = totalChimneyFlashingCost;
			totalChimneyLaborHour = totalChimneyFlashingLaborHour * installComplexity;
			externalChimneyFlashingCost =
				totalChimneyFlashingLaborCost * installComplexity;
			chimneyMaterialQuantity = chimneyFlashingList.length + ' Unit(s)';
		} else {
			chimneyMaterialCost = 0;
			totalChimneyLaborHour = 0;
			externalChimneyFlashingCost = 0;
			chimneyMaterialQuantity = '0 Unit';
		}
		if (skylightFlashingList.length > 0) {
			skylightFlashingList.map((item) => {
				totalSkylightFlashingCost += item.cost;
				totalSkylightFlashingLaborHour += item.hour;
				totalSkylightFlashingLaborCost +=
					item.hour * userInput.externalHourlyCost;
				return {
					totalSkylightFlashingCost,
					totalSkylightFlashingLaborHour,
					totalSkylightFlashingLaborCost,
				};
			});
			skylightMaterialCost = totalSkylightFlashingCost;
			totalSkylightLaborHour =
				totalSkylightFlashingLaborHour * installComplexity;
			externalSkylightFlashingCost =
				totalSkylightFlashingLaborCost * installComplexity;
			skylightMaterialQuantity = skylightFlashingList.length + ' Unit(s)';
		} else {
			skylightMaterialCost = 0;
			totalSkylightLaborHour = 0;
			externalSkylightFlashingCost = 0;
			skylightMaterialQuantity = '0 Unit';
		}

		return (
			externalSkylightFlashingCost,
			totalSkylightLaborHour,
			skylightMaterialCost,
			skylightMaterialQuantity,
			externalChimneyFlashingCost,
			totalChimneyLaborHour,
			chimneyMaterialCost,
			chimneyMaterialQuantity
		);
	};

	const calculateRoofingVent = () => {
		roofingVentsMaterialQuantity = Math.ceil(userInput.squareFootage) + ' Sqft';
		roofingVentsMaterialUnit =
			Math.ceil(
				(userInput.squareFootage * waste) /
					roofingVentsMaterial.LFCoveragePerUnit
			) + ' Vent(s)';
		externalRoofingVentCost =
			Math.ceil(
				userInput.squareFootage / roofingVentsMaterial.LFCoveragePerUnit
			) *
			roofingVentsMaterial.LaborHourPerUnit *
			userInput.externalHourlyCost *
			installComplexity;
		totalRoofingVentLaborHour =
			Math.ceil(
				userInput.squareFootage / roofingVentsMaterial.LFCoveragePerUnit
			) *
			roofingVentsMaterial.LaborHourPerUnit *
			installComplexity;
		roofingVentsMaterialCost =
			Math.ceil(
				(userInput.squareFootage / roofingVentsMaterial.LFCoveragePerUnit) *
					waste
			) * roofingVentsMaterial.CostPerUnit;
		if (intakeVentsMaterial.value === 'None') {
			externalIntakeVentsCost = 0;
			intakeVentsMaterialCost = 0;
			totalIntakeVentsLaborHour = 0;
			intakeVentsQuantity = '0 Vent';
		} else {
			intakeVentsMaterialCost =
				Math.ceil(
					(userInput.squareFootage / intakeVentsMaterial.LFCoveragePerUnit) *
						waste
				) * intakeVentsMaterial.CostPerUnit;
			intakeVentsQuantity =
				Math.ceil(
					(userInput.squareFootage / intakeVentsMaterial.LFCoveragePerUnit) *
						waste
				) + ' Vent(s)';
			externalIntakeVentsCost =
				Math.ceil(
					userInput.squareFootage / intakeVentsMaterial.LFCoveragePerUnit
				) *
				intakeVentsMaterial.LaborHourPerUnit *
				userInput.externalHourlyCost *
				installComplexity;
			totalIntakeVentsLaborHour =
				Math.ceil(
					userInput.squareFootage / intakeVentsMaterial.LFCoveragePerUnit
				) *
				intakeVentsMaterial.LaborHourPerUnit *
				installComplexity;
		}

		return (
			externalRoofingVentCost,
			totalRoofingVentLaborHour,
			roofingVentsMaterialCost,
			roofingVentsMaterialQuantity,
			roofingVentsMaterialUnit,
			externalIntakeVentsCost,
			totalIntakeVentsLaborHour,
			intakeVentsMaterialCost,
			intakeVentsQuantity
		);
	};

	const calculateNewDeckMaterial = () => {
		if (newDeck === 'NO') {
			externalDeckCost = 0;
			deckMaterialCost = 0;
			totalDeckLaborHour = 0;
			deckMaterialQuantity = 0 + ' Sqft';
			deckMaterialUnit = 0 + ' Sheet';
		} else if (newDeck === 'YES') {
			externalDeckCost =
				Math.ceil(userInput.squareFootage / newDeckMaterial.LFCoveragePerUnit) *
				newDeckMaterial.LaborHourPerUnit *
				userInput.externalHourlyCost *
				installComplexity;
			totalDeckLaborHour =
				Math.ceil(userInput.squareFootage / newDeckMaterial.LFCoveragePerUnit) *
				newDeckMaterial.LaborHourPerUnit *
				installComplexity;
			deckMaterialQuantity =
				Math.ceil(userInput.squareFootage * waste) + ' Sqft';
			deckMaterialUnit =
				Math.ceil(
					(userInput.squareFootage * waste) / newDeckMaterial.LFCoveragePerUnit
				) + ' Sheet(s)';
			deckMaterialCost =
				Math.ceil(
					(userInput.squareFootage / newDeckMaterial.LFCoveragePerUnit) * waste
				) * newDeckMaterial.CostPerUnit;
		}

		return (
			externalDeckCost,
			totalDeckLaborHour,
			deckMaterialCost,
			deckMaterialQuantity,
			deckMaterialUnit
		);
	};

	const calculateSaddleCost = () => {
		let totalSaddleCost = 0;
		let saddleLaborHour = 0;
		let totalSaddleLaborCost = 0;
		if (saddleMeasurementList.length > 0) {
			saddleMeasurementList.map((item) => {
				totalSaddleCost += item.cost * item.quantity;
				saddleLaborHour += item.hour;
				totalSaddleLaborCost += item.hour * userInput.externalHourlyCost;
				return { totalSaddleCost, saddleLaborHour, totalSaddleLaborCost };
			});
			saddleMaterialCost = totalSaddleCost;
			totalSaddleLaborHour = saddleLaborHour * installComplexity;
			externalSaddleCost = totalSaddleLaborCost * installComplexity;
		} else {
			saddleMaterialCost = 0;
			totalSaddleLaborHour = 0;
			externalSaddleCost = 0;
		}
		return { saddleMaterialCost, totalSaddleLaborHour, externalSaddleCost };
	};

	const calculateCaulkAndPaintMaterialCost = () => {
		caulkMaterialCost =
			Math.ceil(
				(userInput.squareFootage * waste) / caulkMaterial.LFCoveragePerUnit
			) * caulkMaterial.CostPerUnit;
		caulkQuantity = Math.ceil(
			(userInput.squareFootage * waste) / caulkMaterial.LFCoveragePerUnit
		);
		paintMaterialCost =
			Math.ceil(
				(userInput.squareFootage * waste) / paintMaterial.LFCoveragePerUnit
			) * paintMaterial.CostPerUnit;
		paintQuantity = Math.ceil(
			(userInput.squareFootage * waste) / paintMaterial.LFCoveragePerUnit
		);

		return {
			caulkMaterialCost,
			caulkQuantity,
			paintMaterialCost,
			paintQuantity,
		};
	};

	const calculatePipeJacksCost = () => {
		let totalPipeJacksCost = 0;
		let pipeJacksLaborHour = 0;
		let totalPipeJacksLaborCost = 0;
		if (pipeJacksList.length > 0) {
			pipeJacksList.map((item) => {
				totalPipeJacksCost += item.cost * item.quantity;
				pipeJacksLaborHour += item.hour;
				totalPipeJacksLaborCost += item.hour * userInput.externalHourlyCost;
				return {
					totalPipeJacksCost,
					pipeJacksLaborHour,
					totalPipeJacksLaborCost,
				};
			});
			externalPipeJacksCost = totalPipeJacksLaborCost * installComplexity;
			pipeJacksMaterialCost = totalPipeJacksCost;
			totalPipeJacksLaborHour = pipeJacksLaborHour * installComplexity;
		} else {
			externalPipeJacksCost = 0;
			pipeJacksMaterialCost = 0;
			totalPipeJacksLaborHour = 0;
		}
		return {
			externalPipeJacksCost,
			pipeJacksMaterialCost,
			totalPipeJacksLaborHour,
		};
	};

	const calculateGrandTotal = () => {
		externalTotalLaborCost =
			externalTearOffCost +
			externalInstallRoofCost +
			externalUnderlaymentCost +
			externalDeckCost +
			externalGuttersCost +
			externalRidgeCost +
			externalHipCost +
			externalEaveCost +
			externalRakeCost +
			externalValleyCost +
			externalSkylightFlashingCost +
			externalChimneyFlashingCost +
			externalMiscFlashingCost +
			externalDownspoutCost +
			externalRoofingVentCost +
			externalIntakeVentsCost +
			externalStarterShingleCost +
			externalLumberCost +
			externalSaddleCost +
			externalPipeJacksCost;

		totalLaborHour =
			totalTearOffLaborHour +
			totalRoofInstallLaborHour +
			totalUnderlaymentLaborHour +
			totalDeckLaborHour +
			totalGuttersLaborHour +
			totalRidgeLaborHour +
			totalHipLaborHour +
			totalEaveLaborHour +
			totalRakeLaborHour +
			totalValleyLaborHour +
			totalSkylightLaborHour +
			totalChimneyLaborHour +
			totalMiscFlashingLaborHour +
			totalDownSpoutLaborHour +
			totalRoofingVentLaborHour +
			totalIntakeVentsLaborHour +
			totalStarterShingleLaborHour +
			totalLumberLaborHour +
			totalSaddleLaborHour +
			totalPipeJacksLaborHour;

		totalMaterialCost =
			roofMaterialCost +
			underlaymentMaterialCost +
			deckMaterialCost +
			guttersMaterialCost +
			ridgeMaterialCost +
			hipMaterialCost +
			eaveMaterialCost +
			rakeMaterialCost +
			valleyMaterialCost +
			skylightMaterialCost +
			chimneyMaterialCost +
			miscFlashingMaterialCost +
			downSpoutMaterialCost +
			roofingVentsMaterialCost +
			intakeVentsMaterialCost +
			starterShingleMaterialCost +
			lumberMaterialCost +
			saddleMaterialCost +
			pipeJacksMaterialCost +
			caulkMaterialCost +
			paintMaterialCost +
			ridgeNailCost +
			roofingNailCost +
			ridgeCoilNailCost +
			plasticCapCost +
			customToolsCost;

		externalGrandTotal =
			externalTotalLaborCost + disposalCost + totalMaterialCost * markup * tax;
		externalGrandTotalWithOverheadAndProfit =
			(externalTotalLaborCost +
				disposalCost +
				totalMaterialCost * markup * tax) *
			overhead *
			profit;
		totalNailsCost =
			roofingNailCost + ridgeNailCost + plasticCapCost + ridgeCoilNailCost;

		return (
			externalGrandTotal,
			totalLaborHour,
			totalMaterialCost,
			totalNailsCost,
			externalTotalLaborCost,
			externalGrandTotalWithOverheadAndProfit
		);
	};

	const CreatePDFReport = () => {
		let tearOffReport;
		let tearOffPrice;
		let newDeckReport;
		let newDeckPrice;
		let newGutterReport;
		let newGutterPrice;

		const reportHeader = (
			<View style={styles.topDiv}>
				<View style={styles.infoSection}>
					<Text style={styles.info}>
						Customer Name: {projectInfoInput.customerName}
					</Text>
					<Text style={styles.info}>Date: {projectInfoInput.projectDate}</Text>
					{/* <Text style={styles.info}>
						Phone #: {projectInfoInput.projectPhone}
					</Text> */}
					<Text style={styles.info}>
						Project Address: {projectInfoInput.projectAddress}
					</Text>
					<Text style={styles.info}>
						Project Number & Description: {projectInfoInput.jobNumber} -{' '}
						{projectInfoInput.jobDescription}
					</Text>
				</View>
				<View style={styles.infoSection}>
					<Image style={styles.logo} src={logo} alt='opticost-logo' />
					<Text style={styles.smallText}>Powered by Opticost</Text>
				</View>
			</View>
		);
		const contractorInfo = (
			<View style={styles.topDiv}>
				<View style={styles.infoSection}>
					<Text style={styles.info}>Contractor Name: {props.userFullName}</Text>
					<Text style={styles.info}>Date: {projectInfoInput.projectDate}</Text>
					{/* <Text style={styles.info}>
						Phone #: {projectInfoInput.projectPhone}
					</Text> */}
					<Text style={styles.info}>Phone #: {props.userPhone}</Text>
					<Text style={styles.info}>Email: {props.userEmail}</Text>
				</View>
			</View>
		);
		const projectReportInput = (
			<View style={styles.projectReport}>
				<View style={styles.paragraph}>
					<Text> Current Roof Material: {currentRoof.value} </Text>
					<Text> Desired Roof Material: {desiredRoof.value} </Text>
					<Text> Tear-off Required (Y/N): {tearOff} </Text>
					<Text> New Gutter Required (Y/N): {gutters} </Text>
					<Text> New Deck Required (Y/N): {newDeck} </Text>
					<Text>Desired New Deck Material: {newDeckMaterial.value}</Text>
					{/* <Text>
						Install Complexity (Multiplier): x{installComplexity.toFixed(1)}
					</Text> */}
				</View>
				<View style={styles.paragraph}>
					<Text> Ridge (LF): {userInput.ridge} Ft </Text>
					<Text> Rake (LF): {userInput.rake} Ft </Text>
					<Text> Eave (LF): {userInput.eave} Ft </Text>
					<Text> Hip (LF): {userInput.hip} Ft </Text>
					<Text> Valley (LF): {valleyMaterialQuantity}</Text>
					<Text>Total Footage: {userInput.squareFootage} Sq Ft</Text>
					{/* <Text>
						Total Labor Hours:{' '}
						{totalLaborHour.toLocaleString('en', {
							maximumFractionDigits: 2,
						})}{' '}
						Hrs
					</Text> */}
				</View>
			</View>
		);
		if (tearOff === 'YES' && newDeck === 'YES' && gutters === 'YES') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof.value}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof.value}" will be
							removed and disposed at an added rate of $
							{(externalTearOffCost / userInput.squareFootage).toLocaleString(
								'en',
								{ maximumFractionDigits: 2 }
							)}{' '}
							per square foot per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalTearOffCost + disposalCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newDeckReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						{' '}
						New Deck Installation - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text> - New Desired Deck Material: {newDeckMaterial.value} </Text>
					</View>
				</View>
			);
			newDeckPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						{' '}
						$
						{(
							externalDeckCost +
							deckMaterialCost * tax * markup
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof.value}
						</Text>
					</View>
				</View>
			);
			newGutterPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(
							externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost + downSpoutMaterialCost * markup * tax)
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
		} else if (tearOff === 'YES' && newDeck === 'NO' && gutters === 'NO') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof.value}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof.value}" will be
							removed and disposed at an added rate of $
							{(externalTearOffCost / userInput.squareFootage).toLocaleString(
								'en',
								{ maximumFractionDigits: 2 }
							)}{' '}
							per square foot per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalTearOffCost + disposalCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newDeckReport = null;
			newDeckPrice = null;
			newGutterReport = null;
			newGutterPrice = null;
		} else if (tearOff === 'YES' && newDeck === 'YES' && gutters === 'NO') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof.value}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof.value}" will be
							removed and disposed at an added rate of $
							{(externalTearOffCost / userInput.squareFootage).toLocaleString(
								'en',
								{ maximumFractionDigits: 2 }
							)}{' '}
							per square foot per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalTearOffCost + disposalCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newDeckReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Deck Installation - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text> - New Desired Deck Material: {newDeckMaterial.value} </Text>
					</View>
				</View>
			);
			newDeckPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(
							externalDeckCost +
							deckMaterialCost * tax * markup
						).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newGutterReport = null;
			newGutterPrice = null;
		} else if (tearOff === 'YES' && newDeck === 'NO' && gutters === 'YES') {
			tearOffReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						Tear off and Disposal Existing "{currentRoof.value}" Roof
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- Additional discovered layer of "{currentRoof.value}" will be
							removed and disposed at an added rate of $
							{(externalTearOffCost / userInput.squareFootage).toLocaleString(
								'en',
								{ maximumFractionDigits: 2 }
							)}{' '}
							per square foot per layer
						</Text>
						<Text>
							- Include Pre-tearoff site preparation, in-progress cleaning and
							Post-tearoff cleaning
						</Text>
					</View>
				</View>
			);
			tearOffPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(externalTearOffCost + disposalCost).toLocaleString('en', {
							maximumFractionDigits: 2,
						})}
					</Text>
				</View>
			);
			newDeckReport = null;
			newDeckPrice = null;
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof.value}
						</Text>
					</View>
				</View>
			);
			newGutterPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(
							externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost + downSpoutMaterialCost * markup * tax)
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
		} else if (tearOff === 'NO' && newDeck === 'NO' && gutters === 'NO') {
			tearOffReport = null;
			tearOffPrice = null;
			newDeckReport = null;
			newDeckPrice = null;
			newGutterReport = null;
			newGutterPrice = null;
		} else if (tearOff === 'NO' && newDeck === 'YES' && gutters === 'YES') {
			tearOffReport = null;
			tearOffPrice = null;
			newDeckReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						{' '}
						New Deck Installation - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text> - New Desired Deck Material: {newDeckMaterial.value} </Text>
					</View>
				</View>
			);
			newDeckPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						{' '}
						$
						{(
							externalDeckCost +
							deckMaterialCost * tax * markup
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof.value}
						</Text>
					</View>
				</View>
			);
			newGutterPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(
							externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost + downSpoutMaterialCost * markup * tax)
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
		} else if (tearOff === 'NO' && newDeck === 'YES' && gutters === 'NO') {
			tearOffReport = null;
			tearOffPrice = null;
			newDeckReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						{' '}
						New Deck Installation - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text> - New Desired Deck Material: {newDeckMaterial.value} </Text>
					</View>
				</View>
			);
			newDeckPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						{' '}
						$
						{(
							externalDeckCost +
							deckMaterialCost * tax * markup
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
			newGutterReport = null;
			newGutterPrice = null;
		} else if (tearOff === 'NO' && newDeck === 'NO' && gutters === 'YES') {
			tearOffReport = null;
			tearOffPrice = null;
			newDeckReport = null;
			newDeckPrice = null;
			newGutterReport = (
				<View style={styles.middleColumn}>
					<Text style={styles.descriptionColumn}>
						New Gutter and Downspout - Main House
					</Text>
					<View style={styles.tableBody}>
						<Text>
							- New Gutter and Downspout Material: {desiredRoof.value}
						</Text>
					</View>
				</View>
			);
			newGutterPrice = (
				<View style={styles.rightColumn}>
					<Text style={styles.priceColumn}>
						$
						{(
							externalGuttersCost +
							guttersMaterialCost * markup * tax +
							(externalDownspoutCost + downSpoutMaterialCost * markup * tax)
						).toLocaleString('en', { maximumFractionDigits: 2 })}
					</Text>
				</View>
			);
		}
		setPDFReport(
			<div style={{ margin: '15px auto' }}>
				<div className='report-button'>
					<PDFDownloadLink
						fileName={`${projectInfoInput.customerName}-${projectInfoInput.projectDate}-Customer-Quote.pdf`}
						document={
							<Document>
								<Page size='A4' style={styles.page}>
									<View style={styles.pageLayout}>
										{contractorInfo}
										{reportHeader}
										<View style={styles.middleDiv}>
											<View style={styles.mainTitle}>
												<Text style={styles.title}>Proposal</Text>
											</View>
											<View style={styles.tableHeaderContainer}>
												<View style={styles.leftHeader}>
													<Text>Item </Text>
												</View>
												<View style={styles.middleHeader}>
													<Text>Description </Text>
												</View>
												<View style={styles.rightHeader}>
													<Text> Total </Text>
												</View>
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>A</Text>
												</View>
												{tearOffReport}
												{tearOffPrice}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>B</Text>
												</View>
												{newDeckReport}
												{newDeckPrice}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>C</Text>
												</View>
												{newGutterReport}
												{newGutterPrice}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>D</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														New Complete Roof Installation - Main House
													</Text>
													<View style={styles.tableBody}>
														<Text>
															- New Desired Roof Material: {desiredRoof.value}
															{/* - Cost: $
															{(
																externalInstallRoofCost +
																externalRoofingVentCost +
																externalChimneyFlashingCost +
																externalSkylightFlashingCost +
																externalEaveCost +
																externalHipCost +
																externalRakeCost +
																externalRidgeCost +
																externalValleyCost +
																externalStarterShingleCost +
																externalLumberCost +
																(roofMaterialCost +
																	roofingVentsMaterialCost +
																	chimneyMaterialCost +
																	skylightMaterialCost +
																	eaveMaterialCost +
																	hipMaterialCost +
																	rakeMaterialCost +
																	ridgeMaterialCost +
																	valleyMaterialCost +
																	starterShingleMaterialCost +
																	lumberMaterialCost) *
																	markup *
																	tax
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})} */}
														</Text>
														<Text>
															- New Desired Underlayment Material:{' '}
															{desiredUnderlayment.value}
															{/* - Cost: $
															{(
																externalUnderlaymentCost +
																underlaymentMaterialCost * tax * markup
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})} */}
														</Text>
														<Text>
															*Includes the installation of all roof metals,
															including skylight flashings, except solar
															flashings
														</Text>
													</View>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(
															externalGrandTotalWithOverheadAndProfit -
															(externalTearOffCost + disposalCost) -
															(externalDeckCost +
																deckMaterialCost * tax * markup) -
															(externalDownspoutCost +
																downSpoutMaterialCost * tax * markup) -
															(externalGuttersCost +
																guttersMaterialCost * tax * markup)
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View>
											</View>
											<Text
												style={{
													textAlign: 'center',
													fontSize: 13,
													textDecoration: 'underline',
													marginTop: 15,
												}}>
												Proposal Total: $
												{externalGrandTotalWithOverheadAndProfit.toLocaleString(
													'en',
													{
														maximumFractionDigits: 2,
													}
												)}
											</Text>
											{secondPDFPage}
										</View>
										{reportFooter}
									</View>
									<Text
										style={styles.pageNumber}
										render={({ pageNumber, totalPages }) =>
											`${pageNumber} of ${totalPages}`
										}
										fixed></Text>
								</Page>
								{/* <Page size='A4' style={styles.page}>
									<View style={styles.pageLayout}>
										{reportHeader}
										{secondPDFPage}
										{reportFooter}
									</View>
									<Text
										style={styles.pageNumber}
										render={({ pageNumber, totalPages }) =>
											`${pageNumber} of ${totalPages}`
										}
										fixed></Text>
								</Page> */}
							</Document>
						}>
						<button className='button'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-pdf'
							/>
							Customer Quote Report
						</button>
					</PDFDownloadLink>
				</div>
			</div>
		);
		setMaterialPricingPDF(
			<div style={{ margin: '15px auto' }}>
				<div className='report-button'>
					<PDFDownloadLink
						fileName={`${projectInfoInput.customerName}-${projectInfoInput.projectDate}-Material Pricing.pdf`}
						document={
							<Document>
								<Page size='A4' style={styles.page}>
									<View style={styles.pageLayout}>
										{reportHeader}
										<View style={styles.middleDiv}>
											<View style={styles.mainTitle}>
												<Text style={styles.title}>Project Report</Text>
											</View>
											{projectReportInput}
											<View style={styles.mainTitle}>
												<Text style={styles.title}>Materials List</Text>
											</View>
											<View style={styles.tableHeaderContainer}>
												<View style={styles.leftHeader}>
													<Text>Item </Text>
												</View>
												<View style={styles.middleHeader}>
													<Text>Description </Text>
												</View>
												<View style={styles.rightHeader}>
													<Text> Quantity </Text>
												</View>
												{/* <View style={styles.rightHeader}>
													<Text> Total </Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>01</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Roofing Material: {desiredRoof.value} -{' '}
														{roofMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{roofMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(roofMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>02</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Underlayment: {desiredUnderlayment.value} -{' '}
														{underlaymentMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{underlaymentMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(underlaymentMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>03</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Deck Material: {newDeckMaterial.value} -{' '}
														{deckMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{deckMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(deckMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>04</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Starter Rolls (For Shingle Roof):{' '}
														{starterMaterial.value} -{' '}
														{starterShingleMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{starterShingleMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(starterShingleMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>05</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Lumber (For Concrete Roof): {lumberMaterial.value} -
														{lumberMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{lumberMaterialQuantity}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(lumberMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>06</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Roof Ridge Box: {hipAndRidgeMaterial.value} -{' '}
														{hipAndRidgeMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{hipAndRidgeMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(
															(ridgeMaterialCost + hipMaterialCost) *
															tax
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>07</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Valley Pieces: {valleysMaterial.value} -{' '}
														{valleyMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{valleyMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(valleyMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>08</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Rake Nosing: {nosingMaterial.value} -{' '}
														{rakeMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{rakeMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(rakeMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>09</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Eave Nosing: {nosingMaterial.value} -{' '}
														{eaveMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{eaveMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(eaveMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>10</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Gutters - {guttersMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{guttersMaterialQuantity}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(guttersMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>11</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Downspout - {downSpoutMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{downSpoutMaterialQuantity}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(downSpoutMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>12</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Ridge Vent: {roofingVentsMaterial.value} -{' '}
														{roofingVentsMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{roofingVentsMaterialUnit}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(roofingVentsMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>13</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Intake Vent: {intakeVentsMaterial.value} -{' '}
														{roofingVentsMaterialQuantity}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{intakeVentsQuantity}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(intakeVentsMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>14</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Chimney Flashing:{' '}
														{chimneyFlashingList.map(
															(list) => list.description + ', '
														)}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{chimneyMaterialQuantity}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(chimneyMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>15</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Skylight Flashing:{' '}
														{skylightFlashingList.map(
															(list) => list.description + ', '
														)}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{skylightMaterialQuantity}
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(skylightMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>16</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Misc Flashing:{' '}
														{miscFlashingList.map(
															(list) => list.description + ', '
														)}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{miscFlashingList.length} Unit(s)
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(miscFlashingMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>17</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Saddles:{' '}
														{saddleMeasurementList.map(
															(list) => list.description + ', '
														)}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{saddleMeasurementList.length} Unit(s)
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(saddleMaterialCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>18</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Pipe Jacks & Assemblies:{' '}
														{pipeJacksList.map(
															(list) => list.description + ', '
														)}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{pipeJacksList.length} Unit(s)
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(pipeJacksMaterialCost * tax).toLocaleString(
															'en',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>19</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Custom Tool(s):{' '}
														{customToolsList.map(
															(list) => list.description + ', '
														)}
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														{customToolsList.length} Item(s)
													</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(customToolsCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>20</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>
														Caulk: {caulkMaterial.value} {caulkQuantity} -
														Tube(s) & Paint: {paintMaterial.value} -{' '}
														{paintQuantity} Can(s)
													</Text>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>-</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(
															caulkMaterialCost * tax +
															paintMaterialCost * tax
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											<View style={styles.tableBodyContainer}>
												<View style={styles.leftColumn}>
													<Text style={styles.itemColumn}>21</Text>
												</View>
												<View style={styles.middleColumn}>
													<Text style={styles.descriptionColumn}>Nails</Text>
													<View style={styles.tableBody}>
														<Text>
															-Coil Nails (Roof): {roofingNails.value} -
															Quantity: {roofingNailUnit}
														</Text>
														<Text>
															-Coil Nails (Ridge): {ridgeCoilNails.value} -
															Quantity: {ridgeCoilNailUnit}
														</Text>
														<Text>
															-Strip Nails (Deck): {ridgeNails.value} -
															Quantity: {ridgeNailUnit}
														</Text>
														<Text>
															-Plastic Cap (Underlayment): {plasticCaps.value} -
															Quantity: {plasticCapUnit}
														</Text>
													</View>
												</View>
												<View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>-</Text>
												</View>
												{/* <View style={styles.rightColumn}>
													<Text style={styles.priceColumn}>
														$
														{(totalNailsCost * tax).toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
												</View> */}
											</View>
											{/* <Text
												style={{
													textAlign: 'center',
													fontSize: 13,
													textDecoration: 'underline',
													marginTop: 8,
												}}>
												Total Material Cost: $
												{(totalMaterialCost * tax).toLocaleString('en', {
													maximumFractionDigits: 2,
												})}
											</Text> */}
										</View>
										{reportFooter}
									</View>
									<Text
										style={styles.pageNumber}
										render={({ pageNumber, totalPages }) =>
											`${pageNumber} of ${totalPages}`
										}
										fixed></Text>
								</Page>
							</Document>
						}>
						<button className='button'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-pdf'
							/>
							Material Pricing Report
						</button>
					</PDFDownloadLink>
				</div>
			</div>
		);
		setPDFDetailReport(
			<div style={{ margin: '15px auto' }}>
				<div className='report-button'>
					<PDFDownloadLink
						fileName={`${projectInfoInput.customerName}-${projectInfoInput.projectDate}-Detail-Report.pdf`}
						document={
							<Document>
								<Page size='A4' style={styles.page}>
									<View style={styles.pageLayout}>
										{reportHeader}
										<View style={styles.middleDiv}>
											<View style={styles.mainTitle}>
												<Text style={styles.title}>Project Report</Text>
											</View>
											{projectReportInput}
											<View style={styles.mainTitle}>
												<Text style={styles.title}>Detail Report</Text>
											</View>
											<View style={styles.projectReport}>
												<View style={styles.paragraph}>
													<Text>
														Base Project Cost:
														{' $' +
															externalGrandTotal.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														Estimated Overhead:
														{' $' +
															(
																externalGrandTotal * actualOverhead
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
												</View>
												<View style={styles.paragraph}>
													<Text>
														Total Cost (With Overhead):
														{' $' +
															(externalGrandTotal * overhead).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														Estimated Profit:
														{' $' +
															(
																externalGrandTotal *
																overhead *
																actualProfit
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
												</View>
												<View style={styles.paragraph}>
													<Text>
														Grand Total:
														{' $' +
															externalGrandTotalWithOverheadAndProfit.toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
												</View>
											</View>
											<View style={styles.projectReport}>
												<View style={styles.paragraph}>
													<Text style={styles.boldText}>Description</Text>
													<Text>Tear Off</Text>
													<Text>Disposal</Text>
													<Text style={styles.highlight}>Removal</Text>
													<Text>Primary Roof</Text>
													<Text>Pipe Jacks/Assemblies</Text>
													<Text>Underlayment</Text>
													<Text>Starter</Text>
													<Text>Ridge</Text>
													<Text>Hip</Text>
													<Text style={styles.highlight}>
														Core Installation
													</Text>
													<Text>Roofing Vent</Text>
													<Text>Intake Vent</Text>
													<Text>Rake</Text>
													<Text>Eave</Text>
													<Text>Valley</Text>
													<Text>Saddle Flashings</Text>
													<Text>Misc Flashings</Text>
													<Text style={styles.highlight}>Metal & Vents</Text>
													<Text>Gutters</Text>
													<Text>Downspouts</Text>
													<Text>Skylight & Sun Tunnels</Text>
													<Text>Chimneys</Text>
													<Text style={styles.highlight}>Options</Text>
													<Text>Deck</Text>
													<Text>Lumber</Text>
													<Text style={styles.highlight}>Wood</Text>
													<Text>Nails</Text>
													<Text>Caulk</Text>
													<Text>Paint</Text>
													<Text>Misc Tools & Activities</Text>
													<Text style={styles.highlight}>Misc Item</Text>
													<Text style={styles.highlightTotal}>
														Project Total
													</Text>
												</View>
												<View style={styles.paragraph}>
													<Text style={styles.boldText}>Material Cost</Text>
													<Text>-</Text>
													<Text>
														{'$' +
															disposalCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Removal Cost */}
													<Text style={styles.highlight}>
														{'$' +
															disposalCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(roofMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(pipeJacksMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(underlaymentMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(starterShingleMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(ridgeMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(hipMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Core Installation Material Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																roofMaterialCost * tax +
																pipeJacksMaterialCost * tax +
																underlaymentMaterialCost * tax +
																starterShingleMaterialCost * tax +
																ridgeMaterialCost * tax +
																hipMaterialCost * tax
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(roofingVentsMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(intakeVentsMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(rakeMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(eaveMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(valleyMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(saddleMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(miscFlashingMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													{/* Metal & Vent Material Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																roofingVentsMaterialCost * tax +
																intakeVentsMaterialCost * tax +
																rakeMaterialCost * tax +
																eaveMaterialCost * tax +
																valleyMaterialCost * tax +
																saddleMaterialCost * tax +
																miscFlashingMaterialCost * tax
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(guttersMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(downSpoutMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(skylightMaterialCost * tax).toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															(chimneyMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Options Material Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																guttersMaterialCost * tax +
																downSpoutMaterialCost * tax +
																skylightMaterialCost * tax +
																chimneyMaterialCost * tax
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(deckMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(lumberMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Wood Material Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																deckMaterialCost * tax +
																lumberMaterialCost * tax
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(totalNailsCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(caulkMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(paintMaterialCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															(customToolsCost * tax).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Misc Item Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																totalNailsCost * tax +
																caulkMaterialCost * tax +
																paintMaterialCost * tax +
																customToolsCost * tax
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Total Project Cost */}
													<Text style={styles.highlightTotal}>
														{'$' +
															(
																totalMaterialCost * tax +
																disposalCost
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
												</View>
												<View style={styles.paragraph}>
													<Text style={styles.boldText}>Labor Cost</Text>
													<Text>
														{'$' +
															externalTearOffCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>-</Text>
													{/* Removal Cost */}
													<Text style={styles.highlight}>
														{'$' +
															externalTearOffCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalInstallRoofCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalPipeJacksCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalUnderlaymentCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalStarterShingleCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalRidgeCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalHipCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Core Installaton Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																externalInstallRoofCost +
																externalPipeJacksCost +
																externalUnderlaymentCost +
																externalStarterShingleCost +
																externalRidgeCost +
																externalHipCost
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalRoofingVentCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalIntakeVentsCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalRakeCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalEaveCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalValleyCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalSaddleCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalMiscFlashingCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Metail & Vent Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																externalRoofingVentCost +
																externalIntakeVentsCost +
																externalRakeCost +
																externalEaveCost +
																externalValleyCost +
																externalSaddleCost +
																externalMiscFlashingCost
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalGuttersCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalDownspoutCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalSkylightFlashingCost.toLocaleString(
																'en',
																{
																	maximumFractionDigits: 2,
																}
															)}
													</Text>
													<Text>
														{'$' +
															externalChimneyFlashingCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Options Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																externalGuttersCost +
																externalDownspoutCost +
																externalSkylightFlashingCost +
																externalChimneyFlashingCost
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalDeckCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>
														{'$' +
															externalLumberCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													{/* Wood Cost */}
													<Text style={styles.highlight}>
														{'$' +
															(
																externalDeckCost + externalLumberCost
															).toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
													<Text>-</Text>
													<Text>-</Text>
													<Text>-</Text>
													<Text>-</Text>
													<Text style={styles.highlight}>-</Text>
													{/* Total Labor Cost */}
													<Text style={styles.highlightTotal}>
														{'$' +
															externalTotalLaborCost.toLocaleString('en', {
																maximumFractionDigits: 2,
															})}
													</Text>
												</View>
												<View style={styles.paragraph}>
													<Text style={styles.boldText}>Labor Hours</Text>
													<Text>
														{totalTearOffLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>-</Text>
													{/* Removal Hour */}
													<Text style={styles.highlight}>
														{totalTearOffLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalRoofInstallLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalPipeJacksLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														})}
													</Text>
													<Text>
														{totalUnderlaymentLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalStarterShingleLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalRidgeLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalHipLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													{/* Core Installation Hour */}
													<Text style={styles.highlight}>
														{(
															totalRoofInstallLaborHour +
															totalPipeJacksLaborHour +
															totalUnderlaymentLaborHour +
															totalStarterShingleLaborHour +
															totalRidgeLaborHour +
															totalHipLaborHour
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalRoofingVentLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalIntakeVentsLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalRakeLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalEaveLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalValleyLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalSaddleLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalMiscFlashingLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													{/* Metal & Vent Hour */}
													<Text style={styles.highlight}>
														{(
															totalRoofingVentLaborHour +
															totalIntakeVentsLaborHour +
															totalRakeLaborHour +
															totalEaveLaborHour +
															totalValleyLaborHour +
															totalSaddleLaborHour +
															totalMiscFlashingLaborHour
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalGuttersLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalDownSpoutLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalSkylightLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalChimneyLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													{/* Options Hour */}
													<Text style={styles.highlight}>
														{(
															totalGuttersLaborHour +
															totalDownSpoutLaborHour +
															totalSkylightLaborHour +
															totalChimneyLaborHour
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalDeckLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>
														{totalLumberLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													{/* Wood Hour */}
													<Text style={styles.highlight}>
														{(
															totalDeckLaborHour + totalLumberLaborHour
														).toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
													<Text>-</Text>
													<Text>-</Text>
													<Text>-</Text>
													<Text>-</Text>
													<Text style={styles.highlight}>-</Text>
													<Text style={styles.highlightTotal}>
														{totalLaborHour.toLocaleString('en', {
															maximumFractionDigits: 2,
														}) + ' Hrs'}
													</Text>
												</View>
											</View>
										</View>
									</View>
								</Page>
							</Document>
						}>
						<button className='button'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-pdf'
							/>
							Detail Report
						</button>
					</PDFDownloadLink>
				</div>
			</div>
		);
	};
	// handle token and login persist
	useEffect(() => {
		if (props.userId !== secretUser && props.token) {
			fetch(`${APIURL}/stripes/create-payment-intent`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ items: 1500 }),
			})
				.then((res) => res.json())
				.then((data) => {
					setClientSecret(data.clientSecret);
					setPaymentId(data.id);
				});
		}
		const verifyRefreshToken = async () => {
			if (persistLogin === 'true') {
				try {
					const newToken = await refresh();
					props.setToken(newToken);
				} catch (error) {
					console.log(error);
				} finally {
					return;
				}
			}
		};
		verifyRefreshToken();
		//eslint-disable-next-line
	}, [props.token]);

	//handle file upload/extract to update inout
	useEffect(() => {
		setUserInput({
			squareFootage: parseFloat(analyzedSquareFootage),
			ridge: parseFloat(analyzedRidge),
			hip: parseFloat(analyzedHip),
			eave: parseFloat(analyzedEave),
			valleyMeasurement: parseFloat(analyzedValley),
		});
	}, [
		analyzedSquareFootage,
		analyzedEave,
		analyzedRidge,
		analyzedHip,
		analyzedValley,
	]);

	const createEstimatedProjectReport = async () => {
		const rawMaterialCost =
			(roofMaterialCost +
				underlaymentMaterialCost +
				deckMaterialCost +
				guttersMaterialCost +
				ridgeMaterialCost +
				hipMaterialCost +
				eaveMaterialCost +
				rakeMaterialCost +
				valleyMaterialCost +
				skylightMaterialCost +
				chimneyMaterialCost +
				miscFlashingMaterialCost +
				downSpoutMaterialCost +
				roofingVentsMaterialCost +
				intakeVentsMaterialCost +
				starterShingleMaterialCost +
				lumberMaterialCost +
				saddleMaterialCost +
				pipeJacksMaterialCost +
				caulkMaterialCost +
				paintMaterialCost +
				ridgeNailCost +
				roofingNailCost +
				ridgeCoilNailCost +
				plasticCapCost +
				customToolsCost) *
			markup *
			tax;
		const estimatedOverhead = externalGrandTotal * actualOverhead;
		const projectCostWithOverhead = externalGrandTotal * overhead;
		const estimatedProfit = externalGrandTotal * overhead * actualProfit;
		const totalProjectCost = externalGrandTotal * overhead * profit;
		const tearOffAndDisposalCost = externalTearOffCost + disposalCost;
		const gutterAndDownspoutCost =
			externalDownspoutCost +
			externalGuttersCost +
			guttersMaterialCost * tax +
			downSpoutMaterialCost * tax;
		const newDeckInstallationCost = externalDeckCost + deckMaterialCost * tax;
		const newRoofInstallationCost =
			externalGrandTotal * overhead * profit -
			(externalTearOffCost + disposalCost) -
			(externalDownspoutCost +
				externalGuttersCost +
				guttersMaterialCost * tax +
				downSpoutMaterialCost * tax) -
			(externalDeckCost + deckMaterialCost * tax);
		const formData = {
			customer: projectInfoInput.customerName ?? '',
			phone: projectInfoInput.projectPhone ?? '',
			address: projectInfoInput.projectAddress ?? '',
			created_date: projectInfoInput.projectDate ?? '',
			job_description: projectInfoInput.jobDescription ?? '',
			job_number: projectInfoInput.jobNumber ?? '',
			owner: props.userId,
			project_detail: {
				isTearOff: tearOff ?? '',
				isNewDeck: newDeck ?? '',
				isNewGutters: gutters ?? '',
				enteredTotalFootage: roofMaterialQuantity ?? '',
				enteredCurrentRoofType: currentRoof.value ?? '',
				enteredDesiredRoofType: desiredRoof.value ?? '',
				enteredDesiredUnderlayment: desiredUnderlayment.value ?? '',
				enteredNewDeck: newDeckMaterial.value ?? '',
				enteredStarterMaterial: starterMaterial.value ?? '',
				enteredLumberMaterial: lumberMaterial.value ?? '',
				enteredRoofRidgeMaterial: hipAndRidgeMaterial.value ?? '',
				enteredValleyMaterial: valleysMaterial.value ?? '',
				enteredNosingMaterial: nosingMaterial.value ?? '',
				enteredRidgeVentMaterial: roofingVentsMaterial.value ?? '',
				enteredIntakeVentMaterial: intakeVentsMaterial.value ?? '',
				enteredChimneyFlashingList: chimneyFlashingList ?? '',
				enteredSkylightFlashingList: skylightFlashingList ?? '',
				enteredMiscFlashingList: miscFlashingList ?? '',
				enteredSaddleMaterial: saddleMeasurementList ?? '',
				enteredPipeJacks: pipeJacksList ?? '',
				enteredCustomToolsList: customToolsList ?? '',
				enteredCaulkMaterial: caulkMaterial.value ?? '',
				enteredPaintMaterial: paintMaterial.value ?? '',
				enteredRoofingNailsMaterial: roofingNails.value ?? '',
				enteredRidgeCoilNailsMaterial: ridgeCoilNails.value ?? '',
				enteredRidgeNailMaterial: ridgeNails.value ?? '',
				enteredPlasticCapMaterial: plasticCaps.value ?? '',
				enteredChimneyFlashingAllowance: chimneyFlashingList.length ?? '',
				enteredSkylightFlashingAllowance: skylightFlashingList.length ?? '',
				enteredMiscFlashingAllowance: miscFlashingList.length ?? '',
				enteredInstallComplexity:
					typeof installComplexity === 'number'
						? installComplexity.toFixed(2)
						: '',
				enteredSquareFootage:
					typeof userInput.squareFootage === 'number'
						? userInput.squareFootage.toFixed(2)
						: '',
				enteredEave:
					typeof userInput.eave === 'number' ? userInput.eave.toFixed(2) : '',
				enteredRidge:
					typeof userInput.ridge === 'number' ? userInput.ridge.toFixed(2) : '',
				enteredHip:
					typeof userInput.hip === 'number' ? userInput.hip.toFixed(2) : '',
				enteredRake:
					typeof userInput.hip === 'number' ? userInput.rake.toFixed(2) : '',
				enteredValley: valleyMaterialQuantity ?? '',
				enteredCustomTools: customToolsList.length ?? '',
				enteredWastePercentage:
					typeof userInput.waste === 'number' ? userInput.waste.toFixed(2) : '',
				enteredOverheadPercentage:
					typeof userInput.overhead === 'number'
						? userInput.overhead.toFixed(2)
						: '',
				enteredProfitPercentage:
					typeof userInput.profit === 'number'
						? userInput.profit.toFixed(2)
						: '',
				enteredLaborRate:
					typeof userInput.externalHourlyCost === 'number'
						? userInput.externalHourlyCost.toFixed(2)
						: '',
				estimatedTearOff:
					typeof externalTearOffCost === 'number'
						? externalTearOffCost.toFixed(2)
						: '',
				estimatedTearOffHour:
					typeof totalTearOffLaborHour === 'number'
						? totalTearOffLaborHour.toFixed(2)
						: '',
				estimatedDisposalCost:
					typeof disposalCost === 'number' ? disposalCost.toFixed(2) : '',
				estimatedRawMaterialCost:
					typeof rawMaterialCost === 'number' ? rawMaterialCost.toFixed(2) : '',
				estimatedLaborCost:
					typeof externalTotalLaborCost === 'number'
						? externalTotalLaborCost.toFixed(2)
						: '',
				estimatedTotalLaborHour:
					typeof totalLaborHour === 'number' ? totalLaborHour.toFixed(2) : '',
				estimatedBaseProjectCost:
					typeof externalGrandTotal === 'number'
						? externalGrandTotal.toFixed(2)
						: '',
				estimatedOverhead:
					typeof estimatedOverhead === 'number'
						? estimatedOverhead.toFixed(2)
						: '',
				estimatedProjectCostWithOverhead:
					typeof projectCostWithOverhead === 'number'
						? projectCostWithOverhead.toFixed(2)
						: '',
				estimatedProjectProfit:
					typeof estimatedProfit === 'number' ? estimatedProfit.toFixed(2) : '',
				estimatedTotalProjectCost:
					typeof totalProjectCost === 'number'
						? totalProjectCost.toFixed(2)
						: '',
				estimatedNewRoofCost:
					typeof (roofMaterialCost * tax) === 'number'
						? (roofMaterialCost * tax).toFixed(2)
						: '',
				estimatedRoofMaterialQuantity: roofMaterialUnit ?? '',
				estimatedNewRoofLaborCost:
					typeof externalInstallRoofCost === 'number'
						? externalInstallRoofCost.toFixed(2)
						: '',
				estimatedNewRoofLaborHour:
					typeof totalRoofInstallLaborHour === 'number'
						? totalRoofInstallLaborHour.toFixed(2)
						: '',
				estimatedNewUnderlaymentCost:
					typeof (underlaymentMaterialCost * tax) === 'number'
						? (underlaymentMaterialCost * tax).toFixed(2)
						: '',
				estimatedUnderlaymentMaterialQuantity: underlaymentMaterialUnit ?? '',
				estimatedNewUnderlaymentLaborCost:
					typeof externalUnderlaymentCost === 'number'
						? externalUnderlaymentCost.toFixed(2)
						: '',
				estimatedNewUnderlaymentLaborHour:
					typeof totalUnderlaymentLaborHour === 'number'
						? totalUnderlaymentLaborHour.toFixed(2)
						: '',
				estimatedNewDeckCost:
					typeof (deckMaterialCost * tax) === 'number'
						? (deckMaterialCost * tax).toFixed(2)
						: '',
				estimatedDeckMaterialQuantity: deckMaterialUnit ?? '',
				estimatedNewDeckLaborCost:
					typeof externalDeckCost === 'number'
						? externalDeckCost.toFixed(2)
						: '',
				estimatedNewDeckLaborHour:
					typeof totalDeckLaborHour === 'number'
						? totalDeckLaborHour.toFixed(2)
						: '',
				estimatedStarterShingleCost:
					typeof (starterShingleMaterialCost * tax) === 'number'
						? (starterShingleMaterialCost * tax).toFixed(2)
						: '',
				estimatedStarterShingleMaterialQuantity:
					starterShingleMaterialUnit ?? '',
				estimatedStarterShingleLaborCost:
					typeof externalStarterShingleCost === 'number'
						? externalStarterShingleCost.toFixed(2)
						: '',
				estimatedStarterShingleLaborHour:
					typeof totalStarterShingleLaborHour === 'number'
						? totalStarterShingleLaborHour.toFixed(2)
						: '',
				estimatedLumberCost:
					typeof (lumberMaterialCost * tax) === 'number'
						? (lumberMaterialCost * tax).toFixed(2)
						: '',
				estimatedLumberMaterialQuantity: lumberMaterialQuantity ?? '',
				estimatedLumberLaborCost:
					typeof externalLumberCost === 'number'
						? externalLumberCost.toFixed(2)
						: '',
				estimatedLumberLaborHour:
					typeof totalLumberLaborHour === 'number'
						? totalLumberLaborHour?.toFixed(2)
						: '',
				estimatedRidgeCost:
					typeof (ridgeMaterialCost * tax) === 'number'
						? (ridgeMaterialCost * tax).toFixed(2)
						: '',
				estimatedRidgeLaborCost:
					typeof externalRidgeCost === 'number'
						? externalRidgeCost.toFixed(2)
						: '',
				estimatedRidgeLaborHour:
					typeof totalRidgeLaborHour === 'number'
						? totalRidgeLaborHour.toFixed(2)
						: '',
				estimatedHipCost:
					typeof (hipMaterialCost * tax) === 'number'
						? (hipMaterialCost * tax).toFixed(2)
						: '',
				estimatedHipMaterialQuantity: hipMaterialUnit ?? '',
				estimatedHipAndRidgeCost:
					typeof ((ridgeMaterialCost + hipMaterialCost) * tax) === 'number'
						? ((ridgeMaterialCost + hipMaterialCost) * tax).toFixed(2)
						: '',
				estimatedHipLaborCost:
					typeof externalHipCost === 'number' ? externalHipCost.toFixed(2) : '',
				estimatedHipLaborHour:
					typeof totalHipLaborHour === 'number'
						? totalHipLaborHour.toFixed(2)
						: '',
				estimatedEaveCost:
					typeof (eaveMaterialCost * tax) === 'number'
						? (eaveMaterialCost * tax).toFixed(2)
						: '',
				estimatedEaveMaterialQuantity: eaveMaterialUnit ?? '',
				estimatedEaveLaborCost:
					typeof externalEaveCost === 'number'
						? externalEaveCost.toFixed(2)
						: '',
				estimatedEaveLaborHour:
					typeof totalEaveLaborHour === 'number'
						? totalEaveLaborHour.toFixed(2)
						: '',
				estimatedRakeCost:
					typeof (rakeMaterialCost * tax) === 'number'
						? (rakeMaterialCost * tax).toFixed(2)
						: '',
				estimatedRakeMaterialQuantity: rakeMaterialUnit ?? '',
				estimatedRakeLaborCost:
					typeof externalRakeCost === 'number'
						? externalRakeCost.toFixed(2)
						: '',
				estimatedRakeLaborHour:
					typeof totalRakeLaborHour === 'number'
						? totalRakeLaborHour.toFixed(2)
						: '',
				estimatedValleyCost:
					typeof (valleyMaterialCost * tax) === 'number'
						? (valleyMaterialCost * tax).toFixed(2)
						: '',
				estimatedValleyMaterialQuantity: valleyMaterialUnit ?? '',
				estimatedValleyLaborCost:
					typeof externalValleyCost === 'number'
						? externalValleyCost.toFixed(2)
						: '',
				estimatedValleyLaborHour:
					typeof totalValleyLaborHour === 'number'
						? totalValleyLaborHour.toFixed(2)
						: '',
				estimatedVentCost:
					typeof (roofingVentsMaterialCost * tax) === 'number'
						? (roofingVentsMaterialCost * tax).toFixed(2)
						: '',
				estimatedVentMaterialQuantity: roofingVentsMaterialUnit ?? '',
				estimatedVentLaborCost:
					typeof externalRoofingVentCost === 'number'
						? externalRoofingVentCost.toFixed(2)
						: '',
				estimatedVentLaborHour:
					typeof totalRoofingVentLaborHour === 'number'
						? totalRoofingVentLaborHour.toFixed(2)
						: '',
				estimatedIntakeVentsQuantity: intakeVentsQuantity ?? '',
				estimatedIntakeVentsCost:
					typeof (intakeVentsMaterialCost * tax) === 'number'
						? (intakeVentsMaterialCost * tax).toFixed(2)
						: '',
				estimatedIntakeVentsLaborCost:
					typeof externalIntakeVentsCost === 'number'
						? externalIntakeVentsCost.toFixed(2)
						: '',
				estimatedIntakeVentsLaborHour:
					typeof totalIntakeVentsLaborHour === 'number'
						? totalIntakeVentsLaborHour.toFixed(2)
						: '',
				estimatedChimneyCost:
					typeof (chimneyMaterialCost * tax) === 'number'
						? (chimneyMaterialCost * tax).toFixed(2)
						: '',
				estimatedChimneyMaterialQuantity: chimneyMaterialQuantity ?? '',
				estimatedChimneyLaborCost:
					typeof externalChimneyFlashingCost === 'number'
						? externalChimneyFlashingCost.toFixed(2)
						: '',
				estimatedChimneyLaborHour:
					typeof totalChimneyLaborHour === 'number'
						? totalChimneyLaborHour.toFixed(2)
						: '',
				estimatedSkylightCost:
					typeof (skylightMaterialCost * tax) === 'number'
						? (skylightMaterialCost * tax).toFixed(2)
						: '',
				estimatedSkylightMaterialQuantity: skylightMaterialQuantity ?? '',
				estimatedSkylightLaborCost:
					typeof externalSkylightFlashingCost === 'number'
						? externalSkylightFlashingCost.toFixed(2)
						: '',
				estimatedSkylightLaborHour:
					typeof totalSkylightLaborHour === 'number'
						? totalSkylightLaborHour.toFixed(2)
						: '',
				estimatedMiscFlashingQuantity: miscFlashingList.length
					? miscFlashingList.length + ' Unit(s)'
					: '',
				estimatedMiscFlashingCost:
					typeof (miscFlashingMaterialCost * tax) === 'number'
						? (miscFlashingMaterialCost * tax).toFixed(2)
						: '',
				estimatedMiscFlashingLaborCost:
					typeof externalMiscFlashingCost === 'number'
						? externalMiscFlashingCost.toFixed(2)
						: '',
				estimatedMiscFlashingLaborHour:
					typeof totalMiscFlashingLaborHour === 'number'
						? totalMiscFlashingLaborHour.toFixed(2)
						: '',
				estimatedGutterCost:
					typeof (guttersMaterialCost * tax) === 'number'
						? (guttersMaterialCost * tax).toFixed(2)
						: '',
				estimatedGutterMaterialQuantity: guttersMaterialQuantity ?? '',
				estimatedGutterLaborCost:
					typeof externalGuttersCost === 'number'
						? externalGuttersCost.toFixed(2)
						: '',
				estimatedGutterLaborHour:
					typeof totalGuttersLaborHour === 'number'
						? totalGuttersLaborHour.toFixed(2)
						: '',
				estimatedDownspoutCost:
					typeof (downSpoutMaterialCost * tax) === 'number'
						? (downSpoutMaterialCost * tax).toFixed(2)
						: '',
				estimatedDownspoutMaterialQuantity: downSpoutMaterialQuantity ?? '',
				estimatedDownspoutLaborCost:
					typeof externalDownspoutCost === 'number'
						? externalDownspoutCost.toFixed(2)
						: '',
				estimatedDownspoutLaborHour:
					typeof totalDownSpoutLaborHour === 'number'
						? totalDownSpoutLaborHour.toFixed(2)
						: '',
				estimatedCustomToolsQuantity: customToolsList.length
					? customToolsList.length + ' Item(s)'
					: '',
				estimatedCustomToolsCost:
					typeof (customToolsCost * tax) === 'number'
						? (customToolsCost * tax).toFixed(2)
						: '',
				estimatedNailsCost:
					typeof (totalNailsCost * tax) === 'number'
						? (totalNailsCost * tax).toFixed(2)
						: '',
				estimatedRoofingNailQuantity: roofingNailUnit ?? '',
				estimatedRidgeNailQuantity: ridgeNailUnit ?? '',
				estimateRidgeCoilNailQuantity: ridgeCoilNailUnit ?? '',
				estimatedPlasticCapQuantity: plasticCapUnit ?? '',
				estimatedSaddlesQuantity: saddleMeasurementList.length ?? '',
				estimatedSaddlesCost:
					typeof (saddleMaterialCost * tax) === 'number'
						? (saddleMaterialCost * tax).toFixed(2)
						: '',
				estimatedSaddleLaborCost:
					typeof externalSaddleCost === 'number'
						? externalSaddleCost.toFixed(2)
						: '',
				estimatedSaddleLaborHour:
					typeof totalSaddleLaborHour === 'number'
						? totalSaddleLaborHour.toFixed(2)
						: '',
				estimatedPipesJacksQuantity: pipeJacksList.length ?? '',
				estimatedPipeJacksCost:
					typeof (pipeJacksMaterialCost * tax) === 'number'
						? (pipeJacksMaterialCost * tax).toFixed(2)
						: '',
				estimatedPipeJacksLaborCost:
					typeof externalPipeJacksCost === 'number'
						? externalPipeJacksCost.toFixed(2)
						: '',
				estimatedPipeJacksLaborHour:
					typeof totalPipeJacksLaborHour === 'number'
						? totalPipeJacksLaborHour.toFixed(2)
						: '',
				estimatedCaulkQuantity: caulkQuantity ?? '',
				estimatedCaulkCost:
					typeof (caulkMaterialCost * tax) === 'number'
						? (caulkMaterialCost * tax).toFixed(2)
						: '',
				estimatedPaintQuantity: paintQuantity,
				estimatedPaintCost:
					typeof (paintMaterialCost * tax) === 'number'
						? (paintMaterialCost * tax).toFixed(2)
						: '',
				estimatedCaulkAndPaintCost:
					typeof (caulkMaterialCost * tax + paintMaterialCost * tax) ===
					'number'
						? (caulkMaterialCost * tax + paintMaterialCost * tax).toFixed(2)
						: '',
				quotedTearOffAndDisposal:
					typeof tearOffAndDisposalCost === 'number'
						? tearOffAndDisposalCost.toFixed(2)
						: '',
				quotedNewDeckInstallation:
					typeof newDeckInstallationCost === 'number'
						? newDeckInstallationCost.toFixed(2)
						: '',
				quotedGutterAndDownspout:
					typeof gutterAndDownspoutCost === 'number'
						? gutterAndDownspoutCost.toFixed(2)
						: '',
				quotedNewRoofInstallation:
					typeof newRoofInstallationCost === 'number'
						? newRoofInstallationCost.toFixed(2)
						: '',
			},
		};
		await fetch(`${APIURL}/estimated-projects/new`, {
			method: 'POST',
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json ',
			},
			credentials: 'include',
			body: JSON.stringify(formData),
		})
			.then((response) => response.json())
			.catch((error) => {
				console.error('Error:', error);
			});
	};

	const handlePaymentReady = async () => {
		calculateTearOffCost();
		calculateInstallRoofCost();
		calculateUnderlaymentCost();
		calculateNewDeckMaterial();
		calculateStarterShingleAndLumberCost();
		calculateSkylightAndChimneyFlashingAllowance();
		calculateValleyCost();
		calculateCustomToolsCost();
		calculateMiscFlashingCost();
		calculateRidgeHipRakeEaveCost();
		calculateGuttersAndDownspoutCost();
		calculateRoofingVent();
		calculateSaddleCost();
		calculateCaulkAndPaintMaterialCost();
		calculatePipeJacksCost();
		calculateGrandTotal();
		if (externalGrandTotalWithOverheadAndProfit) {
			setTotalChargeAmount(
				//(externalGrandTotalWithOverheadAndProfit * 0.0025).toFixed(2)
				(20).toFixed(2)
			);
			const result = await fetch(
				`${APIURL}/stripes/v1/payment_intents/${paymentId}`,
				{
					method: 'POST',
					headers: { 'Content-Type': 'application/json' },
					body: JSON.stringify({
						//items: externalGrandTotalWithOverheadAndProfit.toFixed(0),
						items: (20.0).toFixed(0),
					}),
				}
			)
				.then((res) => res.json())
				.then((data) => {
					return true;
				});
			return result;
		}
	};

	const postPayment = async () => {
		setModalBox('block');
		setAgreementMessage('none');
		calculateTearOffCost();
		calculateInstallRoofCost();
		calculateUnderlaymentCost();
		calculateNewDeckMaterial();
		calculateStarterShingleAndLumberCost();
		calculateSkylightAndChimneyFlashingAllowance();
		calculateValleyCost();
		calculateCustomToolsCost();
		calculateMiscFlashingCost();
		calculateRidgeHipRakeEaveCost();
		calculateGuttersAndDownspoutCost();
		calculateRoofingVent();
		calculateSaddleCost();
		calculateCaulkAndPaintMaterialCost();
		calculatePipeJacksCost();
		calculateGrandTotal();
		setPaymentBox('none');
		CreatePDFReport();
		createEstimatedProjectReport();
		setReport(
			<div className=''>
				<Row className='field-set'>
					<legend className=''>
						<p className=''> Summarized Report </p>
					</legend>
					<div className='report-breakline'>
						<p>Project's Information </p>
					</div>
					<Row className='customer-board'>
						<Col className='project-info'>
							<p> Customer Name: {projectInfoInput.customerName}</p>
							<p> Phone Number: {projectInfoInput.projectPhone}</p>
							<p> Address: {projectInfoInput.projectAddress}</p>
							<p> Project Date: {projectInfoInput.projectDate}</p>
							<p> Job Number: {projectInfoInput.jobNumber}</p>
							<p> Job Description: {projectInfoInput.jobDescription} </p>
							<p> Tear-Off: {tearOff}</p>
						</Col>
						<Col className='project-input'>
							<p> Current Roof Type: {currentRoof.value} </p>
							<p> Desired Roof Type: {desiredRoof.value} </p>
							<p> Desired Underlayment: {desiredUnderlayment.value} </p>
							<p> New Deck: {newDeckMaterial.value} </p>
							<p> Square Footage: {userInput.squareFootage} </p>
							<p> Chimneys Flashing Allowance: {chimneyMaterialQuantity}</p>
							<p> Skylight Flashing Allowance: {skylightMaterialQuantity} </p>
						</Col>
						<Col className='project-input'>
							<p> Misc Flashing Allowance: {miscFlashingMaterialQuantity} </p>
							<p> Custom Tools: {customToolsQuantity} </p>
							<p> New Gutters: {gutters} </p>
							<p> Eave (LF): {userInput.eave}</p>
							<p> Ridge (LF): {userInput.ridge}</p>
							<p> Hip (LF): {userInput.hip}</p>
							<p> Rake (LF): {userInput.rake}</p>
						</Col>
						<Col className='project-input'>
							<p> Valley (LF): {valleyMaterialQuantity}</p>
							<p> Install Complexity: x{installComplexity.toFixed(1)} </p>
							<p> Waste (%): {userInput.waste}% </p>
							{/* <p> Material Mark-Up Rate (%): {userInput.materialMarkup}% </p> */}
							<p> Overhead (%): {userInput.overhead}% </p>
							<p> Profit (%): {userInput.profit}% </p>
							<p> Labor Rate: ${userInput.externalHourlyCost}/Hr </p>
						</Col>
					</Row>
					<div className='inputs-board'></div>
					<div className='report-breakline'>
						<p> Project's Report</p>
					</div>
					<Row className='report-board'>
						<Col lg={{ span: 3, offset: 2 }} className='project-report'>
							<ul className='toplevel-breakdown'>
								<li>
									Tear-Off Cost
									<span>
										$
										{externalTearOffCost.toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
								<li>
									Disposal Cost
									<span>
										$
										{disposalCost.toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
								{/* <li className='breakdown-header'>
									 Tear-Off & Disposal Cost
									<span>
										$
										{(externalTearOffCost + disposalCost).toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li> */}
								{/* <li>
									Gutter's Material Cost
									<span>
										$
										{(guttersMaterialCost * markup * tax).toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
								<li>
									Downspout's Material Cost
									<span>
										$
										{(downSpoutMaterialCost * markup * tax).toLocaleString(
											'en',
											{ maximumFractionDigits: 2 }
										)}
									</span>
								</li> */}
								<li>
									Raw Material Cost
									<span>
										$
										{(
											(roofMaterialCost +
												underlaymentMaterialCost +
												deckMaterialCost +
												guttersMaterialCost +
												ridgeMaterialCost +
												hipMaterialCost +
												eaveMaterialCost +
												rakeMaterialCost +
												valleyMaterialCost +
												skylightMaterialCost +
												chimneyMaterialCost +
												miscFlashingMaterialCost +
												downSpoutMaterialCost +
												roofingVentsMaterialCost +
												intakeVentsMaterialCost +
												starterShingleMaterialCost +
												lumberMaterialCost +
												saddleMaterialCost +
												pipeJacksMaterialCost +
												caulkMaterialCost +
												paintMaterialCost +
												ridgeNailCost +
												roofingNailCost +
												plasticCapCost +
												ridgeCoilNailCost +
												customToolsCost) *
											markup *
											tax
										).toLocaleString('en', { maximumFractionDigits: 2 })}
									</span>
								</li>
								<li className='breakdown-header'>
									Total Raw Material & Disposal Cost
									<span>
										$
										{(
											totalMaterialCost * tax * markup +
											disposalCost
										).toLocaleString('en', { maximumFractionDigits: 2 })}
									</span>
								</li>
								<li className='breakdown-header'>
									Total Labor Cost (With Tear-Off)
									<span>
										$
										{externalTotalLaborCost.toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
							</ul>
						</Col>
						<Col lg={3} className='project-report'>
							<ul className='toplevel-breakdown'>
								<li className='breakdown-header'>
									Base Project Cost
									<span>
										$
										{externalGrandTotal.toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
								<li>
									Estimated Overhead ({userInput.overhead}%)
									<span>
										$
										{(externalGrandTotal * actualOverhead).toLocaleString(
											'en',
											{
												maximumFractionDigits: 2,
											}
										)}
									</span>
								</li>
								<li className='breakdown-header'>
									Project Cost (With Overhead)
									<span>
										$
										{(externalGrandTotal * overhead).toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
								<li>
									Estimated Profit ({userInput.profit}%)
									<span>
										$
										{(
											externalGrandTotal *
											overhead *
											actualProfit
										).toLocaleString('en', {
											maximumFractionDigits: 2,
										})}
									</span>
								</li>
								<li className='breakdown-header'>
									Total Project Cost
									<span>
										$
										{externalGrandTotalWithOverheadAndProfit.toLocaleString(
											'en',
											{
												maximumFractionDigits: 2,
											}
										)}
									</span>
								</li>
							</ul>
						</Col>
					</Row>
				</Row>
			</div>
		);
	};

	//THIS IS THE RENDER RETURN
	if (
		!props.token ||
		props.token === '' ||
		props.token === undefined ||
		props.token === null
	) {
		return (
			<h2 className='not-signin-message'>Please sign in to access this page</h2>
		);
	} else if (props.userId === secretUser) {
		return (
			<div>
				<div className='container'>
					<div style={{ marginTop: '30px' }}>
						<h2 className='title'>ROOF ESTIMATOR </h2>
					</div>
					<Col>
						<div className='analysis'>
							<h4 className='header4'> Upload A File Or Proceed Below</h4>
							<div className='file-input-section'>
								<label htmlFor='file'>
									<div className='input-file-label'>
										<FontAwesomeIcon
											className='font-awesome'
											icon='fa-solid fa-upload'
										/>
										<span className='choose-file'> Choose A File </span>
									</div>
								</label>
								<p className='selected-file'>File: {image.name} </p>
								<input
									type='file'
									accept='.pdf, image/png, image/jpg, image/jpeg'
									onChange={handleFileUploadChange}
									className='input-file'
									id='file'
								/>
							</div>
							<Form className='pb-5 pt-2'>
								<div className='row'>
									<div className='col-12 col-lg-4 pt-2'>
										<Form.Label>Image Height (in)</Form.Label>
										<Form.Control
											type='text'
											placeholder='24'
											name='userEnteredHeight'
											onChange={handleUserEnteredHeightChange}
										/>
									</div>
									<div className='col-6 col-lg-4 pt-2'>
										<Form.Label>Image Scale</Form.Label>
										<Form.Select
											required
											name='scaleFactor'
											value={userEnteredScale}
											onChange={handleScaleFactorChange}>
											<optgroup label='Scale Factor'>
												{scaleFactorDropDown}
											</optgroup>
										</Form.Select>
									</div>
									<div className='col-6 col-lg-4 pt-2'>
										<Form.Label>Roof's Slope</Form.Label>
										<Form.Select
											required
											name='slopeFactor'
											value={userEnteredSlope}
											onChange={handleSlopeFactorChange}>
											<optgroup label='Scale Factor'>
												{slopeFactorDropDown}
											</optgroup>
										</Form.Select>
									</div>
								</div>
							</Form>
							<button
								type='submit'
								style={{
									cursor:
										image.name === 'No File Chosen' || disableBtn
											? 'not-allowed'
											: 'pointer',
								}}
								disabled={image.name === 'No File Chosen' || disableBtn}
								className='button'
								onClick={uploadImage}>
								<div
									className={`${spinner} spinner-border`}
									role='status'></div>
								{analyzing}
							</button>
							<p
								className='mt-3 mb-0'
								style={{ color: failedAnalyze ? 'red' : '#003087' }}>
								{errorMsg}
							</p>
						</div>
					</Col>
					<Elements options={options} stripe={props.stripe}>
						<StripeCheckout
							projectInfoInput={projectInfoInput}
							installComplexity={installComplexity}
							userId={props.userId}
							userInput={userInput}
							handleChange={handleChange}
							handleProjectInfoChange={handleProjectInfoChange}
							handleTearOffChange={handleTearOffChange}
							handleCurrentRoofChange={handleCurrentRoofChange}
							handleNewDeckChange={handleNewDeckChange}
							handleDesiredRoofChange={handleDesiredRoofChange}
							handleDesiredUnderlaymentChange={handleDesiredUnderlaymentChange}
							//handleNumberOfLayerChange={handleNumberOfLayerChange}
							//handlePermitChange={handlePermitChange}
							handleNewDeckMaterialChange={handleNewDeckMaterialChange}
							handleGuttersChange={handleGuttersChange}
							handleInstallComplexityChange={handleInstallComplexityChange}
							handleRoofingNailsChange={handleRoofingNailsChange}
							handleRidgeCoilNailsChange={handleRidgeCoilNailsChange}
							handlePlasticCapsChange={handlePlasticCapsChange}
							handleRidgeNailsChange={handleRidgeNailsChange}
							handleStarterMaterialChange={handleStarterMaterialChange}
							handleLumberMaterialChange={handleLumberMaterialChange}
							handleRoofingVentsMaterialChange={
								handleRoofingVentsMaterialChange
							}
							handleIntakeVentsMaterialChange={handleIntakeVentsMaterialChange}
							handleValleysMaterialChange={handleValleysMaterialChange}
							handlePaintMaterialChange={handlePaintMaterialChange}
							handleHipAndRidgeMaterialChange={handleHipAndRidgeMaterialChange}
							handleCaulkMaterialChange={handleCaulkMaterialChange}
							handleNosingMaterialChange={handleNosingMaterialChange}
							handleMiscFlashingsMaterialChange={
								handleMiscFlashingsMaterialChange
							}
							handlePipeJacksAndAssembliesMaterialChange={
								handlePipeJacksAndAssembliesMaterialChange
							}
							handleSaddlesMaterialChange={handleSaddlesMaterialChange}
							addMiscFlashing={addMiscFlashing}
							showAddToast={showAddToast}
							handleAddToast={handleAddToast}
							showDeleteToast={showDeleteToast}
							handleDeleteToast={handleDeleteToast}
							addChimneyFlashing={addChimneyFlashing}
							addSkylightFlashing={addSkylightFlashing}
							addValleyMeasurement={addValleyMeasurement}
							addSaddleMeasurement={addSaddleMeasurement}
							addPipeJacksAndAssemblies={addPipeJacksAndAssemblies}
							addCustomTools={addCustomTools}
							removeCustomItems={removeCustomItems}
							miscFlashingList={miscFlashingList}
							chimneyFlashingList={chimneyFlashingList}
							skylightFlashingList={skylightFlashingList}
							valleyMeasurementList={valleyMeasurementList}
							saddleMeasurementList={saddleMeasurementList}
							pipeJacksList={pipeJacksList}
							customToolsList={customToolsList}
							asphaltShinglesDropdown={asphaltShinglesDropdown}
							onePieceConcreteTilesDropdown={onePieceConcreteTilesDropdown}
							usClayTilesDropdown={usClayTilesDropdown}
							underlaymentsDropdown={underlaymentsDropdown}
							coilNailsDropdown={coilNailsDropdown}
							plasticCapsDropdown={plasticCapsDropdown}
							stripNailsDropdown={stripNailsDropdown}
							caulkDropdown={caulkDropdown}
							paintDropdown={paintDropdown}
							startersDropdown={startersDropdown}
							roofingVentsDropdown={roofingVentsDropdown}
							intakeVentsDropdown={intakeVentsDropdown}
							hipAndRidgeDropdown={hipAndRidgeDropdown}
							deckingDropdown={deckingDropdown}
							lumberDropdown={lumberDropdown}
							valleysDropdown={valleysDropdown}
							nosingDropdown={nosingDropdown}
							miscFlashingDropdown={miscFlashingDropdown}
							pipeJacksAndAssembliesDropdown={pipeJacksAndAssembliesDropdown}
							saddlesDropdown={saddlesDropdown}
							displayLumberMaterial={displayLumberMaterial}
							displayStarterMaterial={displayStarterMaterial}
							displayDeckMaterial={displayDeckMaterial}
							paymentBox={paymentBox}
							setPaymentBox={setPaymentBox}
							handlePaymentReady={handlePaymentReady}
							postPayment={postPayment}
						/>
					</Elements>
					<Col
						sm={{ span: 6, offset: 3 }}
						className='payment-modal-box'
						style={{ display: `${modalBox}` }}>
						<div className='payment-success-box'>
							<h2 className='payment-success'> Report Completed!</h2>
							<div className='payment-success-check'>
								<h3 className='payment-check-mark'>
									<FontAwesomeIcon icon='circle-check' />
								</h3>
							</div>
							<p> Close this box and download your reports below</p>
							<button className='general-button' onClick={handleModalBox}>
								Close
							</button>
						</div>
					</Col>
					{report}
					{pdfReport}
					{materialPricingPDF}
					{pdfDetailReport}
				</div>
			</div>
		);
	} else {
		return (
			<div>
				<div className='container'>
					<div style={{ marginTop: '30px' }}>
						<h2 className='title'>ROOF ESTIMATOR </h2>
					</div>
					{/* <Col>
						<div className='analysis'>
							<h4 className='header4'> Upload A File Or Proceed Below </h4>
							<div className='file-input-section'>
								<label htmlFor='file'>
									<div className='input-file-label'>
										<FontAwesomeIcon
											className='font-awesome'
											icon='fa-solid fa-upload'
										/>
										<span className='choose-file'> Choose A File </span>
									</div>
								</label>
								<p className='selected-file'>File: {image.name} </p>
								<input
									type='file'
									accept='.pdf, image/png, image/jpg, image/jpeg'
									onChange={handleFileUploadChange}
									className='input-file'
									id='file'
								/>
							</div>
							<Form className='pb-5 pt-2'>
								<div className='row'>
									<div className='col-12 col-lg-4 pt-2'>
										<Form.Label>Image Height (in)</Form.Label>
										<Form.Control
											type='text'
											placeholder='24'
											name='userEnteredHeight'
											onChange={handleUserEnteredHeightChange}
										/>
									</div>
									<div className='col-6 col-lg-4 pt-2'>
										<Form.Label>Image Scale</Form.Label>
										<Form.Select
											name='scaleFactor'
											value={userEnteredScale}
											onChange={handleScaleFactorChange}>
											<optgroup label='Scale Factor'>
												{scaleFactorDropDown}
											</optgroup>
										</Form.Select>
									</div>
									<div className='col-6 col-lg-4 pt-2'>
										<Form.Label>Roof's Slope</Form.Label>
										<Form.Select
											required
											name='slopeFactor'
											onChange={handleSlopeFactorChange}>
											<optgroup label='Roof Slope'>
												{slopeFactorDropDown}
											</optgroup>
										</Form.Select>
									</div>
								</div>
							</Form>
							<button
								type='submit'
								style={{
									cursor:
										image.name === 'No File Chosen' || disableBtn
											? 'not-allowed'
											: 'pointer',
								}}
								disabled={image.name === 'No File Chosen' || disableBtn}
								className='button'
								onClick={uploadImage}>
								<div
									className={`${spinner} spinner-border`}
									role='status'></div>
								{analyzing}
							</button>
							<p
								className='mt-3 mb-0'
								style={{ color: failedAnalyze ? 'red' : '#003087' }}>
								{errorMsg}
							</p>
						</div>
					</Col> */}
					{clientSecret && (
						<Elements options={options} stripe={props.stripe}>
							<StripeCheckout
								projectInfoInput={projectInfoInput}
								installComplexity={installComplexity}
								userId={props.userId}
								userInput={userInput}
								handleChange={handleChange}
								handleProjectInfoChange={handleProjectInfoChange}
								handleTearOffChange={handleTearOffChange}
								handleCurrentRoofChange={handleCurrentRoofChange}
								handleNewDeckChange={handleNewDeckChange}
								handleDesiredRoofChange={handleDesiredRoofChange}
								handleDesiredUnderlaymentChange={
									handleDesiredUnderlaymentChange
								}
								//handleNumberOfLayerChange={handleNumberOfLayerChange}
								//handlePermitChange={handlePermitChange}
								handleNewDeckMaterialChange={handleNewDeckMaterialChange}
								handleGuttersChange={handleGuttersChange}
								handleInstallComplexityChange={handleInstallComplexityChange}
								handleRoofingNailsChange={handleRoofingNailsChange}
								handleRidgeCoilNailsChange={handleRidgeCoilNailsChange}
								handlePlasticCapsChange={handlePlasticCapsChange}
								handleRidgeNailsChange={handleRidgeNailsChange}
								handleStarterMaterialChange={handleStarterMaterialChange}
								handleLumberMaterialChange={handleLumberMaterialChange}
								handleRoofingVentsMaterialChange={
									handleRoofingVentsMaterialChange
								}
								handleIntakeVentsMaterialChange={
									handleIntakeVentsMaterialChange
								}
								handleValleysMaterialChange={handleValleysMaterialChange}
								handlePaintMaterialChange={handlePaintMaterialChange}
								handleHipAndRidgeMaterialChange={
									handleHipAndRidgeMaterialChange
								}
								handleCaulkMaterialChange={handleCaulkMaterialChange}
								handleNosingMaterialChange={handleNosingMaterialChange}
								handleMiscFlashingsMaterialChange={
									handleMiscFlashingsMaterialChange
								}
								handlePipeJacksAndAssembliesMaterialChange={
									handlePipeJacksAndAssembliesMaterialChange
								}
								handleSaddlesMaterialChange={handleSaddlesMaterialChange}
								addMiscFlashing={addMiscFlashing}
								addChimneyFlashing={addChimneyFlashing}
								addSkylightFlashing={addSkylightFlashing}
								addValleyMeasurement={addValleyMeasurement}
								addSaddleMeasurement={addSaddleMeasurement}
								addPipeJacksAndAssemblies={addPipeJacksAndAssemblies}
								addCustomTools={addCustomTools}
								removeCustomItems={removeCustomItems}
								miscFlashingList={miscFlashingList}
								chimneyFlashingList={chimneyFlashingList}
								skylightFlashingList={skylightFlashingList}
								valleyMeasurementList={valleyMeasurementList}
								saddleMeasurementList={saddleMeasurementList}
								pipeJacksList={pipeJacksList}
								customToolsList={customToolsList}
								asphaltShinglesDropdown={asphaltShinglesDropdown}
								onePieceConcreteTilesDropdown={onePieceConcreteTilesDropdown}
								usClayTilesDropdown={usClayTilesDropdown}
								underlaymentsDropdown={underlaymentsDropdown}
								coilNailsDropdown={coilNailsDropdown}
								plasticCapsDropdown={plasticCapsDropdown}
								stripNailsDropdown={stripNailsDropdown}
								caulkDropdown={caulkDropdown}
								paintDropdown={paintDropdown}
								startersDropdown={startersDropdown}
								roofingVentsDropdown={roofingVentsDropdown}
								intakeVentsDropdown={intakeVentsDropdown}
								hipAndRidgeDropdown={hipAndRidgeDropdown}
								deckingDropdown={deckingDropdown}
								lumberDropdown={lumberDropdown}
								valleysDropdown={valleysDropdown}
								nosingDropdown={nosingDropdown}
								miscFlashingDropdown={miscFlashingDropdown}
								pipeJacksAndAssembliesDropdown={pipeJacksAndAssembliesDropdown}
								saddlesDropdown={saddlesDropdown}
								displayLumberMaterial={displayLumberMaterial}
								displayStarterMaterial={displayStarterMaterial}
								displayDeckMaterial={displayDeckMaterial}
								paymentBox={paymentBox}
								setPaymentBox={setPaymentBox}
								handlePaymentReady={handlePaymentReady}
								postPayment={postPayment}
								showAddToast={showAddToast}
								setShowAddToast={setShowAddToast}
								handleAddToast={handleAddToast}
								showDeleteToast={showDeleteToast}
								handleDeleteToast={handleDeleteToast}
								paymentId={paymentId}
							/>
						</Elements>
					)}
					<Row
						style={{
							display: `${agreementMessage}`,
							maxWidth: '600px',
							margin: '20px auto',
						}}>
						<h3 className='agreement-message'>Agreement Notice </h3>
						<p className='agreement-message'>
							The estimation cost will be $20.00 per project. By clicking the
							button "Pay & Receive Reports" you are agreed to pay the final
							amount in addition to our Terms & Conditions which can be found{' '}
							<Link to='/'> Here</Link>
						</p>
					</Row>
					{/* <Col
						lg={{ span: 6, offset: 3 }}
						className='payment-modal-box'
						style={{ display: `${modalBox}` }}>
						<div className='payment-success-box'>
							<h2 className='payment-success'> Payment successful!</h2>
							<div className='payment-success-check'>
								<h3 className='payment-check-mark'>
									<FontAwesomeIcon icon='circle-check' />
								</h3>
							</div>
							<p> Thank you for your payment. The total amount was </p>
							<h3>${totalChargeAmount}</h3>
							<p> Transaction ID: {paymentId}</p>
							<p> Close this box and download your reports below</p>
							<button className='general-button' onClick={handleModalBox}>
								Close
							</button>
						</div>
					</Col> */}
					{report}
					{pdfReport}
					{materialPricingPDF}
					{pdfDetailReport}
				</div>
			</div>
		);
	}
}

export default Main;
