import React, { useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import { Link, Redirect } from 'react-router-dom';
import { APIURL } from '../config';
const ForgotPassword = () => {
	const [email, setEmail] = useState('');
	const [errMessage, setErrorMessage] = useState('');
	const [isRedirect, setIsRedirect] = useState(false);
	const [redirectLink, setRedirectLink] = useState('');
	const [modalBox, setModalBox] = useState('none');
	const [errModalBox, setErrModalBox] = useState('none');

	const handleEmailChange = (event) => {
		setEmail(event.target.value);
	};
	const handleModalBox = () => {
		setModalBox('none');
		setErrModalBox('none');
		setRedirectLink('/sign-in');
		setIsRedirect(true);
	};
	const Validation = () => {
		const emailInput = email.split('');
		const emailString = email.toLowerCase();
		const dotCom = '.com';
		const dotNet = '.net';
		const dotOrg = '.org';
		const dotGov = '.gov';
		const dotAi = '.ai';
		const dotIo = '.io';
		const dotMain = '.main';
		if (
			!emailInput.includes('@') ||
			(!emailString.includes(dotCom) &&
				!emailString.includes(dotNet) &&
				!emailString.includes(dotOrg) &&
				!emailString.includes(dotGov) &&
				!emailString.includes(dotAi) &&
				!emailString.includes(dotIo) &&
				!emailString.includes(dotMain))
		) {
			return false;
		} else {
			return true;
		}
	};

	const handleSubmit = async () => {
		if (email === '') {
			setErrorMessage('Email is required');
		} else if (email !== '' && !Validation()) {
			setErrorMessage('*Please verify email*');
		} else if (email !== '' && Validation()) {
			await fetch(`${APIURL}/users/passwordreseturl`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				credentials: 'include',
				body: JSON.stringify({ email: email }),
			})
				.then((response) => response.json())
				.then((message) => {
					if (message === 'Request received') {
						setModalBox('block');
					} else if (message === 'No user found') {
						setErrModalBox('block');
					}
				})
				.catch((error) => console.log('error:', error));
		}
	};
	if (isRedirect && redirectLink) {
		return <Redirect to={redirectLink} />;
	}
	return (
		<>
			<Col className='password-reset'>
				<p>
					Enter your email address and we'll send you email instructions on how
					to reset your password
				</p>
				<input
					className='password-reset-input'
					type='email'
					name='email'
					value={email}
					placeholder='Enter your email'
					onChange={handleEmailChange}
					style={{ padding: '0px 10px' }}
				/>
				<div style={{ marginTop: '20px' }}>
					<button
						onClick={handleSubmit}
						className='button'
						style={{ height: '40px' }}>
						Submit
					</button>
				</div>
				<Link to='/sign-in'>
					<p style={{ marginTop: '20px' }}>Login with credential </p>
				</Link>
				<span className='not-signin-message'>{errMessage}</span>
			</Col>
			<Col
				sm={{ span: 5, offset: 3 }}
				className='general-modal'
				style={{ display: `${modalBox}` }}>
				<p>
					Your request has been received! Please check your email for further
					instruction.
				</p>
				<p> Close this box to be redirected to login page</p>
				<Button className='general-bootstrap-btn' onClick={handleModalBox}>
					Close
				</Button>
			</Col>
			<Col
				sm={{ span: 5, offset: 3 }}
				className='general-modal'
				style={{ display: `${errModalBox}` }}>
				<p>
					Our record showed that there was no account associated with this email
					<span style={{ color: '#0d6efd' }}> {email}</span>. If you believed
					this was a error, please contact us at
					<a href='mailto:main@opticost.ai'> main.opticost.ai</a>
				</p>
				<p> Close this box to be redirected to login page</p>
				<Button
					size='lg'
					className='general-bootstrap-btn'
					onClick={handleModalBox}>
					Close
				</Button>
			</Col>
		</>
	);
};

export default ForgotPassword;
