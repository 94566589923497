import profileImage from '../src/opticost logo.svg';

export const APIURL = 'https://opticostbackend.herokuapp.com';

//export const APIURL = 'http://localhost:4000';
//export const BEURL = 'http://localhost:4000';
export const defaultProfile = profileImage;

export const projectDataConfig = {
	customer: 'TK Nguyen',
	created_date: '06-22-22',
	phone: '4082282228',
	job_number: '46111230',
	job_description: 'Redo Roof',
};
export const projectDetailConfig = {
	enteredChimneyFlashingAllowance: '1.0',
	enteredCurrentRoofType: 'Landmark Solaris',
	enteredDesiredRoofType: 'Presidential TL Solaris',
	enteredDesiredUnderlayment: '30# Felt',
	enteredEave: '100.00',
	enteredHip: '100.00',
	enteredInstallComplexity: '1.10',
	enteredLaborRate: '65.50',
	enteredNewDeck: '7/16 OSB',
	enteredNumberOfLayer: '',
	enteredOverheadPercentage: '10.00',
	enteredPermit: '',
	enteredProfitPercentage: '7.25',
	enteredRake: '100.00',
	enteredRidge: '100.00',
	enteredRoofPitch: 0,
	enteredSkylightFlashingAllowance: '1.0',
	enteredSquareFootage: '2500.00',
	enteredValley: '100.00',
	enteredWastePercentage: '5.00',
	estimatedBaseProjectCost: '22369.38',
	estimatedDisposalCost: '281.25',
	estimatedGrandTotal: '26390.28',
	estimatedLaborCost: '8617.36',
	estimatedMiscAllowance: '875.00',
	estimatedRoofingNails: '875.00',
	estimatedNewChimneyCost: '581',
	estimatedChimneyMaterialQuantity: '1 Unit',
	estimatedNewChimneyLaborCost: '144.10',
	estimatedNewChimneyLaborHour: '2.20',
	estimatedNewDeckCost: '4159.76',
	estimatedDeckMaterialQuantity: '83 Sheets',
	estimatedNewDeckLaborCost: '1895.42',
	estimatedNewDeckLaborHour: '28.94',
	estimatedNewDownspoutCost: '867.58',
	estimatedDownspoutMaterialQuantity: '6 Units',
	estimatedNewDownspoutLaborCost: '81.88',
	estimatedNewDownspoutLaborHour: '1.25',
	estimatedNewEaveCost: '198.09',
	estimatedEaveMaterialQuantity: '11 Pieces',
	estimatedNewEaveLaborCost: '72.05',
	estimatedNewEaveLaborHour: '1.10',
	estimatedNewGutterCost: '1211.31',
	estimatedGutterMaterialQuantity: '105 LF',
	estimatedNewGutterLaborCost: '65.50',
	estimatedNewGutterLaborHour: '1.00',
	estimatedNewHipCost: '417.44',
	estimatedHipMaterialQuantity: '10 Boxes',
	estimatedHipAndRidgeCost: '$180.12',
	estimatedNewHipLaborCost: '90.06',
	estimatedNewHipLaborHour: '1.15',
	estimatedNewLumberCost: '0.00',
	estimatedLumberMaterialQuantity: '0 Pieces',
	estimatedNewLumberLaborCost: '0.00',
	estimatedNewLumberLaborHour: '0.00',
	estimatedNewRakeCost: '198.09',
	estimatedRakeMaterialQuantity: '11 Pieces',
	estimatedNewRakeLaborCost: '72.05',
	estimatedNewRakeLaborHour: '1.10',
	estimatedNewRidgeCost: '417.44',
	estimatedNewRidgeLaborCost: '90.06',
	estimatedNewRidgeLaborHour: '1.15',
	estimatedNewRoofCost: '8414.78',
	estimatedRoofMaterialQuantity: '74 Bundles',
	estimatedNewRoofLaborCost: '3242.25',
	estimatedNewRoofLaborHour: '49.50',
	estimatedNewSkylightCost: '471.48',
	estimatedSkylightMaterialQuantity: '1 Unit',
	estimatedNewSkylightLaborCost: '144.10',
	estimatedNewSkylightLaborHour: '2.20',
	estimatedNewStarterShingleCost: '481.71',
	estimatedStarterShingleMaterialQuantity: '8 Rolls',
	estimatedNewStarterShingleLaborCost: '216.15',
	estimatedNewStarterShingleLaborHour: '2.20',
	estimatedNewUnderlaymentCost: '908.64',
	estimatedUnderlaymentMaterialQuantity: '14 Rolls',
	estimatedNewUnderlaymentLaborCost: '450.31',
	estimatedNewUnderlaymentLaborHour: '6.88',
	estimatedNewValleyCost: '450.18',
	estimatedValleyMaterialQuantity: '11 Pieces',
	estimatedNewValleyLaborCost: '90.06',
	estimatedNewValleyLaborHour: '1.38',
	estimatedNewVentCost: '554.96',
	estimatedVentMaterialQuantity: '9 Vents',
	estimatedNewVentLaborCost: '162.11',
	estimatedNewVentLaborHour: '2.48',
	estimatedOverhead: '2236.94',
	estimatedProjectCostWithOverhead: '24606.32',
	estimatedProjectProfit: '1783.96',
	estimatedRawMaterialCost: '13470.78',
	estimatedTearOff: '1801.25',
	estimatedTearOffHour: '25.00',
	estimatedTotalLaborCost: '8617.36',
	estimatedTotalLaborHour: '131.10',
	estimatedTotalProjectCost: '26390.28',
	isNewDeck: 'YES',
	isNewGutters: 'YES',
	isTearOff: 'YES',
};
