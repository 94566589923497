import React, { useReducer } from 'react';
import { Row, Col, Image, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { APIURL } from '../config';
import { Redirect, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import teamImg from '../images/TeamImg.png';
function SignUp() {
	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			firstname: '',
			lastname: '',
			email: '',
			company: '',
			phone: '',
		}
	);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [passwordError, setPasswordError] = useState('');
	const [password, setPassword] = useState('');
	const [enteredPassword, setEnteredPassword] = useState('');
	const [spinner, setSpinner] = useState('not_loading');
	const [redirect, setRedirect] = useState(false);
	const [modalBox, setModalBox] = useState('none');
	const [capsLockMessage, setCapsLockMessage] = useState('');
	const handleChange = (event) => {
		const name = event.target.name;
		const newValue = event.target.value;
		setUserInput({ [name]: newValue });
	};
	const handlePasswordChange = (event) => {
		setPassword(event.target.value);
	};
	const handleEnteredPasswordChange = (event) => {
		setEnteredPassword(event.target.value);
	};
	const handleModalBox = () => {
		setModalBox('block');
		setRedirect(true);
	};
	const checkCapsLock = (event) => {
		if (event.getModifierState('CapsLock')) {
			setCapsLockMessage('Caps Lock is on!');
		} else {
			setCapsLockMessage('');
		}
	};
	const Validation = async () => {
		const email = userInput.email.split('');
		const emailString = userInput.email.toLowerCase();
		const dotCom = '.com';
		const dotNet = '.net';
		const dotOrg = '.org';
		const dotGov = '.gov';
		const dotAi = '.ai';
		const dotIo = '.io';
		const dotMain = '.main';
		if (!email.includes('@')) {
			setEmailError('*Please enter Valid Email*');
			return;
		} else if (
			!emailString.includes(dotCom) &&
			!emailString.includes(dotNet) &&
			!emailString.includes(dotOrg) &&
			!emailString.includes(dotGov) &&
			!emailString.includes(dotAi) &&
			!emailString.includes(dotIo) &&
			!emailString.includes(dotMain)
		) {
			setEmailError('*Please Enter Valid Email*');
			return;
		} else if (userInput.firstname.match(/^[0-9]+$/)) {
			setFirstNameError('First Name Cannot Contain Numbers');
			return;
		} else if (userInput.lastname.match(/^[0-9]+$/)) {
			setLastNameError('Last Name Cannot Contain Numbers');
			return;
		} else if (password !== enteredPassword) {
			setPasswordError('Password Not Matching');
			return;
		} else {
			await fetch(
				`${APIURL}/users`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
					body: JSON.stringify({
						first_name: userInput.firstname,
						last_name: userInput.lastname,
						email: userInput.email,
						company: userInput.company,
						phone: userInput.phone,
						password: password,
						account_type: 'user',
					}),
				},
				setSpinner('loading')
			)
				.then((res) => res.json())
				.then((res) => {
					if (res === 'user created') {
						setModalBox('block');
						setSpinner('not-loading');
					} else {
						console.log('internal server error');
					}
				})
				.catch((error) => console.log(error));
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		Validation();
	};
	if (redirect === true) {
		return <Redirect to='/sign-in' />;
	}
	return (
		<>
			<div className='signup-section'>
				<div
					className='payment-modal-box'
					style={{ display: `${modalBox}`, maxHeight: '380px' }}>
					<div className='payment-success-box'>
						<h2 className='payment-success' style={{ marginTop: '50px' }}>
							Account Created!
						</h2>
						<div className='payment-success-check'>
							<h3 className='payment-check-mark'>
								<FontAwesomeIcon icon='circle-check' />
							</h3>
						</div>
						<p style={{ padding: '10px' }}>
							Your account has been successfully created, you will get a
							confirmation email shortly. <br></br> You can start using your
							account now
						</p>
						<p> Close this box to be redirected to sign-in page</p>
						<button className='general-button' onClick={handleModalBox}>
							Close
						</button>
					</div>
				</div>
				<Row style={{ marginTop: '100px' }}>
					<Col className='image-col' sm={true}>
						<Image fluid src={teamImg} />
					</Col>
					<Col className='signup-signin-box'>
						<Row style={{ marginBottom: '50px' }}>
							<Col style={{ textAlign: 'right' }}>
								<span>Have an account?</span>
								<Link to='/sign-in'>
									<span style={{ marginLeft: '5px' }}>Sign In</span>
								</Link>
							</Col>
						</Row>
						<Row>
							<h3 style={{ fontSize: '40px', fontWeight: '700' }}>
								Get Started
							</h3>
							<p>
								Please fill out following information, * indicates a required
								field
							</p>
						</Row>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Col className='label-input-clearance'>
									<Form.Label>First Name*</Form.Label>
									<Form.Control
										type='text'
										name='firstname'
										value={userInput.firstname}
										onChange={handleChange}
										required
									/>
									<div className='signup-error-message'>
										<span>{firstNameError}</span>
									</div>
								</Col>
								<Col className='label-input-clearance'>
									<Form.Label>Last Name*</Form.Label>
									<Form.Control
										type='text'
										name='lastname'
										value={userInput.lastname}
										onChange={handleChange}
										required
									/>
									<div className='signup-error-message'>
										<span>{lastNameError}</span>
									</div>
								</Col>
							</Row>
							<Col className='label-input-clearance'>
								<Form.Label>Email*</Form.Label>
								<Form.Control
									type='text'
									name='email'
									value={userInput.email}
									onChange={handleChange}
									required
								/>
								<div className='signup-error-message'>
									<span>{emailError}</span>
								</div>
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label>Password*</Form.Label>
								<Form.Control
									onKeyUp={checkCapsLock}
									type='password'
									name='password'
									onChange={handlePasswordChange}
									required
								/>
								{capsLockMessage && (
									<span style={{ color: 'red' }}>{capsLockMessage}</span>
								)}
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label>Re-Enter Password*</Form.Label>
								<Form.Control
									onKeyUp={checkCapsLock}
									type='password'
									name='enteredPassword'
									onChange={handleEnteredPasswordChange}
									required
								/>
								{capsLockMessage && (
									<span style={{ color: 'red' }}>{capsLockMessage}</span>
								)}
								<div className='signup-error-message'>
									<span>{passwordError}</span>
								</div>
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label>Company*</Form.Label>
								<Form.Control
									type='text'
									name='company'
									value={userInput.company}
									onChange={handleChange}
									required
								/>
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label>Phone*</Form.Label>
								<Form.Control
									type='text'
									name='phone'
									value={userInput.phone}
									onChange={handleChange}
									required
								/>
							</Col>
							<div className='d-grid gap-2'>
								<Button type='submit' size='lg' className='signin-button'>
									Sign Up
								</Button>
							</div>
							<div className='signup-agreement'>
								<p>
									By Signing Up you agree to receive emails from us (Opticost).
									We will use your provided information to further improve our
									services. Email us at main@opticost.ai to opt out
								</p>
							</div>
						</Form>
					</Col>
					<div className='loading-block'> </div>
					<div className={spinner}> </div>
				</Row>
			</div>
		</>
	);
}
export default SignUp;
