import React, { useEffect, useState } from 'react';
import './CanvasStyling.css';
import planwall from '../../images/planfixturebase.jpg';
import planwallcolor from '../../images/planwallcolor.jpg';
import planfixture from '../../images/planfixturecolor.jpg';
import planoutlet from '../../images/planoutletcolor.jpg';

function Canvas() {
	const canvasRef = React.useRef(null);
	const [image, setImage] = useState(planwall);
    const [disableButton, setDisableButton] = useState(false)
    const [viewWidth, setViewWidth] = useState('1210px')
    const [viewHeight,setViewHeight] = useState('650px')
    const [sliderWidth, setSliderWidth] = useState('10')
    const [sliderHeight, setSliderHeight] = useState('640')
	const [componentToRender, setComponentToRender] = useState(
		<div className='calculate-message'>
			<p className='show-message'> Click The Buttons To Begin Scanning</p>
		</div>
	);
    useEffect(()=>{
			if (window.innerWidth <= 1024 && window.innerWidth > 768) {
				setViewWidth('1000px');
				setViewHeight('540 px');
				setSliderHeight('540');
				setSliderWidth('10');
			} else if (window.innerWidth <= 768 && window.innerWidth > 414) {
				setViewWidth('700 px');
				setViewHeight('377 px');
				setSliderHeight('377');
			} else if (window.innerWidth <= 414) {
				setViewWidth('310 px');
				setViewHeight('168 px');
				setSliderHeight('168');
				setSliderWidth('5');
			}
			window.addEventListener(
				'resize',
				() => {
					if (window.screen.width <= 1210 && window.screen.width > 768) {
						setViewWidth('1000px');
						setViewHeight('540 px');
						setSliderHeight('540');
						setSliderWidth('10');
					} else if (window.screen.width <= 768 && window.screen.width > 414) {
						setViewWidth('700 px');
						setViewHeight('377 px');
						setSliderHeight('377');
					} else if (window.screen.width <= 414) {
						setViewWidth('310 px');
						setViewHeight('168 px');
						setSliderHeight('168');
						setSliderWidth('5');
					}
				},
				[]
			);
			//eslint-disable-next-line
		},[])   
	function handleCanvas(event) {
        setDisableButton(true)
        setComponentToRender(
					<div className='calculate-message'>
						<p className='show-message'> Please Wait...</p>
					</div>
				);
		const canvas = canvasRef.current;
		const ctx = canvas.getContext('2d');
		let x = 4;
		let y = 4;
		let speed = 4;
		let isRight = false;
		let buttonClicked = event.target.value;
		function draw() {
            let sliderW = sliderWidth
            let sliderH =sliderHeight
			ctx.clearRect(0, 0, canvas.width, canvas.height);
			ctx.fillStyle = 'skyblue';
			ctx.lineCap = 'round';
			ctx.shadowBlur = 18;
			ctx.shadowColor = '#07C';
			ctx.fillRect(y, x, sliderW, sliderH );
			if (!isRight && y < canvas.width - 14) {y += speed}
			else if (y >= canvas.width - 14) {isRight = true}
			if (isRight && y > 4) y -= speed;
			else if (y === 4) isRight = false;
			if (isRight === true) {
				if (buttonClicked === 'wall') {
					setImage(planwallcolor);
					ctx.clearRect(0, 0, canvas.width, canvas.height);
					setComponentToRender(
						<div className='calculate-message'>
							<p className='message'>
								A.I Found Interior Wall Linear Footage: <span> 147.2 LF</span>
							</p>
							<p className='message'>
								A.I Found Exterior Wall Linear Footage: <span> 192.0 LF </span>
							</p>
							<p className='message'>Total Linear Footage: <span>339.2 LF</span> </p>
							<p className='message'>Interior Wall Area: <span> 3891.2 SqFt</span> </p>
							<p className='message'>Exterior Wall Area: <span> 1920.0 SqFt</span> </p>
							<p className='message'>Baseboard Trim: <span>486.4 Ft</span> </p>
							<p className='message'>Door Trim: <span>90.0 Ft</span> </p>
							<p className='message'>Stucco: <span>1920.0 SqFt</span> </p>
							<p className='message'>Drywall: <span>5689.2 SqFt</span> </p>
							<p className='message'>Paint: <span>7609.2 SqFt</span> </p>
						</div>
					);
                    setDisableButton(false)
					return;
				} else if (buttonClicked === 'fixture') {
					setImage(planfixture);
					ctx.clearRect(0, 0, canvas.width, canvas.height);
                    setComponentToRender(
						<div className='calculate-message'>
							<p className='message'>
								A.I Found Ceiling Fixture (Recessed): <span> 33 Places </span>
							</p>
							<p className='message'>
								A.I Found Ceiling Fixture (Surface Mounted): <span> 9 Places </span>
							</p>
						</div>
					);
                    setDisableButton(false);
					return;
				} else if (buttonClicked === 'outlet') {
					setImage(planoutlet);
					ctx.clearRect(0, 0, canvas.width, canvas.height);
                    setComponentToRender(
						<div className='calculate-message'>
							<p className='message'>
								A.I Found Duplex Outlet +12" U.N.O: <span> 13 Places </span>
							</p>
						</div>
					);
                    setDisableButton(false);
					return;
				}
			} else if (isRight === false) {
				setImage(planwall);
			}

			requestAnimationFrame(draw);
		}
		draw();
	}
	return (
		<div >
			<div
				className='canvas-container'
				style={{ backgroundImage: `url(${image})` }}>
				<canvas
					className='canvas'
					ref={canvasRef}
                    width={viewWidth}
                    height={viewHeight}
                    ></canvas>
			</div>
			<div className='buttons-container'>
				<button onClick={handleCanvas} value='wall' disabled={disableButton} className='analyze-button'>
					Scan For Walls
				</button>
				<button onClick={handleCanvas} value='fixture' disabled={disableButton} className='analyze-button'>
					Scan For Fixtures
				</button>
				<button onClick={handleCanvas} value='outlet' disabled={disableButton} className='analyze-button'>
					Scan For Outlets
				</button>
			</div>
			<div>{componentToRender}</div>
		</div>
	);
}

export default Canvas;
