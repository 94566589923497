export const slopeFactor = [
	{ scale: 1.0, name: 'Slope 1.0/12' },
	{ scale: 1.5, name: 'Slope 1.5/12' },
	{ scale: 2.0, name: 'Slope 2.0/12' },
	{ scale: 2.5, name: 'Slope 2.5/12' },
	{ scale: 3.0, name: 'Slope 3.0/12' },
	{ scale: 3.5, name: 'Slope 3.5/12' },
	{ scale: 4.0, name: 'Slope 4.0/12' },
	{ scale: 4.5, name: 'Slope 4.5/12' },
	{ scale: 5.0, name: 'Slope 5.0/12' },
	{ scale: 5.5, name: 'Slope 5.5/12' },
	{ scale: 6.0, name: 'Slope 6.0/12' },
	{ scale: 6.5, name: 'Slope 6.5/12' },
	{ scale: 7.0, name: 'Slope 7.0/12' },
	{ scale: 7.5, name: 'Slope 7.5/12' },
	{ scale: 8.0, name: 'Slope 8.0/12' },
	{ scale: 8.5, name: 'Slope 8.5/12' },
	{ scale: 9.0, name: 'Slope 9.0/12' },
	{ scale: 9.5, name: 'Slope 9.5/12' },
	{ scale: 10.0, name: 'Slope 10.0/12' },
	{ scale: 10.5, name: 'Slope 10.5/12' },
	{ scale: 11.0, name: 'Slope 11.0/12' },
	{ scale: 11.5, name: 'Slope 11.5/12' },
	{ scale: 12.0, name: 'Slope 12.0/12' },
	{ scale: 12.5, name: 'Slope 12.5/12' },
	{ scale: 13.0, name: 'Slope 13.0/12' },
	{ scale: 13.5, name: 'Slope 13.5/12' },
	{ scale: 14.0, name: 'Slope 14.0/12' },
	{ scale: 14.5, name: 'Slope 14.5/12' },
	{ scale: 15.0, name: 'Slope 15.0/12' },
	{ scale: 15.5, name: 'Slope 15.5/12' },
	{ scale: 16.0, name: 'Slope 16.0/12' },
	{ scale: 16.5, name: 'Slope 16.5/12' },
	{ scale: 17.0, name: 'Slope 17.0/12' },
	{ scale: 17.5, name: 'Slope 17.5/12' },
	{ scale: 18.0, name: 'Slope 18.0/12' },
];
