import React, { useState, useEffect } from 'react';
import {
	PaymentElement,
	useStripe,
	useElements,
} from '@stripe/react-stripe-js';
import { Row, Col, Form, Toast } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
function StripeCheckout({
	projectInfoInput,
	installComplexity,
	userId,
	userInput,
	handleChange,
	handleProjectInfoChange,
	handleTearOffChange,
	handleCurrentRoofChange,
	handleNewDeckChange,
	handleDesiredRoofChange,
	handleDesiredUnderlaymentChange,
	//handleNumberOfLayerChange,
	//handlePermitChange,
	handleNewDeckMaterialChange,
	handleGuttersChange,
	handleInstallComplexityChange,
	handleRoofingNailsChange,
	handleRidgeCoilNailsChange,
	handlePlasticCapsChange,
	handleRidgeNailsChange,
	handleStarterMaterialChange,
	handleLumberMaterialChange,
	handleRoofingVentsMaterialChange,
	handleIntakeVentsMaterialChange,
	handleHipAndRidgeMaterialChange,
	handleValleysMaterialChange,
	handlePaintMaterialChange,
	handleCaulkMaterialChange,
	handleNosingMaterialChange,
	handleMiscFlashingsMaterialChange,
	handlePipeJacksAndAssembliesMaterialChange,
	handleSaddlesMaterialChange,
	addMiscFlashing,
	addChimneyFlashing,
	addSkylightFlashing,
	addValleyMeasurement,
	addSaddleMeasurement,
	addPipeJacksAndAssemblies,
	addCustomTools,
	removeCustomItems,
	miscFlashingList,
	chimneyFlashingList,
	skylightFlashingList,
	valleyMeasurementList,
	saddleMeasurementList,
	pipeJacksList,
	customToolsList,
	asphaltShinglesDropdown,
	onePieceConcreteTilesDropdown,
	usClayTilesDropdown,
	underlaymentsDropdown,
	coilNailsDropdown,
	plasticCapsDropdown,
	stripNailsDropdown,
	caulkDropdown,
	paintDropdown,
	startersDropdown,
	roofingVentsDropdown,
	intakeVentsDropdown,
	hipAndRidgeDropdown,
	deckingDropdown,
	lumberDropdown,
	valleysDropdown,
	nosingDropdown,
	miscFlashingDropdown,
	pipeJacksAndAssembliesDropdown,
	saddlesDropdown,
	displayLumberMaterial,
	displayStarterMaterial,
	displayDeckMaterial,
	paymentBox,
	setPaymentBox,
	handlePaymentReady,
	postPayment,
	showAddToast,
	showDeleteToast,
	handleAddToast,
	handleDeleteToast,
	paymentId,
}) {
	const stripe = useStripe();
	const elements = useElements();
	const [message, setMessage] = useState(null);
	const [messageType, setMessageType] = useState('');
	const [isStripeLoading, setIsStripeLoading] = useState(false);
	const [displayButton, setDisplayButton] = useState('none');
	//const [displayElement, setDisplayElement] = useState('none');
	const secretUser = process.env.REACT_APP_SECRET;
	const checkUser = () => {
		if (userId === secretUser) {
			setDisplayButton('block');
		}
	};
	useEffect(() => {
		checkUser();
		//eslint-disable-next-line
	}, []);

	const handleFormSubmit = async (event) => {
		event.preventDefault();
		if (userId === secretUser) {
			postPayment();
		} else if (userId !== secretUser) {
			if (!stripe || !elements) {
				// Stripe.js has not yet loaded.
				// Make sure to disable form submission until Stripe.js has loaded.
				return;
			}
			await handlePaymentReady();
			setIsStripeLoading(true);
			if (handlePaymentReady) {
				const error = await stripe
					.confirmPayment({
						elements,
						// confirmParams: {
						// 	// Make sure to change this to your payment completion page
						// 	return_url: '',
						// },
						redirect: 'if_required',
					})
					.then((result) => {
						if (result.error) {
							setMessageType('payment-message-failed');
							setMessage(`An unexpected error occured, ${result.error.code}`);
						} else if (result.paymentIntent.status === 'succeeded') {
							postPayment();
							setPaymentBox('none');
							setMessageType('payment-message-success');
							setMessage(
								`Payment Success! Amount charged: $20.00. Download your report below. Transaction ID: ${paymentId}`
							);
						}
					});
				// This point will only be reached if there is an immediate error when
				// confirming the payment. Otherwise, your customer will be redirected to
				// your `return_url`. For some payment methods like iDEAL, your customer will
				// be redirected to an intermediate site first to authorize the payment, then
				// redirected to the `return_url`.
				if (error) {
					setMessageType('payment-message-failed');
					if (
						error.type === 'card_error' ||
						error.type === 'validation_error'
					) {
						setMessage(error.message);
					} else {
						setMessage('An unexpected error occured.');
					}
				}
				setIsStripeLoading(false);
			}
		}
	};

	return (
		<>
			<Row className='toast-row'>
				<Col>
					<Toast
						className='toast-add'
						onClose={handleAddToast}
						show={showAddToast}
						delay={1500}
						autohide>
						<Toast.Header className='toast-header'>
							<strong className='me-auto'>New Item Added</strong>
						</Toast.Header>
						<Toast.Body>New Customize Item Added</Toast.Body>
					</Toast>
					<Toast
						className='toast-remove'
						onClose={handleDeleteToast}
						show={showDeleteToast}
						delay={1500}
						autohide>
						<Toast.Header className='toast-header'>
							<strong className='me-auto'>New Item Removed</strong>
						</Toast.Header>
						<Toast.Body>Customize Item Removed</Toast.Body>
					</Toast>
				</Col>
			</Row>
			{/* <div style={{marginTop:'30px'}}>
				<h2 className='title'>ROOF ESTIMATOR </h2>
			</div> */}
			<Form
				encType='multipart/form-data'
				onSubmit={handleFormSubmit}
				className='mt-3'>
				<Row className='field-set'>
					<p className='legend'>
						<span className='legend-text'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-lines'
							/>
							PROJECT INFORMATION
						</span>
					</p>
					<Col lg={{ span: 3, offset: 2 }}>
						<div className='input-wrapper'>
							<Form.Label>Customer Name</Form.Label>
							<Form.Control
								type='text'
								name='customerName'
								className={
									projectInfoInput.customerName ? 'form-control-focus' : ''
								}
								placeholder='Customer Name'
								value={projectInfoInput.customerName}
								onChange={handleProjectInfoChange}
							/>
						</div>
						<div className='input-wrapper'>
							<Form.Label>Phone Number</Form.Label>
							<Form.Control
								type='text'
								name='projectPhone'
								className={
									projectInfoInput.projectPhone ? 'form-control-focus' : ''
								}
								value={projectInfoInput.projectPhone}
								placeholder='Phone #'
								onChange={handleProjectInfoChange}></Form.Control>
						</div>
						<div className='input-wrapper'>
							<Form.Label>Project Address</Form.Label>
							<Form.Control
								type='text'
								name='projectAddress'
								value={projectInfoInput.projectAddress}
								className={
									projectInfoInput.projectAddress ? 'form-control-focus' : ''
								}
								placeholder='Address'
								onChange={handleProjectInfoChange}></Form.Control>
						</div>
					</Col>
					<Col lg={{ span: 3, offset: 2 }}>
						<div className='input-wrapper'>
							<Form.Label>Date</Form.Label>
							<Form.Control
								type='date'
								name='projectDate'
								value={projectInfoInput.projectDate}
								className={
									projectInfoInput.projectDate ? 'form-control-focus' : ''
								}
								placeholder='Date'
								onChange={handleProjectInfoChange}></Form.Control>
						</div>
						<div className='input-wrapper'>
							<Form.Label>Job Number </Form.Label>
							<Form.Control
								type='text'
								name='jobNumber'
								value={projectInfoInput.jobNumber}
								className={
									projectInfoInput.jobNumber ? 'form-control-focus' : ''
								}
								placeholder='Job #'
								onChange={handleProjectInfoChange}></Form.Control>
						</div>
						<div className='input-wrapper'>
							<Form.Label>Job Description </Form.Label>
							<Form.Control
								type='text'
								name='jobDescription'
								value={projectInfoInput.jobDescription}
								className={
									projectInfoInput.jobDescription ? 'form-control-focus' : ''
								}
								placeholder='Job Description'
								onChange={handleProjectInfoChange}></Form.Control>
						</div>
					</Col>
				</Row>
				<Row className='field-set'>
					<p className='legend'>
						<span className='legend-text'>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-pen'
							/>
							PROJECT INPUTS
						</span>
					</p>
					<Row
						style={{
							margin: '0 auto',
							paddingBottom: '10px',
							borderBottom: '1px dotted rgb(0, 35, 148)',
						}}
						className=''>
						<Col lg={4} className=''>
							<div className='input-wrapper'>
								<Form.Label>Tear Off:</Form.Label>
								<label className='radio-button-label'>
									Yes
									<input
										className='radio-button'
										type='radio'
										name='tearOff'
										value='YES'
										required
										onChange={handleTearOffChange}></input>
								</label>
								<label className='radio-button-label'>
									No
									<input
										className='radio-button'
										type='radio'
										name='tearOff'
										value='NO'
										required
										onChange={handleTearOffChange}></input>
								</label>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Total Estimated Square Footage</Form.Label>
								<p className='error-input'>
									{userInput.totalSquareFootageInputError}
								</p>
								<Form.Control
									type='text'
									name='squareFootage'
									pattern='[0-9, .]*'
									placeholder='eg: 2500'
									className={
										userInput.squareFootage ? 'form-control-focus' : ''
									}
									value={userInput.squareFootage}
									required
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Current Roof Type (s)</Form.Label>
								<Form.Select
									onChange={handleCurrentRoofChange}
									id='current-roof'>
									<optgroup label='Asphalt Composition Shingles'>
										{asphaltShinglesDropdown}
									</optgroup>
									<optgroup label='1 Piece Concrete Tile'>
										{onePieceConcreteTilesDropdown}
									</optgroup>
									<optgroup label='US Clay Tiles'>
										{usClayTilesDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Desired Roof Type (s)</Form.Label>
								<Form.Select
									onChange={handleDesiredRoofChange}
									id='desire-roof'>
									<optgroup label='Asphalt Composition Shingles'>
										{asphaltShinglesDropdown}
									</optgroup>
									<optgroup label='1 Piece Concrete Tile'>
										{onePieceConcreteTilesDropdown}
									</optgroup>
									<optgroup label='US Clay Tiles'>
										{usClayTilesDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Desired Underlayment (s)</Form.Label>
								<Form.Select
									onChange={handleDesiredUnderlaymentChange}
									name='Desired Roof'
									required
									id='desire-underlayment'>
									<optgroup label='Style'>{underlaymentsDropdown}</optgroup>
								</Form.Select>
							</div>
							{/* <Form.Label>
								<label>Number Of Layers</label>
							</Form.Label>
							<select
								onChange={handleNumberOfLayerChange}
								className=''>
								<option value={1}>Level 1</option>
								<option value={2}>Level 2</option>
								<option value={3}>Level 3</option>
								<option value={4}>Level 4</option>
								<option value={5}>Level 5</option>
							</select> */}
							<div className='input-wrapper'>
								<Form.Label>Coil Nails (For Roofing)</Form.Label>
								<Form.Select
									onChange={handleRoofingNailsChange}
									name='Roof Nail'
									id='roof-nail'>
									<optgroup label='Type | Size'>{coilNailsDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Coil Nails (For Ridge)</Form.Label>
								<Form.Select
									onChange={handleRidgeCoilNailsChange}
									name='Ridge Nail'
									id='ridge-coil-nail'>
									<optgroup label='Type | Size'>{coilNailsDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Plastic Caps (For Underlayment)</Form.Label>
								<Form.Select
									onChange={handlePlasticCapsChange}
									name='Plastic Cap'
									id='plastic-cap'>
									<optgroup label='Type | Size'>{plasticCapsDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label style={{ display: `${displayStarterMaterial}` }}>
									Starter Materials (For Shingles)
								</Form.Label>
								<Form.Select
									style={{ display: `${displayStarterMaterial}` }}
									onChange={handleStarterMaterialChange}
									id='starter-material'
									name='Starter'>
									<optgroup label='Presidential roofs suggest to use Presidential Starter'>
										{startersDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label style={{ display: `${displayLumberMaterial}` }}>
									Lumber (For Tiles)
								</Form.Label>
								<Form.Select
									style={{ display: `${displayLumberMaterial}` }}
									onChange={handleLumberMaterialChange}
									id='lumber'
									name='Lumber'>
									<optgroup label='Style | Thickness'>
										{lumberDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Misc Flashings Materials</Form.Label>
								<Form.Select
									onChange={handleMiscFlashingsMaterialChange}
									id='misc-flashing-material'
									name='Misc Flashings'>
									<optgroup label='Type | Size | Detail'>
										{miscFlashingDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<Row className='input-wrapper'>
								<Form.Label>Misc Flashing Measurements</Form.Label>
								<p className='error-input'>
									{userInput.miscFlashingInputError}
								</p>
								<Col lg={5} xs={5}>
									<Form.Control
										placeholder='Quantity'
										type='text'
										name='miscFlashingQuantity'
										id='misc-flashing-qty'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={5} xs={5}>
									<Form.Control
										placeholder='Hr'
										type='text'
										name='miscFlashingHour'
										id='misc-flashing-hr'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addMiscFlashing}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
							<Row className='input-wrapper'>
								<Form.Label>Chimney Flashing Allowance</Form.Label>
								<p className='error-input'>
									{userInput.chimneyFlashingInputError}
								</p>
								<Col lg={5} className='description-input'>
									<Form.Control
										placeholder='Description'
										type='text'
										name='chimneysDescription'
										id='chimney-desc'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={3} xs={5}>
									<Form.Control
										placeholder='Cost'
										type='text'
										name='chimneysCost'
										id='chimney-cost'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={5}>
									<Form.Control
										placeholder='Hr'
										type='text'
										name='chimneysHour'
										id='chimney-hr'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addChimneyFlashing}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
							<Row className='input-wrapper'>
								<Form.Label>Skylight Flashing Allowance</Form.Label>
								<p className='error-input'>
									{userInput.skylightFlashingInputError}
								</p>
								<Col lg={5} className='description-input'>
									<Form.Control
										placeholder='Description'
										type='text'
										name='skylightsDescription'
										id='skylight-desc'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={3} xs={5}>
									<Form.Control
										placeholder='Cost'
										type='text'
										name='skylightsCost'
										id='skylight-cost'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={5}>
									<Form.Control
										placeholder='Hr'
										type='text'
										name='skylightsHour'
										id='skylight-hr'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addSkylightFlashing}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
						</Col>
						<Col lg={4}>
							<div className='input-wrapper'>
								<Form.Label>New Deck</Form.Label>
								<label className='radio-button-label'>
									Yes
									<input
										className='radio-button'
										type='radio'
										name='newDeck'
										value='YES'
										required
										onChange={handleNewDeckChange}></input>
								</label>
								<label className='radio-button-label'>
									No
									<input
										className='radio-button'
										type='radio'
										name='newDeck'
										value='NO'
										required
										onChange={handleNewDeckChange}></input>
								</label>
							</div>
							<div className='input-wrapper'>
								<Form.Label style={{ display: `${displayDeckMaterial}` }}>
									New Deck Materials
								</Form.Label>
								<Form.Select
									style={{ display: `${displayDeckMaterial}` }}
									onChange={handleNewDeckMaterialChange}
									id='new-deck-material'>
									<optgroup label='Style | Thickness | Type'>
										{deckingDropdown}
									</optgroup>
								</Form.Select>
							</div>
							{/* <Form.Label>Permit </Form.Label>
							<input
								className=''
								type='text'
								name='permit'
								//value={userInput.permit}
								placeholder='City'
								onChange={handlePermitChange}></input> */}
							<div className='input-wrapper'>
								<Form.Label>Ridge (LF)</Form.Label>
								<p className='error-input'>{userInput.ridgeInputError}</p>
								<Form.Control
									type='text'
									name='ridge'
									pattern='[0-9, .]*'
									placeholder='Ex: 100'
									value={userInput.ridge}
									className={userInput.ridge ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Hip (LF) </Form.Label>
								<p className='error-input'>{userInput.hipInputError}</p>
								<Form.Control
									type='text'
									name='hip'
									pattern='[0-9, .]*'
									placeholder='Ex: 100'
									value={userInput.hip}
									className={userInput.hip ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Ridge & Hip Materials</Form.Label>
								<Form.Select
									name='RidgeAndHip'
									onChange={handleHipAndRidgeMaterialChange}
									id='hip-ridge-material'>
									<optgroup label='Type'>{hipAndRidgeDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Strip Nails (For Deck)</Form.Label>
								<Form.Select
									onChange={handleRidgeNailsChange}
									name='Ridge Nail'
									id='ridge-nail'>
									<optgroup label='Type | Size'>{stripNailsDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Eave (LF) </Form.Label>
								<p className='error-input'>{userInput.eaveInputError}</p>
								<Form.Control
									type='text'
									name='eave'
									pattern='[0-9, .]*'
									placeholder='Ex: 100'
									value={userInput.eave}
									className={userInput.eave ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Rake (LF) </Form.Label>
								<p className='error-input'>{userInput.rakeInputError}</p>
								<Form.Control
									type='text'
									name='rake'
									pattern='[0-9, .]*'
									placeholder='Ex: 150'
									className={userInput.rake ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Nosing Materials</Form.Label>
								<Form.Select
									onChange={handleNosingMaterialChange}
									name='Nosing'
									id='nosing-material'>
									<optgroup label='Type | Size'>{nosingDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Valley Materials </Form.Label>
								<Form.Select
									onChange={handleValleysMaterialChange}
									id='valley-material'
									name='Valley'
									className=''>
									<optgroup label='Type | Size | Color'>
										{valleysDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<Row className='input-wrapper'>
								<Form.Label>Valley Measurements </Form.Label>
								<p className='error-input'>{userInput.valleyInputError}</p>
								<Col lg={10} xs={10}>
									<Form.Control
										placeholder='Measurement of Each Piece (LF)'
										type='text'
										name='valleyMeasurement'
										value={userInput.valleyMeasurement}
										className={
											userInput.valleyMeasurement ? 'form-control-focus' : ''
										}
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addValleyMeasurement}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
							<div className='input-wrapper'>
								<Form.Label>Saddles Materials</Form.Label>
								<Form.Select
									onChange={handleSaddlesMaterialChange}
									id='saddle-material'
									name='Saddles'>
									<optgroup label='Type | Size'>{saddlesDropdown}</optgroup>
								</Form.Select>
							</div>
							<Row className='input-wrapper'>
								<Form.Label>Saddles Measurements</Form.Label>
								<p className='error-input'>{userInput.saddleInputError}</p>
								<Col lg={5} xs={5}>
									<Form.Control
										placeholder='Quantity'
										type='text'
										name='saddleQuantity'
										id='saddle-qty'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={5} xs={5}>
									<Form.Control
										placeholder='Hr'
										type='text'
										name='saddleHour'
										id='saddle-hr'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addSaddleMeasurement}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
							<Row className='input-wrapper'>
								<Form.Label>Additional Tools & Cost </Form.Label>
								<p className='error-input'>{userInput.otherToolsInputError}</p>
								<Col lg={5} className='description-input'>
									<Form.Control
										placeholder='Description'
										type='text'
										name='otherToolsDescription'
										id='tool-desc'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={3} xs={5}>
									<Form.Control
										placeholder='Cost'
										type='text'
										name='otherToolsCost'
										id='tool-cost'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={5}>
									<Form.Control
										placeholder='Qty'
										type='text'
										name='otherToolsQuantity'
										id='tool-qty'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addCustomTools}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
						</Col>
						<Col lg={4}>
							<div className='input-wrapper'>
								<Form.Label>New Gutter</Form.Label>
								<label className='radio-button-label'>
									Yes
									<input
										className='radio-button'
										type='radio'
										name='gutters'
										value='YES'
										required
										onChange={handleGuttersChange}></input>
								</label>
								<label className='radio-button-label'>
									No
									<input
										className='radio-button'
										type='radio'
										name='gutters'
										value='NO'
										required
										onChange={handleGuttersChange}></input>
								</label>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Vent Materials</Form.Label>
								<Form.Select
									onChange={handleRoofingVentsMaterialChange}
									id='roofing-vent'
									name='Roofing Vent'>
									<optgroup label='Style | Size'>
										{roofingVentsDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Intake Vents (Optional)</Form.Label>
								<Form.Select
									onChange={handleIntakeVentsMaterialChange}
									id='intake-vent'
									name='Intake Vent'>
									<optgroup label='Style | Size'>
										{intakeVentsDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Pipe Jacks & Assemblies</Form.Label>
								<Form.Select
									onChange={handlePipeJacksAndAssembliesMaterialChange}
									id='pipe-jack'
									name='Pipe Jack'>
									<optgroup label='Style | Size'>
										{pipeJacksAndAssembliesDropdown}
									</optgroup>
								</Form.Select>
							</div>
							<Row className='input-wrapper'>
								<Form.Label>Pipe Jacks & Assemblies Quantity </Form.Label>
								<p className='error-input'>{userInput.pipeJacksInputError}</p>
								<Col lg={5} xs={5}>
									<Form.Control
										placeholder='Quantity'
										type='text'
										name='pipeJacksQuantity'
										id='pipe-jack-qty'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={5} xs={5}>
									<Form.Control
										placeholder='Hr'
										type='text'
										name='pipeJacksHour'
										id='pipe-jack-hr'
										onChange={handleChange}></Form.Control>
								</Col>
								<Col lg={2} xs={2}>
									<span>
										<FontAwesomeIcon
											onClick={addPipeJacksAndAssemblies}
											className='font-awesome add-icon'
											icon='fa-solid fa-square-plus'
										/>
									</span>
								</Col>
							</Row>
							<div className='input-wrapper'>
								<Form.Label>Caulk Materials</Form.Label>
								<Form.Select
									onChange={handleCaulkMaterialChange}
									name='Caulk'
									id='caulk-material'>
									<optgroup label='Style | Color'>{caulkDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Paint Materials</Form.Label>
								<Form.Select
									onChange={handlePaintMaterialChange}
									name='Paint'
									id='paint-material'>
									<optgroup label='Type | Color'>{paintDropdown}</optgroup>
								</Form.Select>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Waste (%) </Form.Label>
								<p className='error-input'>{userInput.wasteInputError}</p>
								<Form.Control
									type='text'
									name='waste'
									//value={userInput.waste}
									pattern='[0-9, .]*'
									placeholder='0-50'
									className={userInput.waste ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Overhead (%) </Form.Label>
								<p className='error-input'>{userInput.overheadInputError}</p>
								<Form.Control
									type='text'
									name='overhead'
									pattern='[0-9, .]*'
									placeholder='Ex: 15'
									className={userInput.overhead ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Profit (%) </Form.Label>
								<p className='error-input'>{userInput.profitInputError}</p>
								<Form.Control
									type='text'
									name='profit'
									pattern='[0-9, .]*'
									placeholder='Ex: 15'
									className={userInput.profit ? 'form-control-focus' : ''}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>Hourly Labor Rate </Form.Label>
								<p className='error-input'>{userInput.laborInputError}</p>
								<Form.Control
									type='text'
									name='externalHourlyCost'
									pattern='[0-9, .]*'
									placeholder='Ex: 70'
									required
									className={
										userInput.externalHourlyCost ? 'form-control-focus' : ''
									}
									onChange={handleChange}></Form.Control>
							</div>
							<div className='input-wrapper'>
								<Form.Label>
									Install Complexity <span id='complexity'>(Multiplier x
									{installComplexity.toFixed(1)})</span>
								</Form.Label>
								<div className='range-input'>
									<span>{installComplexity.toFixed(1)}</span>
									<Form.Range
										type='range'
										max='2.5'
										min='1'
										step='0.1'
										onChange={handleInstallComplexityChange}
										defaultValue='1.0'
										name='complexity'
										className=''></Form.Range>
									<span>2.5</span>
								</div>
							</div>
						</Col>
					</Row>
					<Row className='general-list-wrapper'>
						<div>
							<div className='general-list-header'>
								<p>
									<span className='legend-text'>
										<FontAwesomeIcon
											className='font-awesome'
											icon='fa-solid fa-file-pen'
										/>
										CUSTOMIZED ITEMS
									</span>
								</p>
							</div>
							{chimneyFlashingList.length > 0 && (
								<div>
									<div className='general-list-header'>
										Chimney Flashing Allowance List
									</div>
									{chimneyFlashingList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Cost: ${item.cost}</span>
												<span>Labor: {item.hour} Hr</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</div>
						<Row>
							{skylightFlashingList.length > 0 && (
								<div>
									<div className='general-list-header'>
										Skylight Flashing Allowance List
									</div>
									{skylightFlashingList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Cost: ${item.cost}</span>
												<span>Labor: {item.hour} Hr</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</Row>
						<Row>
							{miscFlashingList.length > 0 && (
								<div>
									<div className='general-list-header'>
										Misc Flashing Measurements List
									</div>
									{miscFlashingList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Quantity: {item.quantity}</span>
												<span>Labor: {item.hour} Hr</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</Row>
						<Row>
							{valleyMeasurementList.length > 0 && (
								<div>
									<div className='general-list-header'>
										Valley Measurements List
									</div>
									{valleyMeasurementList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Piece #: {item.quantity}</span>
												<span>Measurement: {item.measurement} LF</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</Row>
						<Row>
							{saddleMeasurementList.length > 0 && (
								<div>
									<div className='general-list-header'>
										Saddle Measurements List
									</div>
									{saddleMeasurementList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Quantity: {item.quantity}</span>
												<span>Labor: {item.hour} Hr</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</Row>
						<Row>
							{pipeJacksList.length > 0 && (
								<div>
									<div className='general-list-header'>
										Pipe Jacks & Assemblies List
									</div>
									{pipeJacksList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Quantity: {item.quantity}</span>
												<span>Labor: {item.hour} Hr</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</Row>
						<Row>
							{customToolsList.length > 0 && (
								<div>
									<div className='general-list-header'>Custom Tools List</div>
									{customToolsList.map((item) => {
										return (
											<div className='general-row' key={item.key}>
												<span>Desc: {item.description}</span>
												<span>Cost: ${item.cost}</span>
												<span>Quantity: {item.quantity}</span>
												<span
													className='link-color3'
													id={item.key}
													onClick={removeCustomItems}>
													Remove Item
												</span>
											</div>
										);
									})}
								</div>
							)}
						</Row>
					</Row>
				</Row>
				<div
					style={{
						display: `${displayButton}`,
						textAlign: `center`,
						marginBottom: '10px',
					}}>
					<button type='submit' id='submit' className='button'>
						<FontAwesomeIcon
							className='font-awesome'
							icon='fa-solid fa-file-pdf'
						/>
						Get Report
					</button>
				</div>
				{userId !== secretUser && (
					<Col sm={{ span: 6, offset: 3 }} className='payment-form'>
						<PaymentElement id='payment-element' />
						<button
							type='submit'
							disabled={isStripeLoading || !stripe || !elements}
							id='submit'
							className='payment-button'
							style={{ display: `${paymentBox}` }}>
							<span id='button-text'>
								{isStripeLoading ? (
									<div className='spinner' id='spinner'></div>
								) : (
									'Pay & Receive Reports'
								)}
							</span>
						</button>
						{/* Show any error or success messages */}
						{message && <div id={messageType}>{message}</div>}
					</Col>
				)}
			</Form>
		</>
	);
}
export default StripeCheckout;
