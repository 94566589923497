import React from 'react';
import {
	Page,
	Text,
	View,
	Document,
	PDFDownloadLink,
	Image,
} from '@react-pdf/renderer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../../opticostlogo3.jpg';
import { pdfRenderStyles, reportFooter, secondPDFPage } from '../PDFtemplate';
function PdfReports({
	projectDataDetail,
	projectData,
	userFullName,
	userEmail,
	userPhone,
}) {
	const styles = pdfRenderStyles;
	const reportHeader = (
		<View style={styles.topDiv}>
			<View style={styles.infoSection}>
				<Text style={styles.info}>
					Customer Name: {projectData.customer ?? ''}
				</Text>
				<Text style={styles.info}>Date: {projectData.created_date ?? ''}</Text>
				<Text style={styles.info}>Address #: {projectData.address ?? ''}</Text>
				{/* <Text style={styles.info}>Phone #: {projectData.phone}</Text> */}
				<Text style={styles.info}>
					Project Number & Description: {projectData.job_number ?? ''} -{' '}
					{projectData.job_description ?? ''}
				</Text>
			</View>
			<View style={styles.infoSection}>
				<Image style={styles.logo} src={logo} />
				<Text style={styles.smallText}>Powered by Opticost</Text>
			</View>
		</View>
	);
	const contractorInfoHeader = (
		<View style={styles.topDiv}>
			<View style={styles.infoSection}>
				<Text style={styles.info}>Contractor Name: {userFullName ?? ''}</Text>
				<Text style={styles.info}>Phone #: {userPhone ?? ''}</Text>
				<Text style={styles.info}>Email: {userEmail ?? ''}</Text>
			</View>
		</View>
	);
	const projectReportInput = (
		<View style={styles.projectReport}>
			<View style={styles.paragraph}>
				<Text>
					Current Roof Material:{' '}
					{projectDataDetail.enteredCurrentRoofType ?? ''}
				</Text>
				<Text>
					Desired Roof Material:{' '}
					{projectDataDetail.enteredDesiredRoofType ?? ''}
				</Text>
				<Text>
					Tear-off Required (Y/N): {projectDataDetail.isTearOff ?? ''}
				</Text>
				<Text>
					New Gutter Required (Y/N): {projectDataDetail.isNewGutters ?? ''}
				</Text>
				<Text>
					New Deck Required (Y/N): {projectDataDetail.isNewDeck ?? ''}
				</Text>
				<Text>
					Desired New Deck Material:
					{' ' + projectDataDetail.enteredNewDeck ?? ''}
				</Text>
				{/* <Text>
					Install Complexity (Multiplier): x
					{projectDataDetail.enteredInstallComplexity}
				</Text> */}
			</View>
			<View style={styles.paragraph}>
				<Text>Ridge (LF): {projectDataDetail.enteredRidge ?? ''} Ft</Text>
				<Text>Rake (LF): {projectDataDetail.enteredRake ?? ''} Ft</Text>
				<Text>Eave (LF): {projectDataDetail.enteredEave ?? ''} Ft</Text>
				<Text>Hip (LF): {projectDataDetail.enteredHip ?? ''} Ft</Text>
				<Text>Valley (LF): {projectDataDetail.enteredValley ?? ''}</Text>
				<Text>
					Total Footage:
					{' ' + projectDataDetail.enteredSquareFootage ?? ''} Sq Ft
				</Text>
				{/* <Text>
					Total Labor Hours:{' '}
					{projectDataDetail.estimatedTotalLaborHour.toLocaleString('en', {
						maximumFractionDigits: 2,
					})}{' '}
					Hrs
				</Text> */}
			</View>
		</View>
	);
	if (!projectDataDetail && !projectData) {
		return (
			<div className='pdf-download-button'>
				<PDFDownloadLink
					style={{ color: 'black', fontWeight: 'bold' }}
					fileName={`${projectData.customer ?? ''}${
						projectData.created_date ?? ''
					}${projectData.job_description ?? ''}.pdf`}
					document={
						<Document>
							<Page size='A4' style={styles.page}>
								<View>
									<Text>Error Loading Pdf</Text>
								</View>
							</Page>
						</Document>
					}>
					<FontAwesomeIcon
						className='font-awesome'
						icon='fa-solid fa-file-arrow-down'
					/>
					Loading
				</PDFDownloadLink>
			</div>
		);
	}
	if (projectDataDetail && projectData) {
		return (
			<>
				<div style={{ margin: '15px auto' }}>
					<div className='pdf-download-button'>
						<PDFDownloadLink
							style={{ color: 'black', fontWeight: 'bold' }}
							fileName={`${projectData.customer ?? ''}-${
								projectData.created_date ?? ''
							}-Customer-Quote.pdf`}
							document={
								<Document>
									<Page size='A4' style={styles.page}>
										<View style={styles.pageLayout}>
											{contractorInfoHeader}
											{reportHeader}
											<View style={styles.middleDiv}>
												{/* <View style={styles.mainTitle}>
													<Text style={styles.title}>Project Report</Text>
												</View>
												{projectReportInput} */}
												<View style={styles.mainTitle}>
													<Text style={styles.title}>Proposal</Text>
												</View>
												<View style={styles.tableHeaderContainer}>
													<View style={styles.leftHeader}>
														<Text>Item </Text>
													</View>
													<View style={styles.middleHeader}>
														<Text>Description </Text>
													</View>
													<View style={styles.rightHeader}>
														<Text> Total </Text>
													</View>
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>A</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Tear off and Disposal Existing "
															{projectDataDetail.enteredCurrentRoofType ?? ''}"
															Roof
														</Text>
														<View style={styles.tableBody}>
															<Text>
																- Additional discovered layer of "
																{projectDataDetail.enteredCurrentRoofType ?? ''}
																" will be removed and disposed with an added
																rate (at the time of discovery) per square feet
																per layer
															</Text>
															<Text>
																- Include Pre-tearoff site preparation,
																in-progress cleaning and Post-tearoff cleaning
															</Text>
														</View>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															$
															{projectDataDetail.quotedTearOffAndDisposal ?? ''}
														</Text>
													</View>
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>B</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															New Deck Installation - Main House
														</Text>
														<View style={styles.tableBody}>
															<Text>
																- New Desired Deck Material:
																{' ' + projectDataDetail.enteredNewDeck ?? ''}
															</Text>
														</View>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															$
															{projectDataDetail.quotedNewDeckInstallation ??
																''}
														</Text>
													</View>
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>C</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															New Gutter and Downspout - Main House
														</Text>
														<View style={styles.tableBody}>
															<Text>
																- New Gutter and Downspout Material:
																{' ' +
																	(projectDataDetail.enteredDesiredRoofType ??
																	'')}
															</Text>
														</View>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															$
															{projectDataDetail.quotedGutterAndDownspout ?? ''}
														</Text>
													</View>
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>D</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															New Complete Roof Installation - Main House
														</Text>
														<View style={styles.tableBody}>
															<Text>
																- New Desired Roof Material:
																{' ' +
																	(projectDataDetail.enteredDesiredRoofType ??
																	'')}
															</Text>
															<Text>
																- New Desired Underlayment Material:
																{projectDataDetail.enteredDesiredUnderlayment ??
																	''}
															</Text>
															<Text>
																*Includes the installation of all roof metals,
																including skylight flashings, except solar
																flashings
															</Text>
														</View>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															$
															{projectDataDetail.quotedNewRoofInstallation ??
																''}
														</Text>
													</View>
												</View>
												<Text
													style={{
														textAlign: 'center',
														fontSize: 13,
														textDecoration: 'underline',
														marginTop: 15,
													}}>
													Proposal Total: $
													{projectDataDetail.estimatedTotalProjectCost ?? ''}
												</Text>
												{secondPDFPage}
											</View>
											{reportFooter}
										</View>
										{/* <Text
											style={styles.pageNumber}
											render={({ pageNumber, totalPages }) =>
												`${pageNumber} of ${totalPages}`
											}
											fixed></Text> */}
									</Page>
									{/* <Page size='A4' style={styles.page}>
										<View style={styles.pageLayout}>
											{reportHeader}
											{secondPDFPage}
											{reportFooter}
										</View>
										<Text
											style={styles.pageNumber}
											render={({ pageNumber, totalPages }) =>
												`${pageNumber} of ${totalPages}`
											}
											fixed></Text>
									</Page> */}
								</Document>
							}>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-arrow-down'
							/>
							Customer Quote
						</PDFDownloadLink>
					</div>
				</div>
				<div style={{ margin: '15px auto' }}>
					<div className='pdf-download-button'>
						<PDFDownloadLink
							style={{ color: 'black', fontWeight: 'bold' }}
							fileName={`${projectData.customer ?? ''}-${
								projectData.created_date ?? ''
							}-Material-Pricing.pdf`}
							document={
								<Document>
									<Page size='A4' style={styles.page}>
										<View style={styles.pageLayout}>
											{reportHeader}
											<View style={styles.middleDiv}>
												<View style={styles.mainTitle}>
													<Text style={styles.title}>Project Report</Text>
												</View>
												{projectReportInput}
												<View style={styles.mainTitle}>
													<Text style={styles.title}>Materials List</Text>
												</View>
												<View style={styles.tableHeaderContainer}>
													<View style={styles.leftHeader}>
														<Text>Item </Text>
													</View>
													<View style={styles.middleHeader}>
														<Text>Description </Text>
													</View>
													<View style={styles.rightHeader}>
														<Text> Quantity </Text>
													</View>
													{/* <View style={styles.rightHeader}>
														<Text> Total </Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>01</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Roofing Material:
															{' ' + (projectDataDetail.enteredDesiredRoofType ??
																'')}
															-{projectDataDetail.enteredTotalFootage ?? ''}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedRoofMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedNewRoofCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>02</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Underlayment:
															{' ' +
																(projectDataDetail.enteredDesiredUnderlayment ??
																'')}
															-{projectDataDetail.enteredTotalFootage ?? ''}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedUnderlaymentMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' +
																projectDataDetail.estimatedNewUnderlaymentCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>03</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Deck Material:
															{' ' + (projectDataDetail.enteredNewDeck ?? '')}-
															{projectDataDetail.enteredTotalFootage ?? ''}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedDeckMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedNewDeckCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>04</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Starter Rolls (For Shingle Roof):
															{' ' + (projectDataDetail.enteredStarterMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedStarterShingleMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' +
																projectDataDetail.estimatedStarterShingleCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>05</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Lumber (For Concrete Roof):
															{' ' + (projectDataDetail.enteredLumberMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedLumberMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedLumberCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>06</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Roof Ridge Box:
															{' ' +
																(projectDataDetail.enteredRoofRidgeMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedHipMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedHipAndRidgeCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>07</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Valley Pieces:
															{' ' + (projectDataDetail.enteredValleyMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedValleyMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedValleyCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>08</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Rake Nosing:
															{' ' + (projectDataDetail.enteredNosingMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedRakeMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedRakeCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>09</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Eave Nosing:
															{' ' + (projectDataDetail.enteredNosingMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedEaveMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedEaveCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>10</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Gutters
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedGutterMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedGutterCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>11</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Downspout
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedDownspoutMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedDownspoutCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>12</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Ridge Vent:
															{' ' +
																(projectDataDetail.enteredRidgeVentMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedHipMaterialQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedVentCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>13</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Intake Vent:
															{' ' +
																(projectDataDetail.enteredIntakeVentMaterial ??
																'')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedIntakeVentsQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedIntakeVentsCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>14</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Chimney Flashing:
															{' ' +
															(projectDataDetail.enteredChimneyFlashingList
																? projectDataDetail.enteredChimneyFlashingList.map(
																		(list) => list.description + ', '
																  )
																: '')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{
																projectDataDetail.estimatedChimneyMaterialQuantity ?? ''
															}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedChimneyCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>15</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Skylight Flashing:
															{' ' +
															(projectDataDetail.enteredSkylightFlashingList
																? projectDataDetail.enteredSkylightFlashingList.map(
																		(list) => list.description + ', '
																  )
																: '')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{
																projectDataDetail.estimatedSkylightMaterialQuantity ?? ''
															}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedSkylightCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>16</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Misc Flashing:
															{' ' + (projectDataDetail.enteredMiscFlashingList
																? projectDataDetail.enteredMiscFlashingList.map(
																		(list) => list.description + ', '
																  )
																: '')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedMiscFlashingQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' +
																projectDataDetail.estimatedMiscFlashingCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>17</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Saddles:
															{' ' + (projectDataDetail.enteredSaddleMaterial
																? projectDataDetail.enteredSaddleMaterial.map(
																		(list) => list.description + ', '
																  )
																: '')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedSaddlesQuantity
																? projectDataDetail.estimatedSaddlesQuantity +
																  ' Unit(s)'
																: ''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedSaddlesCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>18</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Pipe Jacks & Assemblies:
															{' ' + (projectDataDetail.enteredPipeJacks
																? projectDataDetail.enteredPipeJacks.map(
																		(list) => list.description + ', '
																  )
																: '')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedSaddlesQuantity
																? projectDataDetail.estimatedSaddlesQuantity +
																  ' Unit(s)'
																: ''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedPipeJacksCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>19</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Custom Tool(s):
															{' ' + (projectDataDetail.enteredCustomToolsList
																? projectDataDetail.enteredCustomToolsList.map(
																		(list) => list.description + ', '
																  )
																: '')}
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{projectDataDetail.estimatedCustomToolsQuantity ??
																''}
														</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedCustomToolsCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>20</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>
															Caulk:{' '}
															{projectDataDetail.enteredCaulkMaterial ?? ''}{' '}
															{projectDataDetail.estimatedCaulkQuantity ?? ''}{' '}
															Tube(s) & Paint:{' '}
															{projectDataDetail.enteredPaintMaterial ?? ''} -{' '}
															{projectDataDetail.estimatedPaintQuantity ?? ''}{' '}
															Can(s)
														</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>-</Text>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' +
																projectDataDetail.estimatedCaulkAndPaintCost}
														</Text>
													</View> */}
												</View>
												<View style={styles.tableBodyContainer}>
													<View style={styles.leftColumn}>
														<Text style={styles.itemColumn}>21</Text>
													</View>
													<View style={styles.middleColumn}>
														<Text style={styles.descriptionColumn}>Nails</Text>
														<View style={styles.tableBody}>
															<Text>
																-Coil Nails (Roof):{' '}
																{projectDataDetail.enteredRoofingNailsMaterial ??
																	''}{' '}
																- Quantity:
																{' ' +
																	(projectDataDetail.estimatedRoofingNailQuantity ??
																	'')}
															</Text>
															<Text>
																-Coil Nails (Ridge):{' '}
																{projectDataDetail.enteredRidgeCoilNailsMaterial ??
																	''}{' '}
																- Quantity:
																{' ' +
																	(projectDataDetail.estimateRidgeCoilNailQuantity ??
																	'')}
															</Text>
															<Text>
																-Strip Nails (Deck):{' '}
																{projectDataDetail.enteredRidgeNailMaterial ??
																	''}{' '}
																- Quantity:
																{' ' +
																	(projectDataDetail.estimatedRidgeNailQuantity ??
																	'')}
															</Text>
															<Text>
																-Plastic Cap (Underlayment):{' '}
																{projectDataDetail.enteredPlasticCapMaterial ??
																	''}{' '}
																- Quantity:
																{' ' +
																	(projectDataDetail.estimatedPlasticCapQuantity ??
																	'')}
															</Text>
														</View>
													</View>
													{/* <View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>-</Text>
													</View>
													<View style={styles.rightColumn}>
														<Text style={styles.priceColumn}>
															{'$' + projectDataDetail.estimatedNailsCost}
														</Text>
													</View> */}
												</View>
												{/* <Text
													style={{
														textAlign: 'center',
														fontSize: 13,
														textDecoration: 'underline',
														marginTop: 8,
													}}>
													Total Material Cost:
													{' $' + projectDataDetail.estimatedRawMaterialCost}
												</Text> */}
											</View>
											{reportFooter}
										</View>
										<Text
											style={styles.pageNumber}
											render={({ pageNumber, totalPages }) =>
												`${pageNumber} of ${totalPages}`
											}
											fixed></Text>
									</Page>
								</Document>
							}>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-arrow-down'
							/>
							Material Pricing
						</PDFDownloadLink>
					</div>
				</div>
				<div style={{ margin: '15px auto' }}>
					<div className='pdf-download-button'>
						<PDFDownloadLink
							style={{ color: 'black', fontWeight: 'bold' }}
							fileName={`${projectData.customer ?? ''}-${
								projectData.created_date ?? ''
							}-Detail-Report.pdf`}
							document={
								<Document>
									<Page size='A4' style={styles.page}>
										<View style={styles.pageLayout}>
											{reportHeader}
											<View style={styles.middleDiv}>
												<View style={styles.mainTitle}>
													<Text style={styles.title}>Project Input</Text>
												</View>
												{projectReportInput}
												<View style={styles.mainTitle}>
													<Text style={styles.title}>Detail Report</Text>
												</View>
												<View style={styles.projectReport}>
													<View style={styles.paragraph}>
														<Text>
															Base Project Cost:
															{' $' +
																(projectDataDetail.estimatedBaseProjectCost ??
																'')}
														</Text>
														<Text>
															Estimated Overhead:
															{' $' + (projectDataDetail.estimatedOverhead ?? '')}
														</Text>
													</View>
													<View style={styles.paragraph}>
														<Text>
															Total (With Overhead):
															{' $' +
																(projectDataDetail.estimatedProjectCostWithOverhead ??
																'')}
														</Text>
														<Text>
															Estimated Profit:
															{' $' +
																(projectDataDetail.estimatedProjectProfit ?? '')}
														</Text>
													</View>
													<View style={styles.paragraph}>
														<Text>
															Grand Total:
															{' $' +
																(projectDataDetail.estimatedTotalProjectCost ??
																'')}
														</Text>
													</View>
												</View>
												<View style={styles.projectReport}>
													<View style={styles.paragraph}>
														<Text style={styles.boldText}>Description</Text>
														<Text>Tear Off</Text>
														<Text>Disposal</Text>
														<Text style={styles.highlight}>Removal</Text>
														<Text>Primary Roof</Text>
														<Text>Pipe Jacks/Assemblies</Text>
														<Text>Underlayment</Text>
														<Text>Starter</Text>
														<Text>Ridge</Text>
														<Text>Hip</Text>
														<Text style={styles.highlight}>
															Core Installation
														</Text>
														<Text>Roofing Vent</Text>
														<Text>Intake Vent</Text>
														<Text>Rake</Text>
														<Text>Eave</Text>
														<Text>Valley</Text>
														<Text>Saddle Flashings</Text>
														<Text>Misc Flashings</Text>
														<Text style={styles.highlight}>Metal & Vents</Text>
														<Text>Gutters</Text>
														<Text>Downspouts</Text>
														<Text>Skylight & Sun Tunnels</Text>
														<Text>Chimneys</Text>
														<Text style={styles.highlight}>Options</Text>
														<Text>Deck</Text>
														<Text>Lumber</Text>
														<Text style={styles.highlight}>Wood</Text>
														<Text>Nails</Text>
														<Text>Caulk</Text>
														<Text>Paint</Text>
														<Text>Misc Tools & Activities</Text>
														<Text style={styles.highlight}>Misc Item</Text>
														<Text style={styles.highlightTotal}>
															Project Total
														</Text>
													</View>
													<View style={styles.paragraph}>
														<Text style={styles.boldText}>Material Cost</Text>
														<Text>-</Text>
														<Text>
															${projectDataDetail.estimatedDisposalCost ?? '-'}
														</Text>
														{/* Removal Cost */}
														<Text style={styles.highlight}>
															${projectDataDetail.estimatedDisposalCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedNewRoofCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedPipeJacksCost ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedNewUnderlaymentCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedStarterShingleCost ??
																'-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedRidgeCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedHipCost ?? '-'}
														</Text>
														{/* Core Installation Material Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedNewRoofCost
																) +
																parseFloat(
																	projectDataDetail.estimatedPipeJacksCost
																) +
																parseFloat(
																	projectDataDetail.estimatedNewUnderlaymentCost
																) +
																parseFloat(
																	projectDataDetail.estimatedStarterShingleCost
																) +
																parseFloat(
																	projectDataDetail.estimatedRidgeCost
																) +
																parseFloat(projectDataDetail.estimatedHipCost)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedVentCost ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedIntakeVentsCost ??
																'-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedRakeCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedEaveCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedValleyCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedSaddlesCost ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedMiscFlashingCost ??
																'-'}
														</Text>
														{/* Metal & Vents Material Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedVentCost
																) +
																parseFloat(
																	projectDataDetail.estimatedIntakeVentsCost
																) +
																parseFloat(
																	projectDataDetail.estimatedRakeCost
																) +
																parseFloat(
																	projectDataDetail.estimatedEaveCost
																) +
																parseFloat(
																	projectDataDetail.estimatedValleyCost
																) +
																parseFloat(
																	projectDataDetail.estimatedSaddlesCost
																) +
																parseFloat(
																	projectDataDetail.estimatedMiscFlashingCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedGutterCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedDownspoutCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedSkylightCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedChimneyCost ?? '-'}
														</Text>
														{/* Options Material Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedGutterCost
																) +
																parseFloat(
																	projectDataDetail.estimatedDownspoutCost
																) +
																parseFloat(
																	projectDataDetail.estimatedSkylightCost
																) +
																parseFloat(
																	projectDataDetail.estimatedChimneyCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedNewDeckCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedLumberCost ?? '-'}
														</Text>
														{/* Wood Material Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedNewDeckCost
																) +
																parseFloat(
																	projectDataDetail.estimatedLumberCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedNailsCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedCaulkCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedPaintCost ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedCustomToolsCost ??
																'-'}
														</Text>
														{/* Misc Item Material Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedNailsCost
																) +
																parseFloat(
																	projectDataDetail.estimatedCaulkCost
																) +
																parseFloat(
																	projectDataDetail.estimatedPaintCost
																) +
																parseFloat(
																	projectDataDetail.estimatedCustomToolsCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text style={styles.highlightTotal}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedRawMaterialCost
																) +
																parseFloat(
																	projectDataDetail.estimatedDisposalCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
													</View>
													<View style={styles.paragraph}>
														<Text style={styles.boldText}>Labor Cost</Text>
														<Text>
															${projectDataDetail.estimatedTearOff ?? '-'}
														</Text>
														<Text>-</Text>
														{/* Removal Labor */}
														<Text style={styles.highlight}>
															${projectDataDetail.estimatedTearOff ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedNewRoofLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedPipeJacksLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedNewUnderlaymentLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedStarterShingleLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedRidgeLaborCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedHipLaborCost ?? '-'}
														</Text>
														{/* Core Installation Labor Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedNewRoofLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedPipeJacksLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedNewUnderlaymentLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedStarterShingleLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedRidgeLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedHipLaborCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedVentLaborCost ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedIntakeVentsLaborCost ??
																'-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedRakeLaborCost ?? '-'}
														</Text>
														<Text>
															${projectDataDetail.estimatedEaveLaborCost ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedValleyLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedSaddleLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedMiscFlashingLaborCost ??
																'-'}
														</Text>
														{/* Metal & Vent Labor Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedVentLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedIntakeVentsLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedRakeLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedEaveLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedValleyLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedSaddleLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedMiscFlashingLaborCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedGutterLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedDownspoutLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedSkylightLaborCost ??
																'-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedChimneyLaborCost ??
																'-'}
														</Text>
														{/* Options Labor Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedGutterLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedDownspoutLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedSkylightLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedChimneyLaborCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedNewDeckLaborCost ??
																''}
														</Text>
														<Text>
															$
															{projectDataDetail.estimatedLumberLaborCost ??
																'-'}
														</Text>
														{/* Wood Labor Cost */}
														<Text style={styles.highlight}>
															$
															{(
																parseFloat(
																	projectDataDetail.estimatedNewDeckLaborCost
																) +
																parseFloat(
																	projectDataDetail.estimatedLumberLaborCost
																)
															)?.toFixed(2) ?? '-'}
														</Text>
														<Text>-</Text>
														<Text>-</Text>
														<Text>-</Text>
														<Text>-</Text>
														<Text style={styles.highlight}>-</Text>
														<Text style={styles.highlightTotal}>
															${projectDataDetail.estimatedLaborCost ?? '-'}
														</Text>
													</View>
													<View style={styles.paragraph}>
														<Text style={styles.boldText}>Labor Hours</Text>
														<Text>
															{projectDataDetail.estimatedTearOffHour
																? projectDataDetail.estimatedTearOffHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>-</Text>
														<Text style={styles.highlight}>
															{projectDataDetail.estimatedTearOffHour
																? projectDataDetail.estimatedTearOffHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedNewRoofLaborHour
																? projectDataDetail.estimatedNewRoofLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedPipeJacksLaborHour
																? projectDataDetail.estimatedPipeJacksLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedNewUnderlaymentLaborHour
																? projectDataDetail.estimatedNewUnderlaymentLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedStarterShingleLaborHour
																? projectDataDetail.estimatedStarterShingleLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedRidgeLaborHour
																? projectDataDetail.estimatedRidgeLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedHipLaborHour
																? projectDataDetail.estimatedHipLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														{/* Core Installation Hour */}
														<Text style={styles.highlight}>
															{(
																parseFloat(
																	projectDataDetail.estimatedNewRoofLaborHour 
																) +
																parseFloat(
																	projectDataDetail.estimatedPipeJacksLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedNewUnderlaymentLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedStarterShingleLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedRidgeLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedHipLaborHour
																)
															)?.toFixed(2) + ' Hrs' || '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedVentLaborHour
																? projectDataDetail.estimatedVentLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedIntakeVentsLaborHour
																? projectDataDetail.estimatedIntakeVentsLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedRakeLaborHour
																? projectDataDetail.estimatedRakeLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedEaveLaborHour
																? projectDataDetail.estimatedEaveLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedValleyLaborHour
																? projectDataDetail.estimatedValleyLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedSaddleLaborHour
																? projectDataDetail.estimatedSaddleLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedMiscFlashingLaborHour
																? projectDataDetail.estimatedMiscFlashingLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														{/* Metal & Vent Hour */}
														<Text style={styles.highlight}>
															{(
																parseFloat(
																	projectDataDetail.estimatedVentLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedIntakeVentsLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedRakeLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedEaveLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedValleyLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedSaddleLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedMiscFlashingLaborHour
																)
															)?.toFixed(2) + ' Hrs' || '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedGutterLaborHour
																? projectDataDetail.estimatedGutterLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedDownspoutLaborHour
																? projectDataDetail.estimatedGutterLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedSkylightLaborHour
																? projectDataDetail.estimatedSkylightLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedChimneyLaborHour
																? projectDataDetail.estimatedChimneyLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														{/* Options Hour */}
														<Text style={styles.highlight}>
															{(
																parseFloat(
																	projectDataDetail.estimatedGutterLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedDownspoutLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedSkylightLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedChimneyLaborHour
																)
															)?.toFixed(2) + ' Hrs' || '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedNewDeckLaborHour
																? projectDataDetail.estimatedNewDeckLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														<Text>
															{projectDataDetail.estimatedLumberLaborHour
																? projectDataDetail.estimatedLumberLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
														{/* Wood Hour */}
														<Text style={styles.highlight}>
															{(
																parseFloat(
																	projectDataDetail.estimatedNewDeckLaborHour
																) +
																parseFloat(
																	projectDataDetail.estimatedLumberLaborHour
																)
															)?.toFixed(2) + ' Hrs' || '-'}
														</Text>
														<Text>-</Text>
														<Text>-</Text>
														<Text>-</Text>
														<Text>-</Text>
														<Text style={styles.highlight}>-</Text>
														<Text style={styles.highlightTotal}>
															{projectDataDetail.estimatedTotalLaborHour
																? projectDataDetail.estimatedTotalLaborHour +
																  ' Hrs'
																: '-'}
														</Text>
													</View>
												</View>
											</View>
										</View>
									</Page>
								</Document>
							}>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-file-arrow-down'
							/>
							Detail Report
						</PDFDownloadLink>
					</div>
				</div>
			</>
		);
	}
}
export default PdfReports;
