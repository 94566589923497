import React, { useState } from 'react';
import { Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
	faCheckSquare,
	faCircleCheck,
	faFileLines,
	faFilePen,
	faFileArrowDown,
	faFilePdf,
	faFolderOpen,
	faSquarePlus,
	faBolt,
	faRectangleList,
	faUser,
	faUserGroup,
	faXmarkCircle,
	faPhone,
	faEnvelope,
	faCircleMinus,
	faChartLine,
	faLayerGroup,
	faArrowRightFromBracket,
	faAddressBook,
	faCircleLeft,
	faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { loadStripe } from '@stripe/stripe-js';
import { APIURL } from './config';
import Main from './components/Main.jsx';
import TopNav from './components/TopNav';
import Intro from './components/Intro';
//import Slider from './components/Slider';
import Canvas from '../src/components/Canvas/Canvas';
import SignUp from '../src/components/SignUp';
import SignIn from './components/SignIn';
import GetUpdate from './components/GetUpdate';
import ContactUs from './components/ContactUs';
import WallAi from './components/WallAi';
import EstimatedProjects from './components/EstimatedProjects/EstimatedProjects';
import EstimatedProjectDetail from './components/EstimatedProjects/EstimatedProjectDetail';
import ForgotPassword from './components/ForgotPassword';
import ResetPasswordLink from './components/ResetPasswordLink';
// import UserAccount from './components/Accounts/UserAccount';
// import ContractorAccount from './components/Accounts/ContractorAccount';
import Dashboard from './components/Dashboards/Dashboard';
// import ContractorDashboard from './components/Dashboards/ContractorDashboard';
// import ProjectDetail from'./components/Projects/ProjectDetail';
// import ProjectUpdate from './components/Projects/ProjectUpdate';
// import ProjectInvitesContractors from './components/Projects/ProjectInvitesContractors';
// import NewProject from './components/Projects/NewProject';
// import OpenProjects from './components/Projects/OpenProjects';
// import ContractorDetail from './components/Contractors/ContractorDetail';
// import Clients from './components/Clients/Clients'
// import ClientDetail from './components/Clients/ClientDetail';
// import Contractors from './components/Contractors/Contractors';
// import ContractorReview from './components/Contractors/ContractorReview'
// import QuoteDetail from './components/Quotes/QuoteDetail';
import Trial from './components/Trial';
import Footer from './components/Footer';
import './App.css';
library.add(
	fab,
	faCheckSquare,
	faCircleCheck,
	faFileLines,
	faFilePen,
	faFileArrowDown,
	faFilePdf,
	faFolderOpen,
	faSquarePlus,
	faCircleMinus,
	faBolt,
	faRectangleList,
	faUserGroup,
	faUser,
	faXmarkCircle,
	faPhone,
	faEnvelope,
	faChartLine,
	faLayerGroup,
	faArrowRightFromBracket,
	faAddressBook,
	faCircleLeft,
	faUpload
);

const App = () => {
	const [token, setToken] = useState('');
	const [userId, setUserId] = useState('');
	const [account, setAccount] = useState('');
	const [userFullName, setUserFullName] = useState('');
	const [userEmail, setUserEmail] = useState('');
	const [userPhone, setUserPhone] = useState('');
	const [userProfile, setUserProfile] = useState('');
	const [persist, setPersist] = useState(false);
	const [masterMaterialsList, setMasterMaterialsList] = useState([]);
	const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);
	async function handleSignOut() {
		await fetch(`${APIURL}/users/signout`, {
			method: 'GET',
			credentials: 'include',
		})
			.then(
				() => setToken(''),
				localStorage.setItem('persist', false),
				window.location.replace('/')
			)
			.catch((err) => console.log(err));
		//window.location.href = '/';
	}
	return (
		<div>
			<TopNav
				token={token}
				handleSignOut={handleSignOut}
				account={account}></TopNav>
			<Route exact path='/bookademo' component={GetUpdate} />
			<Route exact path='/contactus' component={ContactUs} />
			<Route
				exact
				path='/'
				render={(routerProps) => {
					return <Intro token={token} userFullName={userFullName} />;
				}}
			/>
			{/* <Route exact path='/' component={Slider} /> */}
			<Route
				exact
				path='/aidemo'
				render={(routerProps) => {
					return <Canvas />;
				}}
			/>
			<Route
				exact
				path='/trial'
				render={(routerProps) => {
					return <Trial />;
				}}
			/>

			<Route exact path='/signup' component={SignUp} />
			<Route
				exact
				path='/sign-in'
				render={(routerProps) => {
					return (
						<SignIn
							setToken={setToken}
							token={token}
							persist={persist}
							account={account}
							setUserId={setUserId}
							setAccount={setAccount}
							setUserFullName={setUserFullName}
							setUserEmail={setUserEmail}
							setUserProfile={setUserProfile}
							setUserPhone={setUserPhone}
							setPersist={setPersist}
							setMasterMaterialsList={setMasterMaterialsList}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/forgotpassword'
				render={(routerProps) => {
					return <ForgotPassword token={token} />;
				}}
			/>
			<Route
				exact
				path='/resetpasswordlink/:id/:token'
				render={(routerProps) => {
					return (
						<ResetPasswordLink
							token={token}
							match={routerProps.match}></ResetPasswordLink>
					);
				}}
			/>
			<Route
				exact
				path='/roof-estimator'
				render={(routerProps) => {
					return (
						<Main
							token={token}
							setToken={setToken}
							account={account}
							setAccount={setAccount}
							userId={userId}
							userFullName={userFullName}
							userEmail={userEmail}
							userPhone={userPhone}
							setUserId={setUserId}
							masterMaterialsList={masterMaterialsList}
							stripe={stripePromise}></Main>
					);
				}}
			/>

			<Route
				exact
				path='/artificial-intelligence'
				render={(routerProps) => {
					return <WallAi token={token} />;
				}}
			/>

			<Route
				exact
				path='/estimated-projects'
				render={(routerProps) => {
					return (
						<EstimatedProjects
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							handleSignOut={handleSignOut}
							setToken={setToken}></EstimatedProjects>
					);
				}}
			/>

			<Route
				exact
				path='/estimated-projects/detail/:id'
				render={(routerProps) => {
					return (
						<EstimatedProjectDetail
							token={token}
							userId={userId}
							userFullName={userFullName}
							userEmail={userEmail}
							userPhone={userPhone}
							match={routerProps.match}></EstimatedProjectDetail>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard'
				render={(routerProps) => {
					return (
						<Dashboard
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							handleSignOut={handleSignOut}
						/>
					);
				}}
			/>
			{/* <Route
				exact
				path='/user-dashboard/detail/:id'
				render={(routerProps) => {
					return (
						<UserAccount
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/projects/detail/:id'
				render={(routerProps) => {
					return (
						<ProjectDetail
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/projects/detail/:id/edit'
				render={(routerProps) => {
					return (
						<ProjectUpdate
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/projects/detail/:id/add-contractors'
				render={(routerProps) => {
					return (
						<ProjectInvitesContractors
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/projects/new'
				render={(routerProps) => {
					return (
						<NewProject
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/contractors/detail/:id'
				render={(routerProps) => {
					return (
						<ContractorDetail
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/contractors/detail/:id/review'
				render={(routerProps) => {
					return (
						<ContractorReview
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/user-dashboard/contractors/add'
				render={(routerProps) => {
					return (
						<Contractors
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/quotes/:id/detail'
				render={(routerProps) => {
					return (
						<QuoteDetail
							token={token}
							userId={userId}
							account={account}
							userFullName={userFullName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/> */}
			{/* These Bellow Are Contractor Routes */}
			{/* <Route
				exact
				path='/contractor-dashboard'
				render={(routerProps) => {
					return (
						<ContractorDashboard
							token={token}
							userId={userId}
							account={account}
							userFirstName={userFirstName}
							userLastName={userLastName}
							userProfile={userProfile}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/contractor-dashboard/detail/:id'
				render={(routerProps) => {
					return (
						<ContractorAccount
							token={token}
							userId={userId}
							account={account}
							userFirstName={userFirstName}
							userLastName={userLastName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/contractor-dashboard/projects/detail/:id'
				render={(routerProps) => {
					return (
						<ProjectDetail
							token={token}
							userId={userId}
							account={account}
							userFirstName={userFirstName}
							userLastName={userLastName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/contractor-dashboard/projects/open-projects'
				render={(routerProps) => {
					return (
						<OpenProjects
							token={token}
							userId={userId}
							account={account}
							userFirstName={userFirstName}
							userLastName={userLastName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/contractor-dashboard/clients'
				render={(routerProps) => {
					return (
						<Clients
							token={token}
							userId={userId}
							account={account}
							userFirstName={userFirstName}
							userLastName={userLastName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/>
			<Route
				exact
				path='/contractor-dashboard/clients/detail/:id'
				render={(routerProps) => {
					return (
						<ClientDetail
							token={token}
							userId={userId}
							account={account}
							userFirstName={userFirstName}
							userLastName={userLastName}
							userProfile={userProfile}
							match={routerProps.match}
						/>
					);
				}}
			/> */}

			<Footer></Footer>
		</div>
	);
};

export default App;
