import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Button, Image } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SliderStyle.css';
import { useState } from 'react';
import drag from '../icons/drag.svg';
import HeroShot from '../images/LaptopHeroShot.png';
import Laptop1 from '../images/Laptop-1.png';
import Laptop2 from '../images/Laptop-2.png';
import Laptop3 from '../images/Laptop-3.png';
import Laptop4 from '../images/Laptop-4.png';

function Intro(props) {
	const [sliderValue, setSliderValue] = useState('50');
	function handleSliderValueChange(event) {
		setSliderValue(event.target.value);
	}
	const persistLogin = localStorage.getItem('persist');

	if (!props.token) {
		return (
			<>
				<Container>
					<Row style={{ marginTop: '100px', alignItems: 'center' }}>
						<Col lg={5} className='caption-message-div'>
							<h3 className='caption-message'>
								You'll Never Need To Estimate Again
							</h3>
							<div className='intro-message-div'>
								<p className='intro-message-header'>
									Simple Software Built by Estimators. Faster, Cheaper, and More
									Accurate Than Anyone or Anything
								</p>
							</div>
							<Row>
								<Col style={{ marginTop: '30px' }}>
									<Link
										to='/bookademo'
										className=' btn btn-lg nav-sign-up-button'>
										Book A Demo
									</Link>
									<Link to='/signup' className='btn btn-lg nav-sign-in-button'>
										Get Started
									</Link>
								</Col>
							</Row>
						</Col>
						<Col>
							<Image src={HeroShot} alt='opticost-dashboard' fluid />
						</Col>
					</Row>
					<Row style={{ marginTop: '70px' }}>
						<Col>
							<h3 className='caption-message-center'>
								Construction Takeoffs And Estimates Done In Minutes, Not Days
							</h3>
						</Col>
					</Row>
					<Row lg={3} sm={1} style={{ textAlign: 'center', marginTop: '50px' }}>
						<Col style={{ minWidth: '300px', marginTop: '30px' }}>
							<FontAwesomeIcon
								icon='fa-solid fa-bolt'
								size='3x'
								style={{ color: '#003087' }}
							/>
							<h3
								style={{
									margin: '20px auto',
									fontWeight: '600',
								}}>
								Faster Estimates Powered By Automated AI
							</h3>
							<p>
								Reduce The Time It Takes To Perform Construction Takeoffs.
								Eliminate The Need For Manual Calculation.
							</p>
						</Col>
						<Col style={{ minWidth: '300px', marginTop: '30px' }}>
							<FontAwesomeIcon
								icon='fa-solid fa-rectangle-list'
								size='3x'
								style={{ color: '#003087' }}
							/>
							<h3
								style={{
									margin: '20px auto',
									fontWeight: '600',
								}}>
								Accurate Cost Estimating That Wins Bids
							</h3>
							<p>
								Create Precise Construction Cost Estimated Fast To Secure Bids
								With Complete Confidence.
							</p>
						</Col>
						<Col style={{ minWidth: '300px', marginTop: '30px' }}>
							<FontAwesomeIcon
								icon='fa-solid fa-user-group'
								size='3x'
								style={{ color: '#003087' }}
							/>
							<h3
								style={{
									margin: '20px auto',
									fontWeight: '600',
								}}>
								Build By Experienced Industry Experts, For Everyone
							</h3>
							<p style={{ textAlign: 'center' }}>
								We Are Team Made Of Experienced Estimators And Engineers Making
								Quality Estimating Accessible To All.
							</p>
						</Col>
					</Row>
					{/* SLIDER INTRO */}
					<Row className='general-background-div'>
						<h3
							style={{
								textAlign: 'center',
								marginTop: '70px',
								color: '#616161',
								fontSize: '1.5rem',
							}}>
							Solution
						</h3>
						<h3 className='caption-message-center' style={{ marginTop: '5px' }}>
							Come See How Opticost Does The Estimating For You!
						</h3>
						<p style={{ textAlign: 'center' }}>
							Drag the slider for a glimpse of what our A.I. estimating service
							can do.
						</p>
						<Col>
							<div>
								<div className='slider-container'>
									<div className='background-img'></div>
									<div
										className='foreground-img'
										style={{ width: `${sliderValue}%` }}></div>
									<input
										type='range'
										min='1'
										max='100'
										value={sliderValue}
										className='slider'
										onChange={handleSliderValueChange}></input>
									<img
										src={drag}
										alt='drag icon'
										className='slider-button'
										style={{ left: `calc(${sliderValue}% - 24px)` }}
									/>
								</div>
								{sliderValue >= 86 ? (
									<div className='calculate-message'>
										<p className='show-message' style={{ opacity: '1' }}>
											A.I Found Interior Wall Linear Footage: 147.2 LF
										</p>
										<p className='show-message' style={{ opacity: '1' }}>
											A.I Found Exterior Wall Linear Footage: 192.0 LF
										</p>
									</div>
								) : (
									<div>
										<p className='show-message' style={{ opacity: '0' }}>
											A.I Found Interior Wall Linear Footage: 147.2 LF
										</p>
										<p className='show-message' style={{ opacity: '0' }}>
											A.I Found Exterior Wall Linear Footage: 192.0 LF
										</p>
									</div>
								)}
							</div>
						</Col>
					</Row>
					<Row className='landing-key-points'>
						<Col style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginBottom: '20px' }}>
								<h4
									style={{
										color: '#616161',
										fontSize: '20px',
										fontWeight: '600',
									}}>
									Bid Management
								</h4>
								<h3
									style={{
										fontSize: '36px',
										fontWeight: '600',
										marginTop: '20px',
									}}>
									Keep Everything In One Place.
								</h3>
								<p
									style={{
										fontSize: '20px',
										color: '#616161',
										marginTop: '20px',
									}}>
									Easily View All Project In One Place, With Quick Access To
									Project Documents.
								</p>
								<Link to='/signup'>Get Started</Link>
							</div>
						</Col>
						<Col sm={{ order: 'last' }} xs={{ order: 'first' }}>
							<div>
								<Image
									fluid
									src={Laptop1}
									alt='laptop-1'
									style={{ minWidth: '340px' }}
								/>
							</div>
						</Col>
					</Row>
					<Row className='landing-key-points'>
						<Col style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginBottom: '20px' }}>
								<h4
									style={{
										color: '#616161',
										fontSize: '18px',
										fontWeight: '600',
									}}>
									Construction Takeoff
								</h4>
								<h3
									style={{
										fontSize: '36px',
										fontWeight: '600',
										marginTop: '20px',
									}}>
									Takeoffs In Seconds.
								</h3>
								<p
									style={{
										fontSize: '20px',
										color: '#616161',
										marginTop: '20px',
									}}>
									Go From Blueprints To Detailed Construction Takeoffs In A
									Matter of Seconds! All Done With Our Artificial Intelligence
									Technology!
								</p>
								<Link to='/signup'>Get Started</Link>
							</div>
						</Col>
						<Col sm={{ order: 'first' }} xs={{ order: 'first' }}>
							<div>
								<Image
									fluid
									src={Laptop2}
									alt='laptop-1'
									style={{ minWidth: '340px' }}
								/>
							</div>
						</Col>
					</Row>
					<Row className='landing-key-points'>
						<Col style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginBottom: '20px' }}>
								<h4
									style={{
										color: '#616161',
										fontSize: '20px',
										fontWeight: '600',
									}}>
									Guided Cost Estimating
								</h4>
								<h3
									style={{
										fontSize: '36px',
										fontWeight: '600',
										marginTop: '20px',
									}}>
									Effortless Estimating.
								</h3>
								<p
									style={{
										fontSize: '20px',
										color: '#616161',
										marginTop: '20px',
									}}>
									Just Upload Your Blueprints Or Enter A Few Project Details -
									As Simple As That! No User Training Needed.
								</p>
								<Link to='/signup'>Get Started</Link>
							</div>
						</Col>
						<Col sm={{ order: 'last' }} xs={{ order: 'first' }}>
							<div>
								<Image
									fluid
									src={Laptop3}
									alt='laptop-1'
									style={{ minWidth: '340px' }}
								/>
							</div>
						</Col>
					</Row>
					<Row className='landing-key-points'>
						<Col style={{ display: 'flex', alignItems: 'center' }}>
							<div style={{ marginBottom: '20px' }}>
								<h4
									style={{
										color: '#616161',
										fontSize: '20px',
										fontWeight: '600',
									}}>
									Bid Analytics
								</h4>
								<h3
									style={{
										fontSize: '36px',
										fontWeight: '600',
										marginTop: '20px',
									}}>
									Valuable Project Data Insights.
								</h3>
								<p
									style={{
										fontSize: '20px',
										color: '#616161',
										marginTop: '20px',
									}}>
									Access All Of Your Project Cost Analytic On One Dashboard!
								</p>
								<Link to='/signup'>Get Started</Link>
							</div>
						</Col>
						<Col sm={{ order: 'first' }} xs={{ order: 'first' }}>
							<div>
								<Image
									fluid
									src={Laptop4}
									alt='laptop-1'
									style={{ minWidth: '340px' }}
								/>
							</div>
						</Col>
					</Row>
					<Row
						className='general-background-div'
						style={{ justifyContent: 'center' }}>
						<Row>
							<h3
								style={{
									textAlign: 'center',
									marginTop: '50px',
									color: '#616161',
									fontSize: '1.5rem',
								}}
								id='pricing'>
								Pricing
							</h3>
							<h3
								className='caption-message-center'
								style={{ marginTop: '5px' }}>
								Choose A Plan That's Right For You
							</h3>
							<p
								style={{
									textAlign: 'center',
									marginTop: '10px',
									color: '#616161',
									fontSize: '25px',
								}}>
								Pick from one of our plans or pay as you go!
							</p>
						</Row>
						<Row lg={3} sm={1}>
							<Col
								className='pricing-card'
								style={{ boxShadow: '1px 1px 7px gray' }}>
								<div style={{ marginTop: '20px', marginLeft: '20px' }}>
									<span>Flexible</span>
									<p
										style={{
											color: '#003087',
											fontSize: '20px',
											fontWeight: '800',
										}}>
										Pay as you go
									</p>
									<h3
										style={{
											fontSize: '36px',
											fontWeight: '700',
											fontStyle: 'bold',
										}}>
										Quick Pay
									</h3>
									<span
										style={{
											color: '#003087',
											fontSize: '16px',
											fontWeight: 'bold',
										}}>
										What's included
									</span>
								</div>
								<div style={{ marginLeft: '20px' }}>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check fa-lg'
											style={{
												color: '#003087',
												paddingRight: '10px',
											}}
										/>
										<span>No commitment</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Pay per use</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Flat Fee $20.00 Per Estimate</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Get detail reports and downloadable PDFs</span>
									</div>
								</div>
								<div
									style={{
										textAlign: 'center',
										marginTop: '20px',
									}}>
									<Button
										style={{
											color: 'white',
											backgroundColor: '#003087',
											width: '150px',
											marginTop: '30px',
										}}>
										<Link style={{ color: 'white' }} to='/signup'>
											Get Started
										</Link>
									</Button>
								</div>
							</Col>
							<Col
								className='pricing-card-disable'
								style={{ boxShadow: '1px 1px 7px gray' }}>
								<div style={{ marginTop: '20px', marginLeft: '20px' }}>
									<span>For individuals or new contractors</span>
									<p
										style={{
											color: '#003087',
											fontSize: '20px',
											fontWeight: '800',
										}}>
										Starter
									</p>
									<Row>
										<Col>
											<h3
												style={{
													fontSize: '36px',
													fontWeight: '700',
													fontStyle: 'bold',
												}}>
												$
												<span
													style={{ fontSize: '16px', fontWeight: 'normal' }}>
													/monthly
												</span>
											</h3>
										</Col>
									</Row>
									<span
										style={{
											color: '#003087',
											fontSize: '16px',
											fontWeight: 'bold',
										}}>
										What's included
									</span>
								</div>
								{/* <div style={{ marginLeft: '20px' }}>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check fa-lg'
											style={{
												color: '#003087',
												paddingRight: '10px',
											}}
										/>
										<span>All analytics features</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Only 0.40% Effective Fee</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>1 team member</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Estimate up to $125,000 worth of projects</span>
									</div>
								</div> */}
								<div
									style={{
										textAlign: 'center',
										marginTop: '20px',
									}}>
									<Button
										disabled
										style={{
											color: 'white',
											backgroundColor: '#003087',
											width: '150px',
											marginTop: '30px',
										}}>
										Coming Soon
									</Button>
								</div>
							</Col>
							<Col
								className='pricing-card-disable'
								style={{ boxShadow: '1px 1px 7px gray' }}>
								<div style={{ marginTop: '20px', marginLeft: '20px' }}>
									<span>For small contractors</span>
									<p
										style={{
											color: '#003087',
											fontSize: '20px',
											fontWeight: '800',
										}}>
										Local Pro
									</p>
									<Row>
										<Col>
											<h3
												style={{
													fontSize: '36px',
													fontWeight: '700',
													fontStyle: 'bold',
												}}>
												$$
												<span
													style={{ fontSize: '16px', fontWeight: 'normal' }}>
													/monthly
												</span>
											</h3>
										</Col>
									</Row>
									<span
										style={{
											color: '#003087',
											fontSize: '16px',
											fontWeight: 'bold',
										}}>
										What's included
									</span>
								</div>
								{/* <div style={{ marginLeft: '20px' }}>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check fa-lg'
											style={{
												color: '#003087',
												paddingRight: '10px',
											}}
										/>
										<span>All analytics features</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Only 0.35% Effective fee</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Up to 2 team members</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Estimate up to $500,000 worth of projects</span>
									</div>
								</div> */}
								<div
									style={{
										textAlign: 'center',
										marginTop: '20px',
									}}>
									<Button
										disabled
										style={{
											color: 'white',
											backgroundColor: '#003087',
											width: '150px',
											marginTop: '30px',
										}}>
										Coming Soon
									</Button>
								</div>
							</Col>
							{/* <Col
								className='pricing-card-disable'
								style={{
									boxShadow: '1px 1px 7px black',
								}}>
								<div style={{ marginTop: '20px', marginLeft: '20px' }}>
									<span>For established contractors</span>
									<p
										style={{
											color: '#003087',
											fontSize: '20px',
											fontWeight: '800',
										}}>
										Regional Pro
									</p>
									<Row>
										<Col>
											<h3
												style={{
													fontSize: '36px',
													fontWeight: '700',
													fontStyle: 'bold',
												}}>
												$$$
												<span
													style={{ fontSize: '16px', fontWeight: 'normal' }}>
													/monthly
												</span>
											</h3>
										</Col>
									</Row>
									<span
										style={{
											color: '#003087',
											fontSize: '16px',
											fontWeight: 'bold',
										}}>
										What's included
									</span>
								</div>
								<div style={{ marginLeft: '20px' }}>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check fa-lg'
											style={{
												color: '#003087',
												paddingRight: '10px',
											}}
										/>
										<span>All analytic features</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Only 0.275% Effective fee</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Up to 3 team member</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Estimate up to $2,000,000 worth of projects</span>
									</div>
								</div>
								<div
									style={{
										textAlign: 'center',
										marginTop: '20px',
									}}>
									<Button
										disabled
										style={{
											color: 'white',
											backgroundColor: '#003087',
											width: '150px',
											marginTop: '30px',
										}}>
										Coming Soon
									</Button>
								</div>
							</Col>
							<Col
								className='pricing-card-disable'
								style={{
									boxShadow: '1px 1px 7px gray',
								}}>
								<div style={{ marginTop: '20px', marginLeft: '20px' }}>
									<span>For large contractors</span>
									<p
										style={{
											color: '#003087',
											fontSize: '20px',
											fontWeight: '800',
										}}>
										Enterprise
									</p>
									<Row>
										<Col>
											<h3
												style={{
													fontSize: '36px',
													fontWeight: '700',
													fontStyle: 'bold',
												}}>
												$$$$
												<span
													style={{ fontSize: '16px', fontWeight: 'normal' }}>
													/monthly
												</span>
											</h3>
										</Col>
									</Row>
									<span
										style={{
											color: '#003087',
											fontSize: '16px',
											fontWeight: 'bold',
										}}>
										What's included
									</span>
								</div>
								<div style={{ marginLeft: '20px' }}>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check fa-lg'
											style={{
												color: '#003087',
												paddingRight: '10px',
											}}
										/>
										<span>All analytics features</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Only 0.20% Effective Fee</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Up to 5 team members</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Estimate up to $6,000,000 worth of projects</span>
									</div>
								</div>
								<div
									style={{
										textAlign: 'center',
										marginTop: '20px',
									}}>
									<Button
										disabled
										style={{
											color: 'white',
											backgroundColor: '#003087',
											width: '150px',
											marginTop: '30px',
										}}>
										Coming Soon
									</Button>
								</div>
							</Col>
							<Col
								className='pricing-card-disable'
								style={{
									boxShadow: '1px 1px 7px gray',
								}}>
								<div style={{ marginTop: '20px', marginLeft: '20px' }}>
									<span>Flexible</span>
									<p
										style={{
											color: '#003087',
											fontSize: '20px',
											fontWeight: '800',
										}}>
										Customizable
									</p>
									<Row>
										<h3
											style={{
												fontSize: '36px',
												fontWeight: '700',
												fontStyle: 'bold',
											}}>
											Let's Talk
										</h3>
									</Row>
									<span
										style={{
											color: '#003087',
											fontSize: '16px',
											fontWeight: 'bold',
										}}>
										What's included
									</span>
								</div>
								<div style={{ marginLeft: '20px' }}>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check fa-lg'
											style={{
												color: '#003087',
												paddingRight: '10px',
											}}
										/>
										<span>All analytics features</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Custom Effective Fee</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Enhance Flexibility</span>
									</div>
									<div style={{ marginTop: '10px' }}>
										<FontAwesomeIcon
											icon='fa-solid fa-circle-check'
											style={{ color: '#003087', paddingRight: '10px' }}
										/>
										<span>Custom Templates and Integrations</span>
									</div>
								</div>
								<div
									style={{
										textAlign: 'center',
										marginTop: '20px',
									}}>
									<Button
										disabled
										style={{
											color: 'white',
											backgroundColor: '#003087',
											width: '150px',
											marginTop: '30px',
										}}>
										Coming Soon
									</Button>
								</div>
							</Col> */}
						</Row>
					</Row>
					<Row style={{ textAlign: 'center', marginTop: '70px' }}>
						<h3
							style={{ color: '#003087', fontSize: '36px', fontWeight: '800' }}>
							What Our Clients Say
						</h3>
						<p
							style={{ color: '#616161', fontSize: '25px', marginTop: '10px' }}>
							Don't Take Our Word For It. Trust Our Customers
						</p>
					</Row>
					<Row lg={3} sm={1}>
						<Col style={{ textAlign: 'center', minWidth: '300px' }}>
							<h3
								style={{
									margin: '15px auto',
									fontSize: '19px',
									minHeight: '70px',
								}}>
								"No More Wasted Time Doing Estimates. It's Better, Cheaper, And
								Faster Than Any Other Way Of Doing it"
							</h3>
							<p style={{ marginBottom: '0' }}>Ruben L.</p>
							<p
								style={{
									fontSize: '16px',
									fontWeight: '600',
									color: '#003087',
								}}>
								Alliance Roofing
							</p>
						</Col>
						<Col style={{ textAlign: 'center', minWidth: '300px' }}>
							<h3
								style={{
									margin: '15px auto',
									fontSize: '19px',
									minHeight: '70px',
								}}>
								"I'll Never Need To Hire Or Wait On An Estimator Again; Much
								Less Do It Myself"
							</h3>
							<p style={{ marginBottom: '0' }}>Hector C.</p>
							<p
								style={{
									fontSize: '16px',
									fontWeight: '600',
									color: '#003087',
								}}>
								HM&D Electric
							</p>
						</Col>
						<Col style={{ textAlign: 'center', minWidth: '300px' }}>
							<h3
								style={{
									margin: '15px auto',
									fontSize: '19px',
									minHeight: '70px',
								}}>
								"Opticost Helps To Solve Several Of The Biggest Estimate And
								Preconstruction Phase Problems In The Industry Like Magic"
							</h3>
							<p style={{ marginBottom: '0' }}>Marcelo G.</p>
							{/* <p
								style={{
									fontSize: '16px',
									fontWeight: '600',
									color: '#003087',
								}}>
								IQV Construction And Roofing
							</p> */}
						</Col>
					</Row>
					<Row
						className='general-background-div'
						style={{ marginBottom: '50px' }}>
						<Col
							style={{
								marginTop: '50px',
								marginBottom: '50px',
							}}>
							<h3
								style={{
									textAlign: 'center',
									color: '#616161',
									fontSize: '1.5rem',
								}}>
								Try Us Today!
							</h3>
							<h3
								className='caption-message-center'
								style={{ marginTop: '5px' }}>
								With Opticost, You'll Never Need To Estimate Again.
							</h3>

							<Row
								style={{
									justifyContent: 'center',
									maxWidth: '430px',
									margin: '10px auto',
								}}>
								<Link
									to='/bookademo'
									style={{ margin: '10px auto' }}
									className='btn btn-lg nav-sign-up-button'>
									Book A Demo
								</Link>
								<Link
									to='/signup'
									className='btn btn-lg nav-sign-in-button'
									style={{ margin: '10px auto' }}>
									Get Started
								</Link>
							</Row>
						</Col>
					</Row>
				</Container>
			</>
		);
	} else if (props.token || persistLogin === 'true') {
		return (
			<Row>
				<Col style={{ marginTop: '100px' }}>
					<p className='show-message'>Welcome Back {props.userFullName}</p>
				</Col>
			</Row>
		);
	}
}
export default Intro;
