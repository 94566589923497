import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Button, Image } from 'react-bootstrap';
import { APIURL } from '../config';
import { Redirect } from 'react-router-dom';
import { Link } from 'react-router-dom';
import teamImage from '../images/TeamImg.png';
function SignIn(props) {
	const [username, setUsername] = useState('');
	const [enteredPassword, setEnteredPassword] = useState('');
	const [role, setRole] = useState('user');
	const [redirect, setRedirect] = useState(false);
	const [spinner, setSpinner] = useState('not-loading');
	const [message, setMessage] = useState('');
	const [loginMessage, setLoginMessage] = useState('');
	const [capsLockMessage, setCapsLockMessage] = useState('');

	const handleEmailChange = (event) => {
		setUsername(event.target.value.toLowerCase().trim());
	};

	const handlePassChange = (event) => {
		setEnteredPassword(event.target.value.trim());
	};
	// const handleRoleChange = (event)=> {
	//     setRole(event.target.value)
	// }
	const togglePersist = () => {
		props.setPersist((prev) => !prev);
	};

	const checkCapsLock = (event) => {
		if (event.getModifierState('CapsLock')) {
			setCapsLockMessage('Caps Lock is on!');
		} else {
			setCapsLockMessage('');
		}
	};

	const handleEnterKey = (event) => {
		if (event.key === 'Enter') {
			signIn();
		} else return;
	};

	const getMasterMaterialsList = () => {
		fetch(`${APIURL}/materials`, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json; charset=utf-8',
			},
		})
			.then((res) => res.json())
			.then((data) => {
				props.setMasterMaterialsList(data);
			});
	};

	const fetchUserData = async () => {
		if (!props.token) {
			return;
		} else if (props.token) {
			getMasterMaterialsList();
			await fetch(`${APIURL}/users/${username}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props.token}`,
					'Content-Type': 'application/json; charset=utf-8',
				},
			})
				.then((response) => response.json())
				.then((data) => {
					props.setUserId(data.id);
					props.setAccount(data.account);
					props.setUserFullName(data.first_name + ' ' + data.last_name);
					props.setUserEmail(username);
					props.setUserPhone(data.phone);
					props.setUserProfile(data.profile);
					setRedirect(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};

	const fetchContractorData = async () => {
		if (!props.token) {
			return;
		} else if (props.token) {
			//getMasterMaterialsList()
			await fetch(`${APIURL}/contractors/${username}`, {
				method: 'GET',
				headers: {
					Authorization: `Bearer ${props.token}`,
					'Content-Type': 'application/json; charset=utf-8',
				},
			})
				.then((response) => response.json())
				.then((data) => {
					props.setUserId(data.id);
					props.setAccount(data.account);
					props.setUserFirstName(data.first_name);
					props.setUserLastName(data.last_name);
					props.setUserProfile(data.profile);
					setRedirect(true);
				})
				.catch((error) => {
					console.log(error);
				});
		}
	};
	const signIn = async () => {
		if (username === '' || username === null || username === undefined) {
			setMessage('not-signin-message');
			setLoginMessage('Please Enter Your Email');
		} else if (enteredPassword === '' || enteredPassword == null) {
			setMessage('not-signin-message');
			setLoginMessage('Please Enter Your Password');
		} else if (role === '' || role === undefined || role === null) {
			setMessage('not-signin-message');
			setLoginMessage('Please Select Your Account Type');
		} else {
			await fetch(
				`${APIURL}/users/signin`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
					credentials: 'include',
					body: JSON.stringify({
						email: username,
						password: enteredPassword,
						account_type: role,
					}),
				},
				setMessage('signin-message'),
				setLoginMessage('Login In'),
				setSpinner('loading'),
				localStorage.setItem('persist', props.persist),
				localStorage.setItem('common', username)
			)
				.then((res) => res.json())
				.then((res) => {
					props.setToken(res);
					setSpinner('not-loading');
				})
				.catch(() => {
					setSpinner('not-loading');
					setMessage('not-signin-message');
					setLoginMessage('Incorrect Username and/or Password');
				});
		}
	};
	useEffect(() => {
		if (role === 'user') fetchUserData();
		else {
			fetchContractorData();
		}
		//eslint-disable-next-line
	}, [props.token]);
	if (redirect === true) {
		return <Redirect to='/' />;
	}
	return (
		<Row style={{ marginTop: '100px' }}>
			<Col className='image-col' sm={true}>
				<Image fluid src={teamImage} />
			</Col>
			<Col className='signup-signin-box'>
				<Row style={{ marginBottom: '50px' }}>
					<Col style={{ textAlign: 'right' }}>
						<span>Don't have an account?</span>
						<Link to='/signup'>
							<span style={{ marginLeft: '5px' }}>Sign up</span>
						</Link>
					</Col>
				</Row>
				<Row>
					<h3 style={{ fontSize: '40px', fontWeight: '700' }}>Welcome back!</h3>
					<p>Please sign in to your account</p>
				</Row>
				<Form>
					<Row className='label-input-clearance'>
						<Col>
							<Form.Label>Email</Form.Label>
							<Form.Control
								type='text'
								placeholder='Enter your email'
								id='username'
								name='username'
								onChange={handleEmailChange}></Form.Control>
						</Col>
					</Row>
					<Row className='label-input-clearance'>
						<Col>
							<Form.Label>Password</Form.Label>
							<Form.Control
								onKeyUp={checkCapsLock}
								onKeyDown={handleEnterKey}
								type='password'
								placeholder='Password'
								id='password'
								name='password'
								value={enteredPassword}
								onChange={handlePassChange}></Form.Control>
							{capsLockMessage && (
								<span style={{ color: 'red' }}>{capsLockMessage}</span>
							)}
						</Col>
					</Row>
					<div>
						{/* <div className='signin-button-label'>
								<label className='signin-button-label'>
									As User
									<input
										type='radio'
										name='role'
										value='user'
										required
										onChange={handleRoleChange}></input>
								</label>
								<label className='signin-button-label'>
									As Contractor
									<input
										type='radio'
										name='role'
										value='contractor'
										required
										onChange={handleRoleChange}></input>
								</label>
							</div> */}
						<Row className='label-input-clearance'>
							<Col>
								<label>
									<input
										type='checkbox'
										name='role'
										onChange={togglePersist}
										checked={props.persist}
										style={{ marginRight: '5px' }}></input>
									Keep me signed in
								</label>
							</Col>
							<Col style={{ textAlign: 'right' }}>
								<Link to='/forgotpassword'>
									<p>Forgot Password?</p>
								</Link>
							</Col>
						</Row>
						<div className='d-grid gap-2'>
							<Button size='lg' onClick={signIn} className='signin-button'>
								Sign In
							</Button>
							<p
								className={message}
								style={{ marginTop: '0', marginBottom: '0' }}>
								{loginMessage}
							</p>
						</div>
					</div>
				</Form>
				<div className='loading-block'> </div>
				<div className={spinner}> </div>
			</Col>
		</Row>
	);
}

export default SignIn;
