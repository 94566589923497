import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import { APIURL } from '../../config';
//import { projectDetailConfig, projectDataConfig } from '../../config';
import PdfReport from './PdfReports';
const EstimatedProjectDetail = (props) => {
	const [projectData, setProjectData] = useState('');
	const [projectDataDetail, setProjectDataDetail] = useState('');
	const previousPage = useHistory();
	const projectId = props.match.params.id;

	const fetchProjectData = async () => {
		if (!props.token) {
			return;
		} else if (props.token) {
			await fetch(`${APIURL}/estimated-projects/${projectId}`, {
				method: 'GET',
				headers: { Authorization: `Bearer ${props.token}` },
			})
				.then((response) => response.json())
				.then((project) => {
					setProjectData(project);
					setProjectDataDetail(project.project_detail);
				});
		}
	};

	const handleGoBack = () => {
		previousPage.goBack();
	};

	useEffect(() => {
		fetchProjectData();
		//eslint-disable-next-line
	}, []);

	if (!props.token) {
		return (
			<h2 className='not-signin-message'>Please sign in to access this page</h2>
		);
	} else if (!projectData || !projectDataDetail) {
		return (
			<>
				<p>Loading Data</p>
			</>
		);
	} else
		return (
			<>
				<Row style={{marginTop:'100px'}}>
					<Col
						className='general-back-col'
						sm={2}
						xs={4}
						onClick={handleGoBack}>
						<FontAwesomeIcon
							className='font-awesome'
							icon='fa-solid fa-circle-left'
						/>
						Go Back
					</Col>
				</Row>
				<h3 className='pdf-report-header'>
					<FontAwesomeIcon
						className='font-awesome'
						icon='fa-solid fa-file-lines'
					/>
					Project Detail
				</h3>
				<Row className='pdf-report-wrapper'>
					<Col sm={4} className='pdf-report-col'>
						<p>Customer Name: {projectData.customer ?? ''}</p>
						<p>Phone#: {projectData.phone ?? ''}</p>
						<p>Address: {projectData.address ?? ''}</p>
						<p>Date: {projectData.created_date ?? ''}</p>
						<p>Job Number: {projectData.job_number ?? ''}</p>
						<p>Job Description: {projectData.job_description ?? ''}</p>
						<p>Is Tear Off Required?: {projectDataDetail.isTearOff ?? ''}</p>
						<p>Is New Deck Required?: {projectDataDetail.isNewDeck ?? ''}</p>
						<p>
							Is New Gutter Required?: {projectDataDetail.isNewGutters ?? ''}
						</p>
					</Col>
					<Col sm={4} className='pdf-report-col'>
						<p>
							Total Square Footage Measurement:
							{' ' + (projectDataDetail.enteredSquareFootage ?? '')} Sqft
						</p>
						<p>
							Current Roof Type:{' '}
							{projectDataDetail.enteredCurrentRoofType ?? ''}
						</p>
						<p>
							Desired Roof Type:{' '}
							{projectDataDetail.enteredDesiredRoofType ?? ''}
						</p>
						<p>
							Desired Underlayment:
							{' ' + (projectDataDetail.enteredDesiredUnderlayment ?? '')}
						</p>
						<p>New Deck Type: {projectDataDetail.enteredNewDeck ?? ''}</p>
						<p>
							Number of Chimney Flashing Allowance:
							{' ' + (projectDataDetail.enteredChimneyFlashingAllowance ?? '')}
						</p>
						<p>
							Number of Skylight Flashing Allowance:
							{' ' + (projectDataDetail.enteredSkylightFlashingAllowance ?? '')}
						</p>
						<p>
							Number of Misc Flashing Allowance:
							{' ' + (projectDataDetail.enteredSkylightFlashingAllowance ?? '')}
						</p>
						<p>Valley Measurement: {projectDataDetail.enteredValley ?? ''}</p>
					</Col>
					<Col sm={4} className='pdf-report-col'>
						<p>Eave Measurement: {projectDataDetail.enteredEave ?? ''} Ft</p>
						<p>Ridge Measurement: {projectDataDetail.enteredRidge ?? ''} Ft</p>
						<p>Hip Measurement: {projectDataDetail.enteredHip ?? ''} Ft</p>
						<p>Rake Measurement: {projectDataDetail.enteredRake ?? ''} Ft</p>
						<p>
							Install Complexity: x
							{projectDataDetail.enteredInstallComplexity ?? ''}
						</p>
						<p>
							Estimated Waste Percentage:
							{' ' + (projectDataDetail.enteredWastePercentage ?? '')}%
						</p>
						<p>
							Estimated Overhead Percentage:
							{' ' + (projectDataDetail.enteredOverheadPercentage ?? '')}%
						</p>
						<p>
							Estimated Profit Percentage:
							{' ' + (projectDataDetail.enteredProfitPercentage ?? '')}%
						</p>
						<p>
							Estimated Labor Rate/Hr: $
							{projectDataDetail.enteredLaborRate ?? ''}
						</p>
					</Col>
				</Row>
				<div style={{ margin: '10px' }}> </div>
				<Row className='report-board'>
					<Col sm={{ span: 3, offset: 2 }} className='project-report'>
						<ul className='toplevel-breakdown'>
							<h4>Quote To Customer</h4>
							<li>
								<span>
									Tear-Off Cost:
									{' $' + (projectDataDetail.estimatedTearOff ?? '')}
								</span>
							</li>
							<li>
								<span>
									Disposal Cost:
									{' $' + (projectDataDetail.estimatedDisposalCost ?? '')}
								</span>
							</li>
							<li>
								<span>
									New Deck Installation:
									{' $' + (projectDataDetail.quotedNewDeckInstallation ?? '')}
								</span>
							</li>
							<li>
								<span>
									New Gutter & Downspout:
									{' $' + (projectDataDetail.quotedGutterAndDownspout ?? '')}
								</span>
							</li>
							<li>
								<span>
									New Complete Roof Installation:
									{' $' + (projectDataDetail.quotedNewRoofInstallation ?? '')}
								</span>
							</li>
						</ul>
					</Col>
					<Col sm={3} className='project-report'>
						<ul className='toplevel-breakdown'>
							<h4>Overview</h4>
							<li>
								<span>
									Raw Materials Cost:
									{' $' + (projectDataDetail.estimatedRawMaterialCost ?? '')}
								</span>
							</li>
							<li>
								<span>
									Labor Cost:{' $' + (projectDataDetail.estimatedLaborCost ?? '')}
								</span>
							</li>
							<li>
								<span>
									Base Project Cost:
									{' $' + (projectDataDetail.estimatedBaseProjectCost ?? '')}
								</span>
							</li>
							<li>
								<span>
									Project Cost (With Overhead):
									{' $' + (projectDataDetail.estimatedProjectCostWithOverhead ?? '')}
								</span>
							</li>
							<li>
								<span>
									Total Project (With Overhead & Profit):
									{' $' + (projectDataDetail.estimatedTotalProjectCost ?? '')}
								</span>
							</li>
						</ul>
					</Col>
				</Row>

				<h3 className='pdf-report-header'>
					<FontAwesomeIcon
						className='font-awesome'
						icon='fa-solid fa-file-pdf'
					/>
					Downloadable PDF
				</h3>
				<PdfReport
					projectDataDetail={projectDataDetail}
					projectData={projectData}
					userFullName={props.userFullName}
					userEmail={props.userEmail}
					userPhone={props.userPhone}
				/>
				<h3 className='pdf-report-header'>
					<FontAwesomeIcon
						className='font-awesome'
						icon='fa-solid fa-file-pen'
					/>
					Project Report
				</h3>
				<Row className='pdf-report-wrapper'>
					<Col sm={4} className='pdf-report-col'>
						<p>
							Estimated Base Project Cost:
							{' $' + (projectDataDetail.estimatedBaseProjectCost ?? '')}
						</p>
						<p>
							Estimated Project Overhead:
							{' $' + (projectDataDetail.estimatedOverhead ?? '')}
						</p>
						<p>
							Estimated Project Cost With Overhead:
							{' $' + (projectDataDetail.estimatedProjectCostWithOverhead ?? '')}
						</p>
						<p>
							Estimated Profit:
							{' $' + (projectDataDetail.estimatedProjectProfit ?? '')}
						</p>
						<p>
							Estimated Total Project Cost:
							{' $' + (projectDataDetail.estimatedTotalProjectCost ?? '')}
						</p>
						<p>
							Estimated Tear-off Cost:
							{' $' + (projectDataDetail.estimatedTearOff ?? '')}
						</p>
						<p>
							Estimated Disposal Cost:
							{' $' + (projectDataDetail.estimatedDisposalCost ?? '')}
						</p>
						<p>
							Estimated Total Material Cost:
							{' $' + (projectDataDetail.estimatedRawMaterialCost ?? '')}
						</p>
						<p>
							Estimated Total Labor Cost:
							{' $' + (projectDataDetail.estimatedLaborCost ?? '')}
						</p>
						<p>
							Estimated Total Labor Hour:
							{' ' + projectDataDetail.estimatedTotalLaborHour + ' Hrs' ||
								'0 Hr'}
						</p>
						<p>
							Estimated Tear-off Labor Hour:
							{' ' + projectDataDetail.estimatedTearOffHour + ' Hrs' || '0 Hr'}
						</p>
						<p>
							Estimated New Roof Material Cost:
							{' $' + (projectDataDetail.estimatedNewRoofCost ?? '')}
						</p>
						<p>
							Estimated New Roof Labor Cost:
							{' $' + (projectDataDetail.estimatedNewRoofLaborCost ?? '')}
						</p>
						<p>
							Estimated New Roof Labor Hour:
							{' ' + projectDataDetail.estimatedNewRoofLaborHour + ' Hrs' ||
								'0 Hr'}
						</p>
						<p>
							Estimated New Underlayment Cost:
							{' $' + (projectDataDetail.estimatedNewUnderlaymentCost ?? '')}
						</p>
						<p>
							Estimated New Underlayment Labor Cost:
							{' $' + (projectDataDetail.estimatedNewUnderlaymentLaborCost ?? '')}
						</p>
						<p>
							Estimated New Underlayment Labor Hour:
							{' ' +
								projectDataDetail.estimatedNewUnderlaymentLaborHour +
								' Hrs' || '0 Hr'}
						</p>
						<p>
							Estimated New Deck Cost:
							{' $' + (projectDataDetail.estimatedNewDeckCost ?? '')}
						</p>
						<p>
							Estimated New Deck Labor Cost:
							{' $' + (projectDataDetail.estimatedNewDeckLaborCost ?? '')}
						</p>
						<p>
							Estimated New Deck Labor Hour:
							{' ' + projectDataDetail.estimatedNewDeckLaborHour + ' Hrs' ||
								'0 Hr'}
						</p>
						<p>
							Estimated Starter Shingle Cost:
							{' $' + (projectDataDetail.estimatedStarterShingleCost ?? '')}
						</p>
						<p>
							Estimated Starter Shingle Labor Cost:
							{' $' + (projectDataDetail.estimatedStarterShingleLaborCost ?? '')}
						</p>
						<p>
							Estimated Starter Shingle Labor Hour:
							{' ' +
								projectDataDetail.estimatedStarterShingleLaborHour +
								' Hrs' ?? ''}
						</p>
					</Col>
					<Col sm={4} className='pdf-report-col'>
						<p>
							Estimated Lumber Material Cost:
							{' $' + (projectDataDetail.estimatedLumberCost ?? '')}
						</p>
						<p>
							Estimated Lumber Labor Cost:
							{' $' + (projectDataDetail.estimatedLumberLaborCost ?? '')}
						</p>
						<p>
							Estimated Lumber Labor Hour:
							{' ' + (projectDataDetail.estimatedLumberLaborHour ?? '') + ' Hrs'}
						</p>
						<p>
							Estimated Ridge Material Cost:
							{' $' + (projectDataDetail.estimatedRidgeCost ?? '')}
						</p>
						<p>
							Estimated Ridge Labor Cost:
							{' $' + (projectDataDetail.estimatedRidgeLaborCost ?? '')}
						</p>
						<p>
							Estimated Ridge Labor Hour:
							{' ' + (projectDataDetail.estimatedRidgeLaborHour ?? '') + ' Hrs'}
						</p>
						<p>
							Estimated Hip Material Cost:
							{' $' + (projectDataDetail.estimatedHipCost ?? '')}
						</p>
						<p>
							Estimated Hip Labor Cost:
							{' $' + projectDataDetail.estimatedHipLaborCost ?? ''}
						</p>
						<p>
							Estimated Hip Labor Hour:
							{' ' + (projectDataDetail.estimatedHipLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Eave Material Cost:
							{' $' + (projectDataDetail.estimatedEaveCost ?? '')}
						</p>
						<p>
							Estimated Eave Labor Cost:
							{' $' + (projectDataDetail.estimatedEaveLaborCost ?? '')}
						</p>
						<p>
							Estimated Eave Labor Hour:
							{' ' + (projectDataDetail.estimatedEaveLaborHour ?? '') + ' Hrs'}
						</p>
						<p>
							Estimated Rake Material Cost:
							{' $' + (projectDataDetail.estimatedRakeCost ?? '')}
						</p>
						<p>
							Estimated Rake Labor Cost:
							{' $' + (projectDataDetail.estimatedRakeLaborCost ?? '')}
						</p>
						<p>
							Estimated Rake Labor Hour:
							{' ' + (projectDataDetail.estimatedRakeLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Valley Material Cost:
							{' $' + (projectDataDetail.estimatedValleyCost ?? '')}
						</p>
						<p>
							Estimated Valley Labor Cost:
							{' $' + (projectDataDetail.estimatedValleyLaborCost ?? '')}
						</p>
						<p>
							Estimated Valley Labor Hour:
							{' ' + (projectDataDetail.estimatedValleyLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Chimney Allowance Cost:
							{' $' + (projectDataDetail.estimatedChimneyCost ?? '')}
						</p>
						<p>
							Estimated Chimney Allowance Labor Cost:
							{' $' + (projectDataDetail.estimatedChimneyLaborCost ?? '')}
						</p>
						<p>
							Estimated Chimney Allowance Labor Hour:
							{' ' + (projectDataDetail.estimatedChimneyLaborHour ?? '0') + 'Hrs'}
						</p>
						<p>
							Estimated Skylight Allowance Cost:
							{' $' + (projectDataDetail.estimatedSkylightCost ?? '')}
						</p>
						<p>
							Estimated Skylight Allowance Labor Cost:
							{' $' + (projectDataDetail.estimatedSkylightLaborCost ?? '')}
						</p>
					</Col>
					<Col sm={4} className='pdf-report-col'>
						<p>
							Estimated Skylight Allowance Labor Hour:
							{' ' + (projectDataDetail.estimatedSkylightLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Gutter Material Cost:
							{' $' + (projectDataDetail.estimatedGutterCost ?? '')}
						</p>
						<p>
							Estimated Gutters Labor Cost:
							{' $' + (projectDataDetail.estimatedGutterLaborCost ?? '')}
						</p>
						<p>
							Estimated Gutters Labor Hour:
							{' ' + (projectDataDetail.estimatedGutterLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Downspout Material Cost:
							{' $' + (projectDataDetail.estimatedDownspoutCost ?? '')}
						</p>
						<p>
							Estimated Downspout Labor Cost:
							{' $' + (projectDataDetail.estimatedDownspoutLaborCost ?? '')}
						</p>
						<p>
							Estimated Downspout Labor Hour:
							{' ' + (projectDataDetail.estimatedDownspoutLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Roofing Vent Material Cost:
							{' $' + (projectDataDetail.estimatedVentCost ?? '')}
						</p>
						<p>
							Estimated Roofing Vent Labor Cost:
							{' $' + (projectDataDetail.estimatedVentLaborCost ?? '')}
						</p>
						<p>
							Estimated Roofing Vent Labor Hour:
							{' ' + (projectDataDetail.estimatedVentLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Intake Vent Material Cost:
							{' $' + (projectDataDetail.estimatedIntakeVentsCost ?? '')}
						</p>
						<p>
							Estimated Intake Vent Labor Cost:
							{' $' + (projectDataDetail.estimatedIntakeVentsLaborCost ?? '')}
						</p>
						<p>
							Estimated Intake Vent Labor Hour:
							{' ' + (projectDataDetail.estimatedIntakeVentsLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Saddles Material Cost:
							{' $' + (projectDataDetail.estimatedSaddlesCost ?? '')}
						</p>
						<p>
							Estimated Saddles Labor Cost:
							{' $' + (projectDataDetail.estimatedSaddleLaborCost ?? '')}
						</p>
						<p>
							Estimated Saddles Labor Hour:
							{' ' + (projectDataDetail.estimatedSaddleLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Pipe Jacks & Assemblies Cost:
							{' $' + (projectDataDetail.estimatedPipeJacksCost ?? '')}
						</p>
						<p>
							Estimated Pipe Jacks & Assemblies Labor Cost:
							{' $' + (projectDataDetail.estimatedPipeJacksLaborCost ?? '')}
						</p>
						<p>
							Estimated Pipe Jacks & Assemblies Labor Hour:
							{' ' + (projectDataDetail.estimatedPipeJacksLaborHour ?? '0') + ' Hrs'}
						</p>
						<p>
							Estimated Nails Cost:
							{' $' + (projectDataDetail.estimatedNailsCost ?? '')}
						</p>
						<p>
							Estimated Caulk Material Cost:
							{' $' + (projectDataDetail.estimatedCaulkCost ?? '')}
						</p>
						<p>
							Estimated Paint Material Cost:
							{' $' + (projectDataDetail.estimatedPaintCost ?? '')}
						</p>
						<p>
							Estimated Custom Tools Cost:
							{' $' + (projectDataDetail.estimatedCustomToolsCost ?? '')}
						</p>
					</Col>
				</Row>
			</>
		);
};

export default EstimatedProjectDetail;
