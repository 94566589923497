import React, { useReducer } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useState } from 'react';
import { APIURL } from '../config';
function GetUpdate() {
	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			firstname: '',
			lastname: '',
			email: '',
			company: '',
			phone: '',
		}
	);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [spinner, setSpinner] = useState('not_loading');
	const handleChange = (event) => {
		const name = event.target.name;
		const newValue = event.target.value;
		setUserInput({ [name]: newValue });
	};
	const Validation = async () => {
		const email = userInput.email.split('');
		const emailString = userInput.email.toLowerCase();
		const dotCom = '.com';
		const dotNet = '.net';
		const dotOrg = '.org';
		const dotGov = '.gov';
		const dotAi = '.ai';
		const dotIo = '.io';
		const dotMain = '.main';
		if (!email.includes('@')) {
			setEmailError('*Please enter Valid Email*');
			return;
		} else if (
			!emailString.includes(dotCom) &&
			!emailString.includes(dotNet) &&
			!emailString.includes(dotOrg) &&
			!emailString.includes(dotGov) &&
			!emailString.includes(dotAi) &&
			!emailString.includes(dotIo) &&
			!emailString.includes(dotMain)
		) {
			setEmailError('*Please Enter Valid Email*');
			return;
		} else if (userInput.firstname.match(/^[0-9]+$/)) {
			setFirstNameError('First Name Cannot Contain Numbers');
			return;
		} else if (userInput.lastname.match(/^[0-9]+$/)) {
			setLastNameError('Last Name Cannot Contain Numbers');
		} else {
			await fetch(
				`${APIURL}/signup/bookademo`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
					body: JSON.stringify({
						first_name: userInput.firstname,
						last_name: userInput.lastname,
						email: userInput.email,
						company: userInput.company,
						phone: userInput.phone,
					}),
				},
				setSpinner('loading')
			)
				.then((res) => res.json())
				.then((res) => {
					setSpinner('not-loading');
					//window.location.href = 'https://www.surveymonkey.com/r/FF7SJ35';
				});
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		Validation();
	};
	return (
		<>
			<Row className='general-background-div book-demo-box'>
				<Col style={{ padding: '30px' }}>
					<Row style={{ textAlign: 'center', marginTop: '20px' }}>
						<h3 className='h3-big-blue-header'>Book A Demo </h3>
						<p>
							Please Fill in Form Below For Demo Request
							<br /> <span>* indicated a required field</span>
						</p>
					</Row>
					<div className=''>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Col className='label-input-clearance'>
									<Form.Label className=''>First Name*</Form.Label>
									<Form.Control
										className=''
										type='text'
										name='firstname'
										value={userInput.firstname}
										onChange={handleChange}
										required
									/>
									<div className='signup-error-message'>
										<span>{firstNameError}</span>
									</div>
								</Col>
								<Col className='label-input-clearance'>
									<Form.Label className=''>Last Name*</Form.Label>
									<Form.Control
										className=''
										type='text'
										name='lastname'
										value={userInput.lastname}
										onChange={handleChange}
										required
									/>
									<div className='signup-error-message'>
										<span>{lastNameError}</span>
									</div>
								</Col>
							</Row>
							<Col className='label-input-clearance'>
								<Form.Label className=''>Email*</Form.Label>
								<Form.Control
									className=''
									type='email'
									name='email'
									value={userInput.email}
									onChange={handleChange}
									required
								/>
								<div className='signup-error-message'>
									<span>{emailError}</span>
								</div>
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label className=''>Company*</Form.Label>
								<Form.Control
									className=''
									type='text'
									name='company'
									value={userInput.company}
									onChange={handleChange}
									required
								/>
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label className=''>Phone*</Form.Label>
								<Form.Control
									className=''
									type='text'
									name='phone'
									value={userInput.phone}
									onChange={handleChange}
									required
								/>
							</Col>
							<Col style={{ textAlign: 'center' }}>
								<span>After submission, please fill out our survey</span>
							</Col>
							<div style={{ marginTop: '20px' }} className='d-grid gap-2'>
								<Button
									style={{ margin: '0 auto' }}
									className='nav-sign-up-button'
									type='submit'>
									Submit
								</Button>
							</div>
						</Form>
					</div>
					<div className='loading-block'> </div>
					<div className={spinner}> </div>
				</Col>
			</Row>
		</>
	);
}
export default GetUpdate;
