export const scaleFactor = [
	{
		scale: 1.0,
		name: '1" = 1 Foot',
	},
	{
		scale: 0.9375,
		name: '15/16" = 1 Foot',
	},
	{
		scale: 0.875,
		name: '7/8" = 1 Foot',
	},
	{
		scale: 0.8125,
		name: '13/16" = 1 Foot',
	},
	{
		scale: 0.75,
		name: '3/4" = 1 Foot',
	},
	{
		scale: 0.6875,
		name: '11/16" = 1 Foot',
	},
	{
		scale: 0.625,
		name: '5/8" = 1 Foot',
	},
	{
		scale: 0.5625,
		name: '9/16" = 1 Foot',
	},
	{
		scale: 0.5,
		name: '1/2" = 1 Foot',
	},
	{
		scale: 0.4375,
		name: '7/16" = 1 Foot',
	},
	{
		scale: 0.375,
		name: '3/8" = 1 Foot',
	},
	{
		scale: 0.3125,
		name: '5/16" = 1 Foot',
	},
	{
		scale: 0.25,
		name: '1/4" = 1 Foot',
	},
	{
		scale: 0.1875,
		name: '3/16" = 1 Foot',
	},
	{
		scale: 0.125,
		name: '1/8" = 1 Foot',
	},
	{
		scale: 0.0625,
		name: '1/16" = 1 Foot',
	},
];
