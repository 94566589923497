import React, { useReducer } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { APIURL } from '../config';
function ContactUs() {
	const [userInput, setUserInput] = useReducer(
		(state, newState) => ({ ...state, ...newState }),
		{
			firstname: '',
			lastname: '',
			email: '',
			message: '',
		}
	);
	const [firstNameError, setFirstNameError] = useState('');
	const [lastNameError, setLastNameError] = useState('');
	const [emailError, setEmailError] = useState('');
	const [spinner, setSpinner] = useState('not-loading');
	const [showModal, setShowModal] = useState(false);
	const handleModalClose = () => {
		setShowModal(false);
		window.location.href = '/';
	};
	const handleChange = (event) => {
		const name = event.target.name;
		const newValue = event.target.value;
		setUserInput({ [name]: newValue });
	};
	const Validation = async () => {
		const email = userInput.email.split('');
		const emailString = userInput.email.toLowerCase();
		const dotCom = '.com';
		const dotNet = '.net';
		const dotOrg = '.org';
		const dotGov = '.gov';
		const dotAi = '.ai';
		const dotIo = '.io';
		const dotMain = '.main';
		if (!email.includes('@')) {
			setEmailError('*Please enter Valid Email*');
			return;
		} else if (
			!emailString.includes(dotCom) &&
			!emailString.includes(dotNet) &&
			!emailString.includes(dotOrg) &&
			!emailString.includes(dotGov) &&
			!emailString.includes(dotAi) &&
			!emailString.includes(dotIo) &&
			!emailString.includes(dotMain)
		) {
			setEmailError('*Please Enter Valid Email*');
			return;
		} else if (userInput.firstname.match(/^[0-9]+$/)) {
			setFirstNameError('First Name Cannot Contain Numbers');
			return;
		} else if (userInput.lastname.match(/^[0-9]+$/)) {
			setLastNameError('Last Name Cannot Contain Numbers');
		} else {
			await fetch(
				`${APIURL}/signup/inquiry`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json; charset=utf-8',
					},
					body: JSON.stringify({
						first_name: userInput.firstname,
						last_name: userInput.lastname,
						email: userInput.email,
						message: userInput.message,
					}),
				},
				setSpinner('loading')
			)
				.then((res) => res.json())
				.then(() => {
					setSpinner('not-loading');
					setShowModal(true);
				});
		}
	};
	const handleSubmit = (event) => {
		event.preventDefault();
		Validation();
	};
	return (
		<>
			<Row md={true}>
				<Col md={4} style={{ marginTop: '70px' }}>
					<div>
						<h3 className='h3-big-blue-header'>Let's Talk</h3>
						<p>
							Have any questions? We'd love to hear from you. Fill out the form
							to connect with to our team
						</p>
						<p
							style={{ borderTop: '1px solid #003087', maxWidth: '300px' }}></p>
					</div>
					<div style={{ marginTop: '30px' }}>
						<span style={{ color: '#003087', fontWeight: 'bold' }}>
							CONNECT
						</span>
						<p style={{ marginTop: '5px' }}>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-phone'
							/>
							<span> (408) 661-0873</span>
						</p>
						<p>
							<FontAwesomeIcon
								className='font-awesome'
								icon='fa-solid fa-envelope'
							/>
							<span>main@opticost.ai</span>
						</p>
					</div>
					<div style={{ marginTop: '30px' }}>
						<span style={{ color: '#003087', fontWeight: 'bold' }}>
							BOOK A DEMO
						</span>
						<p>
							Request a demo with our team.
							<Link to='/bookademo'> Book a Demo</Link>
						</p>
					</div>
				</Col>
				<Col
					md={8}
					className='general-background-div'
					style={{ padding: '30px' }}>
					<Row style={{ textAlign: 'center', marginTop: '20px' }}>
						<h3 className='h3-big-blue-header'>Contact Us</h3>
						<span style={{ marginBottom: '20px' }}>
							* indicated a required field
						</span>
					</Row>
					<div className=''>
						<Form onSubmit={handleSubmit}>
							<Row>
								<Col className='label-input-clearance'>
									<Form.Label className=''>First Name*</Form.Label>
									<Form.Control
										className=''
										type='text'
										name='firstname'
										value={userInput.firstname}
										onChange={handleChange}
										required
									/>
									<div className='signup-error-message'>
										<span>{firstNameError}</span>
									</div>
								</Col>
								<Col className='label-input-clearance'>
									<Form.Label className=''>Last Name*</Form.Label>
									<Form.Control
										className=''
										type='text'
										name='lastname'
										value={userInput.lastname}
										onChange={handleChange}
										required
									/>
									<div className='signup-error-message'>
										<span>{lastNameError}</span>
									</div>
								</Col>
							</Row>
							<Col className='label-input-clearance'>
								<Form.Label className=''>Email*</Form.Label>
								<Form.Control
									className=''
									type='email'
									name='email'
									value={userInput.email}
									onChange={handleChange}
									required
								/>
								<div className='signup-error-message'>
									<span>{emailError}</span>
								</div>
							</Col>
							<Col className='label-input-clearance'>
								<Form.Label className=''>Message*</Form.Label>
								<Form.Control
									style={{ minHeight: '200px' }}
									as='textarea'
									name='message'
									value={userInput.message}
									onChange={handleChange}
									required
								/>
							</Col>
							<div style={{ marginTop: '20px' }} className='d-grid gap-2'>
								<Button
									style={{ margin: '0 auto' }}
									className='nav-sign-up-button'
									type='submit'>
									Submit
								</Button>
							</div>
						</Form>
					</div>
					<div className='loading-block'> </div>
					<div className={spinner}> </div>
				</Col>
			</Row>
			<Modal
				show={showModal}
				onHide={handleModalClose}
				backdrop='static'
				keyboard={false}>
				<Modal.Header closeButton>
					<Modal.Title>Message Sent</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Thank you for your inquiry, your question is submitted.
				</Modal.Body>
				<Modal.Footer>
					<Button variant='primary' onClick={handleModalClose}>
						Back to home
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
}
export default ContactUs;
