import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Button, Col, Row } from 'react-bootstrap';
import logo from '../images/OpticostLogo2.svg';
import { Link, useLocation } from 'react-router-dom';
function TopNav({ token, handleSignOut, account }) {
	const persistLogin = localStorage.getItem('persist');
	const location = useLocation();
	// let dashBoard
	// if (!token ) {
	// 	dashBoard = ''
	// }
	//  if ( token && account === 'user') {
	// 	dashBoard = (
	// 		<Link to='/user-dashboard'>
	// 			<li className='top-nav-list'>
	// 				<p className='link'> DASHBOARD</p>
	// 			</li>
	// 		</Link>
	// 	);
	// }
	// else if (token && account === 'contractor') {
	// 	dashBoard = (
	// 		<Link to='/contractor-dashboard'>
	// 			<li className='top-nav-list'>
	// 				<p className='link'> DASHBOARD</p>
	// 			</li>
	// 		</Link>
	// 	);
	// }
	if (persistLogin === 'true') {
		return (
			<>
				<Row>
					<Col>
						<Navbar expand='lg'>
							<Container>
								<Navbar.Brand>
									<Link to='/'>
										<img className='main-logo' src={logo} alt='opticost logo' />
									</Link>
								</Navbar.Brand>
								<Navbar.Toggle aria-controls='basic-navbar-nav' />
								<Navbar.Collapse>
									<Nav>
										<Link to='/'>
											<li className='top-nav-list'>
												<p className='link'>Home</p>
											</li>
										</Link>
										<Link to='/roof-estimator'>
											<li className='top-nav-list'>
												<p className='link'>Roof Estimator</p>
											</li>
										</Link>
										<Link to='/estimated-projects'>
											<li className='top-nav-list'>
												<p className='link'>Projects</p>
											</li>
										</Link>
										{/* <Link to='/artificial-intelligence'>
											<li className='top-nav-list'>
												<p className='link'> A.I</p>
											</li>
										</Link> */}
										{/* {dashBoard} */}
										<Link to='/' onClick={handleSignOut}>
											<li className='top-nav-list'>
												<p className='link'>Log out</p>
											</li>
										</Link>
									</Nav>
								</Navbar.Collapse>
							</Container>
						</Navbar>
					</Col>
				</Row>
			</>
		);
	} else if (!token) {
		return (
			<>
				<Row>
					<Col>
						<Navbar expand='lg'>
							<Container>
								<Navbar.Brand>
									<Link to='/'>
										<img className='main-logo' src={logo} alt='opticost logo' />
									</Link>
								</Navbar.Brand>
								<Navbar.Toggle aria-controls='basic-navbar-nav' />
								<Navbar.Collapse>
									<Nav className='top-nav'>
										<Link to='/'>
											<li className='top-nav-list'>
												<p className='link'>Home</p>
											</li>
										</Link>
										<a href='/#pricing'>
											<li className='top-nav-list'>
												<p className='link'>Pricing</p>
											</li>
										</a>
										<Link to='/contactus'>
											<li className='top-nav-list'>
												<p className='link'>Contact</p>
											</li>
										</Link>
									</Nav>
								</Navbar.Collapse>
							</Container>
						</Navbar>
					</Col>
					{location.pathname === '/sign-in' ||
					location.pathname === '/signup' ? (
						<div></div>
					) : (
						<Col
							style={{
								display: 'flex',
								justifyContent: 'flex-end',
								alignItems: 'center',
								marginLeft: '10px',
							}}>
							<Row>
								<Col>
									<Link to='/signup'>
										<Button size='lg' className='nav-sign-up-button'>
											Sign Up
										</Button>
									</Link>
									<Link to='/sign-in'>
										<Button size='lg' className='nav-sign-in-button'>
											Log in
										</Button>
									</Link>
								</Col>
							</Row>
						</Col>
					)}
				</Row>
			</>
		);
	} else
		return (
			<>
				<Row>
					<Col>
						<Navbar expand='lg'>
							<Container className='over-lay'>
								<Navbar.Brand>
									<Link to='/'>
										<img className='main-logo' src={logo} alt='opticost logo' />
									</Link>
								</Navbar.Brand>
								<Navbar.Toggle aria-controls='basic-navbar-nav' />
								<Navbar.Collapse className='top-nav'>
									<Nav>
										{token ? (
											<Link to='/'>
												<li className='top-nav-list'>
													<p className='link'>Home</p>
												</li>
											</Link>
										) : (
											''
										)}
										{token ? (
											<Link to='/roof-estimator'>
												<li className='top-nav-list'>
													<p className='link'>Roof Estimator</p>
												</li>
											</Link>
										) : (
											''
										)}
										{token ? (
											<Link to='/estimated-projects'>
												<li className='top-nav-list'>
													<p className='link'>Projects</p>
												</li>
											</Link>
										) : (
											''
										)}
										{/* {token ? (
											<Link to='/artificial-intelligence'>
												<li className='top-nav-list'>
													<p className='link'> A.I</p>
												</li>
											</Link>
										) : (
											''
										)} */}
										{/* {dashBoard} */}
										{token ? (
											<div className='general-signout' onClick={handleSignOut}>
												<li className='top-nav-list'>
													<p className='link'>Log out</p>
												</li>
											</div>
										) : (
											''
										)}
										{/* {token ? (
											<Link to='/user-dashboard'>
												<li className='top-nav-list'>
													<p className='link'>DASHBOARD</p>
												</li>
											</Link>
										) : (
											''
										)} */}
									</Nav>
								</Navbar.Collapse>
							</Container>
						</Navbar>
					</Col>
				</Row>
			</>
		);
}
export default TopNav;
