import React from 'react';
import { defaultProfile } from '../../config';
import { Link, useLocation } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function UserDashboardNav(props) {
	//props.setCurrentUserId(props.userId)
	//const userIdLink = '/user-dashboard/detail/' + props.userId;
	const contractorIdLink = '/contractor-dashboard/detail/' + props.userId;
	const location = useLocation();
	if (props.userId && props.account === 'user') {
		return (
			<>
				<Col className='sidebar-area' sm={2}>
					<Row sm={2} className='dashboard-user'>
						<Col sm={{ span: 10, offset: 1 }}>
							<FontAwesomeIcon
								className='sidebar-icon'
								icon='fa-solid fa-user'
							/>
							<span>{props.userFullName || 'User'}</span>
						</Col>
					</Row>
					<Link className='sidebar-link' to='/user-dashboard'>
						<Row
							sm={2}
							className={`sidebar ${
								location.pathname === '/user-dashboard' ? 'active-tab' : ''
							} `}>
							<Col sm={{ span: 9, offset: 1 }}>
								<FontAwesomeIcon
									className='sidebar-icon'
									icon='fa-solid fa-rectangle-list'
								/>
								<span>Dash Board</span>
							</Col>
						</Row>
					</Link>
					<Link className='sidebar-link' to='/estimated-projects'>
						<Row
							className={`sidebar ${
								location.pathname === '/estimated-projects' ? 'active-tab' : ''
							} `}>
							<Col sm={{ span: 9, offset: 1 }}>
								<FontAwesomeIcon
									className='sidebar-icon'
									icon='fa-solid fa-layer-group'
								/>
								<span>Projects</span>
							</Col>
						</Row>
					</Link>
					<Link className='sidebar-link' to='#'>
						<Row className='sidebar'>
							<Col sm={{ span: 9, offset: 1 }}>
								<FontAwesomeIcon
									className='sidebar-icon'
									icon='fa-solid fa-chart-line'
								/>
								<span>Analytics</span>
							</Col>
						</Row>
					</Link>
					<Link className='sidebar-link' to='#'>
						<Row className='sidebar'>
							<Col sm={{ span: 9, offset: 1 }}>
								<FontAwesomeIcon
									className='sidebar-icon'
									icon='fa-solid fa-address-book'
								/>
								<span>Contacts</span>
							</Col>
						</Row>
					</Link>
					<div className='general-signout' onClick={props.handleSignOut}>
						<Row className='sidebar'>
							<Col sm={{ span: 9, offset: 1 }}>
								<FontAwesomeIcon
									className='sidebar-icon'
									icon='fa-solid fa-arrow-right-from-bracket'
								/>
								<span>Log out</span>
							</Col>
						</Row>
					</div>
				</Col>
			</>
		);
	} else if (props.userId && props.account === 'contractor') {
		return (
			<>
				<div className='dashboard-header-container'>
					<div className='dashboard-profile-view'>
						<img
							src={props.userProfile || defaultProfile}
							className='general-profile'
							alt='user-profile'
						/>
						<h5>
							{props.userFirstName} {props.userLastName}
						</h5>
					</div>
					<div className='dashboard-header'>
						<Link to='/contractor-dashboard'>
							<h3 className='header-link'>Dashboard</h3>
						</Link>
						<Link to='/contractor-dashboard/projects/open-projects'>
							<h3 className='header-link'>Open Projects</h3>
						</Link>
						<Link to='/contractor-dashboard/clients'>
							<h3 className='header-link'>Clients</h3>
						</Link>
						<Link to={contractorIdLink}>
							<h3 className='header-link'>My Account</h3>
						</Link>
					</div>
					<div className='dashboard-profile-view'> </div>
				</div>
			</>
		);
	} else {
		return (
			<>
				<p className='show-message'>
					Problem Loading Data, Please Refresh and Try Again!
				</p>
			</>
		);
	}
}

export default UserDashboardNav;
