import React from 'react';
import { StyleSheet, Text, View, Font } from '@react-pdf/renderer';
Font.register({
	family: 'Helvetica-Bold',
});
export const pdfRenderStyles = StyleSheet.create({
	page: {
		backgroundColor: '#FFF',
	},
	pageLayout: {
		display: 'flex',
		flexDirection: 'column',
		fontSize: 10,
		margin: 10,
	},
	topDiv: {
		height: '9vh',
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		borderBottom: 1,
		borderBottomColor: 'lightskyblue',
	},
	middleDiv: {
		marginTop: 5,
	},
	bottomDiv: {
		marginTop: '15px',
		marginBottom: '15px',
		justifyContent: 'center',
		alignSelf: 'end',
	},
	logo: {
		width: 55,
		height: 55,
	},
	infoSection: {
		margin: 5,
		marginBottom: 0,
	},
	info: {
		marginTop: 5,
	},
	mainTitle: {
		marginTop: 1,
		marginBottom: 2,
		marginLeft: 5,
		marginRight: 5,
	},
	title: {
		paddingTop: 3,
		fontSize: 11,
		marginBottom: 0,
		textDecoration: 'underline',
	},
	projectReport: {
		flexDirection: 'row',
		display: 'flex',
	},
	paragraph: {
		lineHeight: 1.6,
		paddingTop: 5,
		marginLeft: 5,
		marginRight: 20,
	},
	body: {
		fontSize: 9,
		lineHeight: 1.6,
		paddingTop: 5,
		marginTop: 10,
		marginRight: 10,
		marginLeft: 7,
	},
	tableHeaderContainer: {
		flexDirection: 'row',
		display: 'flex',
		backgroundColor: 'lightskyblue',
		color: '#FFF',
		fontSize: 10,
	},
	leftHeader: {
		width: '5%',
		margin: 5,
		textAlign: 'left',
	},
	middleHeader: {
		width: '77%',
		flexDirection: 'column',
		margin: 5,
		textAlign: 'left',
	},
	rightHeader: {
		width: '12%',
		marginTop: 5,
		marginBottom: 5,
		marginRight: 5,
		textAlign: 'right',
	},
	tableBodyContainer: {
		display: 'flex',
		flexDirection: 'row',
		fontSize: 10,
	},
	leftColumn: {
		width: '5%',
		textAlign: 'left',
		margin: 5,
		marginTop: 2,
	},
	middleColumn: {
		width: '77%',
		textAlign: 'left',
		margin: 5,
		marginTop: 2,
	},
	rightColumn: {
		width: '12%',
		textAlign: 'right',
		marginRight: 5,
		marginTop: 2,
	},
	subHeader: {
		paddingRight: 10,
		textAlign: 'left',
		fontWeight: 'bold',
	},
	itemColumn: {
		textAlign: 'left',
		marginLeft: 2,
		marginTop: 2,
	},
	descriptionColumn: {
		textAlign: 'left',
		marginTop: 2,
	},
	priceColumn: {
		textAlign: 'right',
		marginTop: 2,
	},
	tableBody: {
		paddingTop: 5,
		textAlign: 'left',
		lineHeight: 1.6,
		fontSize: 9,
	},
	fineText: {
		fontSize: 7,
		margin: 5,
		textAlign: 'center',
	},
	footer: {
		textAlign: 'center',
	},
	hyperlink: {
		color: 'lightskyblue',
		textDecoration: 'underline',
	},
	pageNumber: {
		position: 'absolute',
		bottom: 7,
		left: 0,
		right: 0,
		textAlign: 'center',
		fontSize: 10,
	},
	signature: {
		marginTop: 20,
	},
	smallText: {
		fontSize: 8,
		textAlign: 'left',
		marginTop: 3,
		marginRight: 3,
	},
	boldText: {
		fontFamily: 'Helvetica-Bold',
		fontWeight: '900',
	},
	highlight: {
		fontFamily: 'Helvetica-Bold',
		fontWeight: '900',
		color: '#003087',
		paddingBottom: '5px',
	},
	highlightTotal: {
		fontFamily: 'Helvetica-Bold',
		fontWeight: '900',
		color: '#0064f7',
		fontSize: 11,
	},
});

export const reportFooter = (
	<View style={pdfRenderStyles.bottomDiv}>
		<Text style={pdfRenderStyles.fineText}>
			Estimations and Calculations are generated by Opticost Inc
			{/* Pricing does not include items that are not immediately apparent upon our
			initial inspection (ex. rotting on roof deck, rotted rafters), explicitly
			stated in above proposal, nor are any repairs included unless specifically
			listed above, and correction of these faults may result in added charges.
			Pricing on this estimate is valid for 15 calendar days */}
		</Text>
		<Text style={pdfRenderStyles.footer}>
			Website:
			<Text style={pdfRenderStyles.hyperlink}> https://www.opticost.ai </Text>|
			Phone: 408-661-0873 | Email:
			<Text style={pdfRenderStyles.hyperlink}> main@opticost.ai</Text>
		</Text>
	</View>
);

export const secondPDFPage = (
	<View>
		<Text
			style={{
				fontSize: 9,
				marginLeft: 10,
				textAlign: 'left',
				marginTop: '100px',
			}}>
			If proposal is accepted, please sign and date below
		</Text>
		<Text
			style={{
				fontSize: 8,
				marginLeft: 10,
				textAlign: 'left',
				marginTop: 20,
			}}>
			Signature:___________________________________________ Print
			Name:_________________________________________ Date:_________________
		</Text>
	</View>
	// <View style={pdfRenderStyles.middleDiv}>
	// 	<Text
	// 		style={{
	// 			textAlign: 'center',
	// 			fontSize: 13,
	// 			marginTop: 10,
	// 		}}>
	// 		Scope of Work and Key Steps
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		A - Remove existing roof and non-fastened items down to the skip-sheathing
	// 		and dispose of debris per local requirements. Nails and/or staples are to
	// 		be removed or hammered down to ensure a smooth deck prior to new deck
	// 		installation
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		B - We will do our best to ensure thorough and meticulous preparation,
	// 		cleaning, and care before, during, after roof tear-off
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		C - Debris related to roofing work will be thoroughly cleaned from
	// 		surroundings daily and upon project completion
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		D - Deck will be inspected for rot or damage. In case of any rotting or
	// 		damage, we will contact homeowner to discuss any rotting or damage found,
	// 		as well as T&M costs approvals before remediation. After approval, we will
	// 		replace wood
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		E - Proposal includes the replacement of sheet metal flashings for the
	// 		entire roof, painted to match the roof color closely
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		F - Proposal includes the installation of pre-painted white nosing along
	// 		the rake and eave edges of the roof
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		G - Proposal includes the installation of customer-selected underlayment:
	// 		(see Item A page 1)
	// 	</Text>
	// 	<Text
	// 		style={{
	// 			textAlign: 'center',
	// 			fontSize: 13,
	// 			marginTop: 10,
	// 		}}>
	// 		Homeowner Responsibilities
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		A - Homeowner to arrange with a third party to install plastic sheeting
	// 		over any items that must be protected from dust or debris that are located
	// 		in the attic or garage, or any other location with an open ceiling that is
	// 		susceptible to dust intrusion
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		B - Homeowner to arrange with a third party to remove and reinstall the
	// 		pool solar system and its required components
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		C - Homeowner to contact neighbors prior to work commencement and alert
	// 		them about the nature of the project
	// 	</Text>
	// 	<Text
	// 		style={{
	// 			textAlign: 'center',
	// 			fontSize: 13,
	// 			marginTop: 10,
	// 		}}>
	// 		General Conditions
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		A - Homeowner negotiates directly with the roofing contractor regarding
	// 		roofing details including, but not limited to, the type of underlayment,
	// 		the type of shingles, and the type of plywood or OSB to be used
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		B - Contractor to pay for and pull the roofing permit from the City of
	// 		Palo Alto and submit to the homeowner a separate invoice for the permits
	// 		paid
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		C - Payment Schedule is as follows: 10% Upfront Deposit; 40% 1st progress
	// 		payment (after tear-off); 40% 2nd progress payment (after completion of
	// 		in-progress inspection); 10% Final payment (after final inspection;
	// 		Conditional Releases for progress payments and an Unconditional release
	// 		for final payment shall be issued prior to final payment)
	// 	</Text>
	// 	<Text style={pdfRenderStyles.body}>
	// 		D - We will provide a schedule outlining project completion order,
	// 		expected working days, and anticipated project duration
	// 	</Text>
	// 	<View style={pdfRenderStyles.signature}>
	// 		<Text
	// 			style={{
	// 				fontSize: 9,
	// 				marginLeft: 10,
	// 				textAlign: 'left',
	// 			}}>
	// 			If proposal is accepted, please sign and date below
	// 		</Text>
	// 		<Text
	// 			style={{
	// 				fontSize: 8,
	// 				marginLeft: 10,
	// 				textAlign: 'left',
	// 				marginTop: 30,
	// 			}}>
	// 			Signature:___________________________________________ Print
	// 			Name:_________________________________________ Date:_________________
	// 		</Text>
	// 	</View>
	// </View>
);
